import React from "react";
import Accordion from "../../../../../components/Accordion/Accordion";
import { useDispatch, useSelector } from "react-redux";
import { setPageWiseData } from "../../../../../state/SocialSlice";
import { numberRegex } from "../../../../../helper/Rejex";
import { Select } from "antd";

const LaborMgmtRelations = ({ formEditable, editMode }) => {
  const { pageWiseData } = useSelector((state) => state.social);

  const dispatch = useDispatch();

  let socialAllData = JSON.parse(JSON.stringify(pageWiseData));

  const handleAboutNoticePeriod = (e, index) => {
    const { name, value } = e.target;
    if (name === "yesNo") {
      socialAllData.LaborManagementRelations.noticePeriod.aboutNoticePeriod[index][name] = value;
    } else {
      if (numberRegex.test(e.target.value) || e.target.value === "") {
        socialAllData.LaborManagementRelations.noticePeriod.aboutNoticePeriod[index][name] = value;
      }
    }

    dispatch(setPageWiseData(socialAllData));
  };

  const handleCollectiveAgreementsChange = (e) => {
    socialAllData.LaborManagementRelations.noticePeriod.collectiveAgreements = e.target.value;
    dispatch(setPageWiseData(socialAllData));
  };

  return (
    <div className="esg-table-content">
      <div className="form-bullet-points-div ">
        <Accordion title={"Details about notice period"} index={0}>
          <div>
            <table className="form-table mb-4 mt-1">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Yes/ No</th>
                  <th scope="col">Number of weeks</th>
                </tr>
              </thead>
              <tbody>
                {socialAllData?.LaborManagementRelations?.noticePeriod?.aboutNoticePeriod.map((item, index) => (
                  <tr key={item + index}>
                    <td>{item.title}</td>
                    <td>
                      <Select
                        className="w-100"
                        value={item.yesNo ? item.yesNo : "No"}
                        onChange={(value) => handleAboutNoticePeriod({ target: { value, name: "yesNo" } }, index)}
                        disabled={editMode && !formEditable}
                      >
                        {["Yes", "No"].map((item, ind) => (
                          <Select.Option key={ind} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>
                    <td className="p-1">
                      <textarea
                        rows={5}
                        name="NumberOfWeeks"
                        value={item.NumberOfWeeks}
                        onChange={(e) => handleAboutNoticePeriod(e, index)}
                        className={`no-style-input  ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></textarea>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="foreword-container mt-1 mb-4">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                {
                  "Whether the notice period and provisions for consultation and negotiation are specified in collective agreements."
                }
              </p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={socialAllData?.LaborManagementRelations?.noticePeriod?.collectiveAgreements}
                  name={"collectiveAgreements"}
                  onChange={(e) => handleCollectiveAgreementsChange(e)}
                  className="no-style-input w-100"
                  disabled={editMode && !formEditable}
                />
              </div>
            </div>
          </div>
        </Accordion>
      </div>
    </div>
  );
};

export default LaborMgmtRelations;
