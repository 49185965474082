import { createSlice } from "@reduxjs/toolkit";

const initialMultipleCategoryState = {
  tabWiseValueGlobal: {},
  tabs: []
};

export const multipleCategorySlice = createSlice({
  name: "multipleCategory",
  initialState: initialMultipleCategoryState,
  reducers: {
    setTabWiseValueGlobal: (state, action) => {
      return {
        ...state,
        tabWiseValueGlobal: action.payload
      };
    },
    setTabs: (state, action) => {
      return {
        ...state,
        tabs: action.payload
      };
    }
  }
});

export const { setTabWiseValueGlobal, setTabs } = multipleCategorySlice.actions;

export default multipleCategorySlice.reducer;
