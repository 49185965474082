import { createSlice } from "@reduxjs/toolkit";

const governanceInitialState = {
  formPageWiseData: {
    // need changes
    governance: {
      governanceStructureComposition: {
        accordionTitle: "Details about the Governance structure and its Composition",
        governanceStructureDetails: [
          {
            heading: "Describe its governance structure, including committees of the highest governance body",
            forewordValue: ""
          },
          {
            heading:
              "List the committees of the highest governance body that are responsible for decisionmaking on and overseeing the management of the organization’s impacts on the economy, environment and people",
            forewordValue: ""
          }
        ]
      },

      compositionDetails: {
        accordionTitle: "Details of the Composition of the highest governance body",
        compositionTableDetail: [
          {
            committeeMember: "",
            executiveNonExecutive: "",
            gender: "",
            age: "",
            conflictOfInteres: "",
            tenureOfMember: "",
            numberOfSignificantPositions: "",
            natureOfCommitment: "",
            competenciesRelevant: "",
            stakeholderRepresentation: "",
            anyOtherIndicator: ""
          }
        ]
      }
    },

    structureComposition: {
      structureGovernancBbody: {
        accordionTitle: "Governance Structure and Composition",
        governanceBodyDetails: [
          {
            heading:
              "Describe the nomination and selection processes for the highest governance body and its committiees",
            forewordValue: ""
          },
          {
            heading:
              "Describe the criteria used for nominating and selecting highest governance body members, including whether and how the following are taken into consideration: i. views of stakeholders (including shareholders);ii. diversity; iii. independence; and iv. competencies relevant to the impacts of the organization.",
            forewordValue: ""
          },
          {
            heading:
              "Report whether the chair of the highest governance body is also a senior executive in the organization",
            forewordValue: ""
          },
          {
            heading:
              "If the chair is also a senior executive, explain their function within the organization’s management, the reasons for this arrangement, and how conflicts of interest are prevented and mitigated",
            forewordValue: ""
          }
        ]
      }
    },

    highestGovernance: {
      roleOfHighestGovernanceBody: {
        accordionTitle: "Role of the highest governance body in overseeing the management of impacts",

        roleOfGovernanceBody: [
          {
            heading:
              "Describe the role of the highest governance body and of senior executives in developing, approving, and updating the organization’s purpose, value or mission statements, strategies, policies, and goals related to sustainable development.",
            firstSubHeading: "",
            secondSubHeading: "",
            forewordValue: ""
          },
          {
            heading:
              "Describe the role of the highest governance body in overseeing the organization’s due diligence and other processes to identify and manage the organization’s impacts on the economy, environment, and people, including:",
            firstSubHeading:
              "whether and how the highest governance body engages with stakeholders to support these processes",
            secondSubHeading: "how the highest governance body considers the outcomes of these processes",
            forewordValue: ""
          },
          {
            heading:
              "Describe the role of the highest governance body in reviewing the effectiveness of the organization’s processes as described in 2-12-b, and report the frequency of this review.",
            firstSubHeading: "",
            secondSubHeading: "",
            forewordValue: ""
          }
        ]
      }
    },

    managingImpacts: {
      delegationOfResponsibility: {
        accordionTitle: "Details about responsibility delegations for managing impacts",
        detailsAboutResponsibility: [
          {
            heading:
              "Describe how the highest governance body delegates responsibility for managing the organization’s impacts on the economy, environment, and people, including:",
            firstSubHeading:
              "whether it has appointed any senior executives with responsibility for the management of impacts",
            secondSubHeading:
              "whether it has delegated responsibility for the management of impacts to other employees",
            forewordValue: ""
          },
          {
            heading:
              "Describe the process and frequency for senior executives or other employees to report back to the highest governance body on the management of the organization’s impacts on the economy, environment, and people",
            firstSubHeading: "",
            secondSubHeading: "",
            forewordValue: ""
          }
        ]
      }
    },

    sustainability: {
      roleOfGovernanceBodyInSustainability: {
        accordionTitle: "Details about responsibility delegations for managing impacts",
        detailsAboutInSustainability: [
          {
            heading:
              "Describe the role of the highest governance body and of senior executives in developing, approving, and updating the organization’s purpose, value or mission statements, strategies, policies, and goals related to sustainable development.",
            firstSubHeading: "",
            secondSubHeading: "",
            forewordValue: ""
          },
          {
            heading:
              "Describe the role of the highest governance body in overseeing the organization’s due diligence and other processes to identify and manage the organization’s impacts on the economy, environment, and people, including:",
            firstSubHeading:
              "whether and how the highest governance body engages with stakeholders to support these processes",
            secondSubHeading: "How the highest governance body considers the outcomes of these processes",
            forewordValue: ""
          },
          {
            heading:
              "Describe the role of the highest governance body in reviewing the effectiveness of the organization’s processes as described in 2-12-b, and report the frequency of this review.",
            firstSubHeading: "",
            secondSubHeading: "",
            forewordValue: ""
          }
        ]
      }
    },

    conflictInterest: {
      conflictOfInterest: {
        accordionTitle: "Details about conflict of interest",
        detailsAboutConflict: [
          {
            heading:
              "Describe the processes for the highest governance body to ensure that conflicts of interest are prevented and mitigated",
            firstSubHeading: "",
            secondSubHeading: "",
            thirdSubHeading: "",
            fourthSubHeading: "",
            forewordValue: ""
          },
          {
            heading:
              "Report whether conflicts of interest are disclosed to stakeholders, including, at a minimum, conflicts of interest relating to",
            firstSubHeading: "cross border membership",
            secondSubHeading: "cross-shareholding with suppliers and other stakeholders",
            thirdSubHeading: "existence of controlling shareholders",
            fourthSubHeading: "related parties, their relationships, transactions, and outstanding balances",
            forewordValue: ""
          }
        ]
      }
    },

    criticalConcerns: {
      communicationOfCriticalConcerns: {
        accordionTitle: "Details about communication of critical concerns",
        detialsAboutCommunication: [
          {
            heading: "Describe whether and how critical concerns are communicated to the highest governance body",
            forewordValue: ""
          },
          {
            heading:
              "Report the total number and the nature of critical concerns that were communicated to the highest governance body during the reporting period",
            forewordValue: ""
          }
        ]
      }
    },

    knowledgeAndPerformance: {
      collectiveKnowledge: {
        accordionTitle: "Collective knowledge of the highest governance body",
        collectiveKnowledge: [
          {
            heading:
              "Report measures taken to advance the collective knowledge, skills, and experience of the highest governance body on sustainable development.",
            forewordValue: ""
          }
        ]
      },

      performanceEvaluation: {
        accordionTitle: "Evaluation of the performance of the highest governance body the organization",
        evaluationOfThePerformance: [
          {
            heading:
              "Describe the processes for evaluating the performance of the highest governance body in overseeing the management of the organization’s impacts on the economy, environment, and people.",
            forewordValue: ""
          },
          {
            heading: "Report whether the evaluations are independent or not, and the frequency of the evaluations.",
            forewordValue: ""
          },
          {
            heading:
              "Describe actions taken in response to the evaluations, including changes to the composition of the highest governance body and organizational practices.",
            forewordValue: ""
          }
        ]
      }
    },

    remunerationPolicies: {
      remunerationPolicy: {
        accordionTitle: "Details about the remuneration policy of the Organisation",

        detailsAboutRemuneration: [
          {
            fixedVariablePay: "",
            incentivePayments: "",
            terminationPayments: "",
            clawbacks: "",
            retirementBenefits: ""
          },
          {
            fixedVariablePay: "",
            incentivePayments: "",
            terminationPayments: "",
            clawbacks: "",
            retirementBenefits: ""
          }
        ],

        discriptionOfRemuneration: ""
      },

      determinerRemuneration: {
        accordionTitle: "Annual total compensation ratio",
        determineRemunerationProcess: [
          {
            heading:
              "Describe the process for designing its remuneration policies and for determining remuneration, including",
            firstSubHeading:
              "whether independent highest governance body members or an independent remuneration committee oversees the process for determining remuneration",
            secondSubHeading:
              "how the views of stakeholders (including shareholders) regarding remuneration are sought and taken into consideration",
            thirdSubHeading: "whether remuneration consultants are involved in determining remuneration",
            fourthSubHeading:
              "if yes, whether they are independent of the organization, its highest governance body and senior executives",
            forewordValue: ""
          },
          {
            heading:
              "Report the results of votes of stakeholders (including shareholders) on remuneration policies and proposals, if applicable",
            forewordValue: ""
          }
        ]
      },

      compensationRatio: {
        accordionTitle: "Annual total compensation ratio",
        annualCompensationRatio: [
          {
            heading:
              "Report the ratio of the annual total compensation for the organization’s highest-paid individual to the median annual total compensation for all employees(excluding the highest-paid individual)",
            forewordValue: ""
          },
          {
            heading:
              "Report the ratio of the percentage increase in annual total compensation for the organization’s highest-paid individual to the median percentage increase in annual total compensation for all employees (excluding the highest-paid individual)",
            forewordValue: ""
          },
          {
            heading:
              "Report contextual information necessary to understand the data and how the data has been compiled",
            forewordValue: ""
          }
        ]
      }
    },

    organisation: {
      antiCompetitive: {
        accordionTitle: "Details about the Anti-Competitive cases in the Organisation",
        deatilsAboutAntiCompetitive: [
          {
            heading: "Anti-competitive behaviour",
            numberOfCases: "",
            pendingCompleted: "",
            outcome: ""
          },
          {
            heading: "Anti-trust and monopoly behaviour",
            numberOfCases: "",
            pendingCompleted: "",
            outcome: ""
          }
        ]
      }
    },

    // we have Anti- Corruption slice in seprate file -

    tax: {
      approach: {
        accordionTitle: "Approach to tax",
        approachToTaxDescription: ""
      },

      taxGovernance: {
        accordionTitle: "Tax governance, control, and risk management",
        taxGovernanceControlAndRiskManagement: [
          {
            heading: "A description of the tax governance and control framework, including: ",
            firstSubHeading:
              "the governance body or executive-level position within the organization accountable for compliance with the tax strategy",
            secondSubHeading:
              "how the approach to tax is embedded within the organization;the approach to tax risks, including how risks are identified, managed, and monitored",
            thirdSubHeading: "the approach to tax risks, including how risks are identified, managed, and monitored",
            fourthSubHeading: "how compliance with the tax governance and control framework is evaluated",
            forewordValue: ""
          },
          {
            heading:
              "A description of the mechanisms to raise concerns about the organization’s business conduct and the organization’s integrity in relation to tax",
            // firstSubHeading: "",
            // secondSubHeading: "",
            // thirdSubHeading: "",
            // fourthSubHeading: "",
            forewordValue: ""
          },
          {
            heading:
              "A description of the assurance process for disclosures on tax including, if applicable, a link or reference to the external assurance report(s) or assurance statement(s).",
            // firstSubHeading: "",
            // secondSubHeading: "",
            // thirdSubHeading: "",
            // fourthSubHeading: "",
            forewordValue: ""
          }
        ]
      },

      stakeholder: {
        accordionTitle: "Stakeholder engagement and management of concerns related to tax",
        stakeholderEngagementDiscription: ""
      },

      reporting: {
        accordionTitle: "Country by country reporting",
        countryByCountryReporting: [
          {
            SINo: "",
            residentEntitiesName: "",
            primaryActivities: "",
            numberOfEmployees: "",
            basisCalculation: "",
            revenuesFromThirdParty: "",
            revenuesFromIntraGroup: "",
            profitoLssbeforeTax: "",
            tangibleAssets: "",
            corporateIncomeTaxPaidCashBasic: "",
            corporateIncomeTaxAccruedOnProfitLoss: "",
            diffrence: ""
          }
        ]
      }
    }
  }
};

export const govtSlice = createSlice({
  name: "governance",
  initialState: governanceInitialState,
  reducers: {
    setFormWiseData: (state, action) => {
      return {
        ...state,
        formPageWiseData: action.payload
      };
    }
  }
});
export const { setFormWiseData } = govtSlice.actions;

export default govtSlice.reducer;
