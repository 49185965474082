import React from "react";
import { Navigate } from "react-router-dom";

function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function PrivateRoute({ children }) {
  const cookie = getCookie("isLoggedIn");
  // const cookie=true
  if (!cookie) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/" />;
  }

  return children;
}

export default PrivateRoute;
