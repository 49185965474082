import React, { useEffect, useState } from "react";
import Accordion from "../../../../../components/Accordion/Accordion";
import { useDispatch, useSelector } from "react-redux";
import { setPageWiseData } from "../../../../../state/SocialSlice";

import { useLazyGetUserDetailByIdQuery } from "../../../../../state/api";
import { Select } from "antd";
import { numberRegex } from "../../../../../helper/Rejex";

const employeeCategory = [
  "Permanent Employee",
  "Temporary Employees",
  "Non-guaranteed hours employees",
  "Full-time employees",
  "Part-time employees"
];

const DiversityEqualOpportunity = ({ formEditable, editMode }) => {
  const { pageWiseData } = useSelector((state) => state.social);

  const dispatch = useDispatch();

  let socialAllData = JSON.parse(JSON.stringify(pageWiseData));

  const userId = useSelector((state) => state.global.user);
  const [user, setUser] = useState(null);
  const [getUser, userResult] = useLazyGetUserDetailByIdQuery();

  useEffect(() => {
    getUser({ id: userId._id }, true);
  }, []);

  useEffect(() => {
    if (userResult.data?.data) {
      setUser(userResult.data?.data);
    }
  }, [userResult.data?.data]);

  const handlePercentageOfIndividualsChange = (e, index) => {
    const { name, value } = e.target;
    if (name === "title") {
      socialAllData.diversityEqualOpportunity.governanceBody.percentageOfIndividuals[index][name] = value;
    } else {
      if (numberRegex.test(value) || value === "") {
        if (name === "percentage") {
          if (value <= 100) {
            socialAllData.diversityEqualOpportunity.governanceBody.percentageOfIndividuals[index][name] = value;
          }
        } else {
          socialAllData.diversityEqualOpportunity.governanceBody.percentageOfIndividuals[index][name] = value;
        }
      }
    }
    dispatch(setPageWiseData(socialAllData));
  };
  const addNewRowPercentageOfIndividuals = () => {
    let newRowObj = {
      title: "",
      number: "",
      percentage: ""
    };
    socialAllData.diversityEqualOpportunity.governanceBody.percentageOfIndividuals.push(newRowObj);
    dispatch(setPageWiseData(socialAllData));
  };

  const handleDiversityCategoriesChange = (e, index) => {
    const { name, value } = e.target;
    if (name === "category") {
      socialAllData.diversityEqualOpportunity.percentageOfEmployees.percentageOfEmployeesPerEmployee[index][name] = value;
    } else {
      if (numberRegex.test(value) || value === "") {
        if (name === "percentage") {
          if (value <= 100) {
            socialAllData.diversityEqualOpportunity.percentageOfEmployees.percentageOfEmployeesPerEmployee[index][name] = value;
          }
        } else {
          socialAllData.diversityEqualOpportunity.percentageOfEmployees.percentageOfEmployeesPerEmployee[index][name] = value;
        }
      }
    }

    dispatch(setPageWiseData(socialAllData));
  };

  const addNewRowDiversityCategories = () => {
    let newRowObj = {
      category: "",
      number: "",
      percentage: ""
    };
    socialAllData.diversityEqualOpportunity.percentageOfEmployees.percentageOfEmployeesPerEmployee.push(newRowObj);
    dispatch(setPageWiseData(socialAllData));
  };

  const handleRatioOfTheBasicSalaryChange = (e, index) => {
    const { name, value } = e.target;
    if (name === "region" || name ==="employeeCategory") {
      socialAllData.diversityEqualOpportunity.ratioOfTheBasicSalary.ratioOfTheBasicSalary[index][name] = value;
    } else if(numberRegex.test(value) || value === ""){
   
      socialAllData.diversityEqualOpportunity.ratioOfTheBasicSalary.ratioOfTheBasicSalary[index][name] = value;
    }
    dispatch(setPageWiseData(socialAllData));
  };

  const addNewRowRatioOfTheBasicSalary = () => {
    let newRowObj = {
      region: "",
      employeeCategory: "",
      basicSalaryFemale: "",
      basicSalaryMale: "",
      basicSalaryRatio: ""
    };
    socialAllData.diversityEqualOpportunity.ratioOfTheBasicSalary.ratioOfTheBasicSalary.push(newRowObj);
    dispatch(setPageWiseData(socialAllData));
  };

  const deleteRow = (parentKey, arrayKey, rowIndex) => {
    const parentObject = socialAllData.diversityEqualOpportunity?.[parentKey];
    const dataArray = parentObject?.[arrayKey];
    if (Array.isArray(dataArray)) {
      if (rowIndex >= 0 && rowIndex < dataArray.length) {
        const updatedArray = dataArray.filter((_, index) => index !== rowIndex);

        const updatedParentObject = {
          ...parentObject,
          [arrayKey]: updatedArray
        };

        const updatedData = {
          ...socialAllData,
          diversityEqualOpportunity: {
            ...socialAllData.diversityEqualOpportunity,
            [parentKey]: updatedParentObject
          }
        };

        dispatch(setPageWiseData(updatedData));
      }
    }
  };

  // const deleteRow = (rowIndex, name) => {
  //   if (rowIndex > 0) {
  //     let tempArray = [...socialAllData.diversityEqualOpportunity[name]];
  //     tempArray.splice(rowIndex, 1);
  //     socialAllData.diversityEqualOpportunity[name] = tempArray;
  //     dispatch(setPageWiseData(socialAllData));
  //   }
  // };

  return (
    <div className="esg-table-content">
      <div className="form-bullet-points-div ">
        <Accordion title={"Percentage of individuals within the organization's governance body"} index={0}>
          <table className="form-table mb-3 mt-1">
            <thead>
              <tr>
                <th scope="col">By Gender</th>
                <th scope="col">Number</th>
                <th scope="col">Percentage</th>
                <th className="col-md-1" scope="col">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {socialAllData?.diversityEqualOpportunity?.governanceBody?.percentageOfIndividuals.map((item, index) => (
                <tr key={index + item}>
                  {index === 3 || index === 7 ? (
                    <>
                      <td colSpan={4}>{item.title}</td>
                    </>
                  ) : (
                    <>
                      <td>
                        <input
                          type="text"
                          name="title"
                          value={item.title}
                          onChange={(e) => handlePercentageOfIndividualsChange(e, index)}
                          className={`no-style-input ${
                            formEditable && index >= 8 ? "border border-2 rounded-1 p-1" : ""
                          } `}
                          readOnly={(index >= 8 ? false : true) || (editMode && !formEditable)}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="number"
                          value={item.number}
                          onChange={(e) => handlePercentageOfIndividualsChange(e, index)}
                          className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                          readOnly={editMode && !formEditable}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="percentage"
                          value={item.percentage}
                          onChange={(e) => handlePercentageOfIndividualsChange(e, index)}
                          className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                          readOnly={editMode && !formEditable}
                        ></input>
                      </td>
                      <td className="small-width-columns">
                        {index >= 9 && (
                          <img
                            src="/images/icons/delete-icon.svg"
                            alt=""
                            onClick={() => deleteRow("governanceBody", "percentageOfIndividuals" , index)}
                            className="cursor-pointer"
                          />
                        )}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          <button
            className="btn btn-outline-success add-new-row mb-4"
            onClick={() => addNewRowPercentageOfIndividuals()}
          >
            <p>+ Add New Indicator</p>
          </button>
        </Accordion>
      </div>

      <div className="form-bullet-points-div ">
        <Accordion
          title={"Percentage of employees per employee category in each of the following diversity categories"}
          index={1}
        >
          <table className="form-table mb-3 mt-1">
            <thead>
              <tr>
                <th scope="col">By Employee Category</th>
                <th scope="col">Number</th>
                <th scope="col">Percentage</th>
                <th className="col-md-1" scope="col">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {socialAllData?.diversityEqualOpportunity?.percentageOfEmployees?.percentageOfEmployeesPerEmployee?.map((item, index) => (
                <tr key={index + item}>
                  {index === 0 ||
                  index === 3 ||
                  index === 6 ||
                  index === 9 ||
                  index === 12 ||
                  index === 15 ||
                  index === 19 ? (
                    <>
                      <td colSpan={4}>{item.category}</td>
                    </>
                  ) : (
                    <>
                      <td>
                        <input
                          type="text"
                          name="category"
                          value={item.category}
                          onChange={(e) => handleDiversityCategoriesChange(e, index)}
                          className={`no-style-input ${
                            formEditable && index >= 20 ? "border border-2 rounded-1 p-1" : ""
                          } `}
                          readOnly={(index >= 20 ? false : true) || (editMode && !formEditable)}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="number"
                          value={item.number}
                          onChange={(e) => handleDiversityCategoriesChange(e, index)}
                          className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                          readOnly={editMode && !formEditable}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="percentage"
                          value={item.percentage}
                          onChange={(e) => handleDiversityCategoriesChange(e, index)}
                          className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                          readOnly={editMode && !formEditable}
                        ></input>
                      </td>
                      <td className="small-width-columns">
                        {index >= 21 && (
                          <img
                            src="/images/icons/delete-icon.svg"
                            alt=""
                            onClick={() => deleteRow("percentageOfEmployees", "percentageOfEmployeesPerEmployee" ,index)}
                            className="cursor-pointer"
                          />
                        )}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          <button className="btn btn-outline-success add-new-row mb-4" onClick={() => addNewRowDiversityCategories()}>
            <p>+ Add New Indicator</p>
          </button>
        </Accordion>

        <div className="form-bullet-points-div ">
          <Accordion title={"Ratio of the basic salary and remuneration of women to men"} index={2}>
            <table className="form-table mb-3 mt-1">
              <thead>
                <tr>
                  <th scope="col">Region</th>
                  <th scope="col">Employee Category</th>
                  <th scope="col">Basic Salary and Remuneration Female</th>
                  <th scope="col">Basic Salary and Remuneration Male</th>
                  <th scope="col">Ratio of basic Salary and Remuneration of women to men</th>
                  <th className="col-md-1">Action</th>
                </tr>
              </thead>

              <tbody>
                {socialAllData?.diversityEqualOpportunity?.ratioOfTheBasicSalary?.ratioOfTheBasicSalary?.map((inputItem, index) => (
                  <tr key={inputItem + index}>
                    <td>
                      <Select
                        className="w-100"
                        disabled={editMode && !formEditable}
                        defaultValue="Region"
                        showSearch
                        value={inputItem.region ? inputItem.region : "Region"}
                        onChange={(value) =>
                          handleRatioOfTheBasicSalaryChange({ target: { value, name: "region" } }, index)
                        }
                      >
                        {user?.facilities?.map((item, ind) => (
                          <Select.Option key={ind} value={item._id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>

                    <td>
                      <Select
                        className="w-100"
                        disabled={editMode && !formEditable}
                        defaultValue="Permanent Employee"
                        showSearch
                        value={inputItem.employeeCategory ? inputItem.employeeCategory : "Permanent Employee"}
                        onChange={(value) =>
                          handleRatioOfTheBasicSalaryChange({ target: { value, name: "employeeCategory" } }, index)
                        }
                      >
                        {employeeCategory.map((item, ind) => (
                          <Select.Option key={ind} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>

                    <td>
                      <input
                        type="text"
                        name="basicSalaryFemale"
                        value={inputItem.basicSalaryFemale}
                        onChange={(e) => handleRatioOfTheBasicSalaryChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="basicSalaryMale"
                        value={inputItem.basicSalaryMale}
                        onChange={(e) => handleRatioOfTheBasicSalaryChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="basicSalaryRatio"
                        value={inputItem.basicSalaryRatio}
                        onChange={(e) => handleRatioOfTheBasicSalaryChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>

                    <td className="small-width-columns">
                      {index !== 0 && (
                        <img
                          src="/images/icons/delete-icon.svg"
                          alt=""
                          onClick={() => deleteRow("ratioOfTheBasicSalary", "ratioOfTheBasicSalary",index)}
                          className="cursor-pointer"
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <button
              className="btn btn-outline-success add-new-row mb-4"
              onClick={() => addNewRowRatioOfTheBasicSalary()}
            >
              <p>+ Add New Indicator</p>
            </button>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default DiversityEqualOpportunity;
