import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "../components/ApiUrl";
import { dashboardEndpoints } from "./dashboardApi";
import { industryEndpoints } from "./industryApi";
import { emissionEndpoints } from "./emissionApi";
import { kleanEndpoints } from "./kleanApi";
import { reportEndpoints } from "./reportApi";
import { offsetEndpoints } from "./offsetApi";
import { analysisEndpoints } from "./analysisApi";
import { actEndpoints } from "./actApi";
import { ProductEndPoint } from "./productApi";
import { ImporterEndPoint } from "./ImporterApi";
import { InvoiceEndPoint } from "./invoiceApi";
import { userEndpoints } from "./userApi";
import { esgEndpoints } from "./esgApi";
import { auditLogEndpoints } from "./auditLogApi";
import { dataQulaityEndPoint } from "./dataQualityApi";

const baseQuery = fetchBaseQuery({
  baseUrl: apiUrl,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    headers.set("Content-Type", "application/json");
    return headers;
  }
});

const customBaseQuery = async (args, api, extraOptions) => {
  try {
    return await baseQuery(args, api, extraOptions);
  } catch (error) {
    // Handle the error globally, for example, show a notification or redirect to an error page
    console.error("Request failed:", error);
    throw error;
  }
};

export const api = createApi({
  baseQuery: customBaseQuery,
  reducerPath: "adminApi",
  tagTypes: ["Organization", "Facility", "Products", "Userdetail"],

  endpoints: (build) => ({
    ...dashboardEndpoints(build),
    ...industryEndpoints(build),
    ...emissionEndpoints(build),
    ...kleanEndpoints(build),
    ...reportEndpoints(build),
    ...offsetEndpoints(build),
    ...analysisEndpoints(build),
    ...actEndpoints(build),
    ...ProductEndPoint(build),
    ...ImporterEndPoint(build),
    ...InvoiceEndPoint(build),
    ...userEndpoints(build),
    ...esgEndpoints(build),
    ...auditLogEndpoints(build),
    ...dataQulaityEndPoint(build),

    getFacilityData: build.query({
      query: (arg) => ({
        url: `facility/list`,
        params: { ...arg }
      }),
      providesTags: ["Facility"]
    }),

    deleteFacilityData: build.mutation({
      query: (id) => ({
        url: `facility/${id}`,
        method: "DELETE"
      }),

      invalidatesTags: ["Facility", "Userdetail", "Dashmonthfacility", "Topfacility"],

      transformResponse: (response) => {
        return response;
      }
    }),

    searchFacilityData: build.query({
      query: (arg) => ({
        url: `facility/search`,
        params: { ...arg }
      }),
      providesTags: ["Facility"]
    }),

    getOrganizationData: build.query({
      query: () => `/organization`,
      providesTags: ["Organization"]
    }),

    getCurrencyData: build.query({
      query: () => `common/currency/list`,
      providesTags: ["Currency"]
    }),

    getScopeList: build.query({
      query: () => ({
        url: `common/scope/list`
      }),
      providesTags: ["scopes"]
    }),

    getCountryData: build.query({
      query: () => `common/country/list`,
      providesTags: ["Country"]
    }),

    getLogoutUser: build.query({
      query: () => `auth/logout`
    }),

    postUnitData: build.mutation({
      query: (postBody) => ({
        url: `common/units/list`,
        method: "POST",
        body: postBody
      }),

      transformResponse: (response) => {
        return response;
      }
    }),

    postCreateFacility: build.mutation({
      query: (postBody) => ({
        url: `facility`,
        method: "POST",
        body: postBody
      }),

      transformResponse: (response) => {
        return response;
      },

      invalidatesTags: ["Facility", "Dashmonthfacility", "Topfacility"]
    }),

    putUpdateFacility: build.mutation({
      query: (postBody) => ({
        url: `facility`,
        method: "PUT",
        body: postBody
      }),

      transformResponse: (response) => {
        return response;
      },
      invalidatesTags: ["Facility", "Dashmonthfacility", "Topfacility"]
    }),

    putUpdateOrganization: build.mutation({
      query: (postBody) => ({
        url: `organization/update`,
        method: "PUT",
        body: postBody
      }),

      transformResponse: (response) => {
        return response;
      },

      invalidatesTags: ["Organization", "Userdetail", "DataTableEntries"]
    }),

    postLoginUser: build.mutation({
      query: (postBody) => ({
        url: `auth/login`,
        method: "POST",
        body: postBody
      }),

      transformResponse: (response) => {
        return response;
      },

      invalidatesTags: ["Login"]
    }),

    postResetPassword: build.mutation({
      query: (postBody) => ({
        url: `auth/reset`,
        method: "POST",
        body: postBody
      }),

      transformResponse: (response) => {
        return response;
      },

      invalidatesTags: ["Reset"]
    }),

    getEvidenceById: build.query({
      query: (id) => ({
        url: `evidence/${id}`
      })
    }),
    getGeneratedUUID: build.query({
      query: () => ({
        url: "facility/generateUUID"
      }),
      providesTags: ["Facility"]
    })

    // createImporter: build.mutation({
    //   query: (body) => ({
    //     url: "cbam/importers",
    //     method: "POST",
    //     body: body
    //   }),
    //   invalidatesTags: ["Products"]
    // })
  })

  // onError(error) {
  //   console.error("Request error occurred:", error);
  // },
});

export const {
  usePostCreateFacilityMutation,
  useDeleteFacilityDataMutation,
  usePostLoginUserMutation,
  usePutUpdateOrganizationMutation,
  usePutUpdateFacilityMutation,
  usePostResetPasswordMutation,
  usePostUnitDataMutation,
  usePostEmissionTableDataMutation,
  usePostUpdateAndSaveReportMutation,
  usePostFilteredReportMutation,
  usePostCalculateEmissionRowMutation,
  useLazyPostAnalysisCategoryDataQuery,
  useLazyPostAnalysisSubCategoryDataQuery,
  usePostKleanInterestMutation,
  useLazyAnalysisTrendByYearQuery,
  useLazyAnalysisEmissionByMonthQuery,
  useLazyAnalysisEmissionByFacilityQuery,
  useLazyAnalysisEmissionTrendByMonthSourceQuery,
  useLazyAnalysisYearSourceTrendQuery,
  useLazyAnalysisRenewableShareQuery,
  useLazyAnalysisScopeEmissionQuery,
  useLazyGetReportDetailQuery,
  useLazyGetTemplateListQuery,
  useLazyGetDownloadReportQuery,
  useLazyGetKleanSolutionsQuery,
  useLazyGetKleanInterestsQuery,
  useGetMonthWiseDataQuery,
  useLazyGetReportFilterQuery,
  useGetScopeWiseDataQuery,
  useGetFacilityWiseDataQuery,
  useGetKleanFiltersQuery,
  useGetFacilityDataQuery,
  useGetOrganizationDataQuery,
  useGetCountryDataQuery,
  useGetCurrencyDataQuery,
  useGetLogoutUserQuery,
  useGetIndustryDataQuery,
  useGetReportTypeQuery,
  useLazyGetCategoryTableDataQuery,
  useLazyGetDataEntriesQuery,
  useLazyGetDashboardScopeDataQuery,
  useLazyGetCountryDataQuery,
  useLazyGetEmissionScopeCategoryQuery,
  useLazyGetFacilityDataQuery,
  useLazyGetFacilityWiseDataQuery,
  useLazyGetMonthWiseDataQuery,
  useLazyGetMonthlyFacilityQuery,
  useLazyGetMonthlyScopeQuery,
  useLazyGetScopeWiseDataQuery,
  useLazyGetTopCategoryQuery,
  useLazyGetTopFacilityQuery,
  useLazyGetTopSubcategoryQuery,
  useUpdateEmissionEntryMutation,
  useLazyGetSpendDataQuery,
  useLazyGetOffsetProjectsQuery,
  useGetOffsetFiltersQuery,
  useGetOffsetYearFilterQuery,
  useLazyGetOffsetTransactionsQuery,
  useGetOffsetTransactionFilterQuery,
  useLazyGetTotalOffsetQuery,
  usePostOffsetTransactionDataMutation,
  useCreateReductionTargetMutation,
  useUpdateReductionTargetMutation,
  useDeleteReductionTargetMutation,
  useLazyGetReductionTargetQuery,
  usePostTargetByScopeMutation,
  useLazyGetScopeListQuery,
  useLazyGetReductionTargetZeroStateQuery,
  useLazyGetReductionReportingYearQuery,
  useCheckReductionTargetExistsMutation,
  useLazyGetReductionTargetGraphQuery,
  useLazyGetSimulationsQuery,
  useCreateSimulationsMutation,
  useDeleteSimulationsMutation,
  useUpdateSimulationsMutation,
  useLazyGetSimulationsYearFilterQuery,
  useLazyGetMeasuresQuery,
  useCreateMeasureMutation,
  useUpdateMeasureMutation,
  useLazyGetMeasureScopeDataQuery,
  useLazyGetMeasureDetailQuery,
  useGetAllScopesQuery,
  useCreateMeasureDetailMutation,
  useDeleteMeasureDetailMutation,
  useCreateProjectionMutation,
  useLazyGetProjectionQuery,
  useUpdateProjectionMutation,
  useDeleteProjectionMutation,
  useCreateActionPlanMutation,
  useLazyGetActionPlanQuery,
  useUpdateActionPlanMutation,
  useDeleteActionPlanMutation,
  useCreateActionMeasureMutation,
  useLazyGetActionMeasureQuery,
  useUpdateActionMeasureMutation,
  useDeleteActionMeasureMutation,
  useLazyGetActionActionQuery,
  useCreateActionTemplateMutation,
  useLazyGetActionTemplateQuery,
  useLazyGetActionTemplateFilterQuery,
  useCreateActionActionMutation,
  useUpdateActionActionMutation,
  useLazyGetActionMeasureDetailQuery,
  useDeleteActionActionMutation,
  useGetSingleTemplateMutation,
  useLikeActionTemplateMutation,
  useUpdateActionTemplateMutation,
  useDeleteActionTemplateMutation,
  useDeleteMeasuresMutation,
  useCreateCommentMutation,
  useLazyGetCommentQuery,
  useUpdateCommentMutation,
  useDeleteCommentMutation,
  useLazyGetSimulationSingleQuery,
  useLazyGetAllActionActionQuery,
  useLazyGetEvidenceByIdQuery,
  useGetGeneratedUUIDQuery,
  useGetCBAMCodeSearchQuery,
  useGetProductListQuery,
  useCreateCBAMProductMutation,
  useGetFacilitySearchQuery,
  useGetProductDetailsQuery,
  useUpdateProductDetailsMutation,
  useDeleteProductCBAMMutation,
  useGetCBAMCodeListQuery,
  useGetProductDropdownListQuery,
  useGetInternalCodeDropdownListQuery,
  useGetImporterListQuery,
  useGetImporterDetailsQuery,
  useGetCountryListQuery,
  useCreateImporterMutation,
  useDeleteImporterMutation,
  useUpdateImporterMutation,
  useGetInvoiceListQuery,
  useDeleteInvoiceMutation,
  useCalculateEmissionMutation,
  useCreateInvoiceMutation,
  useGetInvoiceQuery,
  usePatchInvoiceMutation,
  useMakePdfInvoiceMutation,
  useGetBreakdownListQuery,
  usePostSendEmailMutation,
  useLazyGetUsersAllQuery,
  usePatchUserListMutation,
  useDeleteUserListMutation,
  useLazyGetUserDetailByIdQuery,
  useUsePostCreateUserMutation,
  useLazyGetUserGroupQuery,
  useDeleteUserGroupMutation,
  usePatchUserGroupMutation,
  usePostUserGroupMutation,
  useLazyGetUserGroupDetailQuery,
  useLazyGetCountryCodeQuery,
  useLazyGetAnalysisFilterQuery,
  useLazyGetRetrieveScopWiseEmissionDataQuery,
  usePostAnalysisFilterMutation,
  useLazySearchFacilityDataQuery,
  useLazyGetAnalysisSubFilterQuery,
  useLazyGetEsgTemplateListQuery,
  usePostEsgFormDataMutation,
  useLazyGetEsgDataEntriesQuery,
  useUpdateEsgEntryDataMutation,
  useLazyGetAuditLogDataQuery,
  usePostAuditLogDataMutation,
  useLazyGetAuditLogFilterDataQuery,
  useLazyGetDataQualityQuery,
  useLazyGetEnvDataQualityQuery,
  useLazyGetEnvBreakdownDataQuery,
  useLazyGetEsgBreakdownQuery,
  useLazyGetDataQualityFilterDataQuery,
  useLazyGetEsgFilterDataQuery,
  useLazyGetUserDepartmentQuery,
  useLazyGetEmissionSummaryDataQuery
} = api;
