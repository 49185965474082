import React, { useEffect, useRef } from "react";
import "./CustomDrawer.scss";

const CustomDrawer = ({ open, onClose, children }) => {
  const drawerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onClose]);

  return (
    <>
      {/* {open && <div className="drawer-overlay" onClick={""}></div>} */}

      <div className={`custom-drawer ${open ? "open" : ""}`} >
        <div className="drawer-content">
          <button className="close-btn" onClick={onClose}>
            <img
              src="/images/icons/cross-icon.svg"
              alt=""
              className="cursor-pointer"
              style={{ width: "48px", height: "48px" }}
            />
          </button>
          {children}
        </div>
      </div>
    </>
  );
};

export default CustomDrawer;
