import { createSlice } from "@reduxjs/toolkit";

const initialBrsrReportState = {
  pageWiseValue: {
    coverPage: {
      selectedCardIndex: 0,
      foreword: ""
    },
    SectionA: {
      corporateNumber: "",
      corpoarteNumberEvidence: "",
      listedEntityName: "",
      listedEntityNameEvidence: "",
      yearOfInc: "",
      officeAddress: "",
      corporateAddress: "",
      email: "",
      telephone: {
        areaCode: "",
        countryCode: "+91",
        telephoneNo: "",
        mobileNo: ""
      },
      website: "",
      financialYear: "",
      stockExchange: "",
      paidUpCapital: "",
      contactDetail: {
        name: "",
        email: "",
        designation: "",
        telephoneNo: "",
        mobileNo: "",
        areaCode: "",
        countryCode: "+91"
      },
      reportingBoundary: "",
      nameOfAssurance: "",
      typeOfAssurance: "",
      businessActivityDetails: [
        {
          mainActivity: "",
          businessActivity: "",
          percentTurnover: ""
        }
      ],
      productServiceSold: [
        {
          productService: "",
          nicCode: "",
          percentTurnover: ""
        }
      ],
      locationOfPlants: {
        national: {
          noOfPlants: "",
          noOfOffices: "",
          total: ""
        },
        international: {
          noOfPlants: "",
          noOfOffices: "",
          total: 0
        }
      },
      marketServed: {
        national: {
          number: ""
        },
        international: {
          number: ""
        }
      },
      exportContribution: "",
      typesOfCustomer: "",
      employee: {
        permanent: {
          total: "",
          male: "",
          malePercent: 0,
          female: "",
          femalePercent: 0
        },
        notPermanent: {
          total: "",
          male: "",
          malePercent: 0,
          female: "",
          femalePercent: 0
        },
        totalEmployee: {
          total: "",
          male: "",
          malePercent: 0,
          female: "",
          femalePercent: 0
        }
      },
      worker: {
        permanent: {
          total: "",
          male: "",
          malePercent: 0,
          female: "",
          femalePercent: 0
        },
        notPermanent: {
          total: "",
          male: "",
          malePercent: 0,
          female: "",
          femalePercent: 0
        },
        totalEmployee: {
          total: "",
          male: "",
          malePercent: 0,
          female: "",
          femalePercent: 0
        }
      },
      diffEmployee: {
        permanent: {
          total: "",
          male: "",
          malePercent: 0,
          female: "",
          femalePercent: 0
        },
        notPermanent: {
          total: "",
          male: "",
          malePercent: 0,
          female: "",
          femalePercent: 0
        },
        totalEmployee: {
          total: "",
          male: "",
          malePercent: 0,
          female: "",
          femalePercent: 0
        }
      },
      diffWorker: {
        permanent: {
          total: "",
          male: "",
          malePercent: 0,
          female: "",
          femalePercent: 0
        },
        notPermanent: {
          total: "",
          male: "",
          malePercent: 0,
          female: "",
          femalePercent: 0
        },
        totalEmployee: {
          total: "",
          male: "",
          malePercent: 0,
          female: "",
          femalePercent: 0
        }
      },
      participation: {
        boardOfDirectors: {
          total: "",
          women: ""
        },
        keyManagement: {
          total: "",
          women: ""
        }
      },
      turnOverCurrent: {
        permanentWorker: {
          male: "",
          female: ""
        },
        permanentEmployee: {
          male: "",
          female: ""
        }
      },
      turnOverPrevious: {
        permanentWorker: {
          male: "",
          female: ""
        },
        permanentEmployee: {
          male: "",
          female: ""
        }
      },
      turnOverPrePrevious: {
        permanentWorker: {
          male: "",
          female: ""
        },
        permanentEmployee: {
          male: "",
          female: ""
        }
      },
      holdings: [
        {
          companyDetail: "",
          indicator: "",
          sharePercent: "",
          participation: ""
        }
      ],
      csrApplicable: "",
      netWorth: "",
      turnOver: "",
      complaints: [
        {
          stakeholder: "Communities",
          grievanceRedressal: "",
          previousYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          },
          currentYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          }
        },
        {
          stakeholder: "Investors (other than shareholders)",
          grievanceRedressal: "",
          previousYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          },
          currentYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          }
        },
        {
          stakeholder: "Shareholders",
          grievanceRedressal: "",
          previousYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          },
          currentYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          }
        },
        {
          stakeholder: "Employees and workers",
          grievanceRedressal: "",
          previousYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          },
          currentYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          }
        },
        {
          stakeholder: "Customers",
          grievanceRedressal: "",
          previousYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          },
          currentYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          }
        },
        {
          stakeholder: "Value Chain Partners",
          grievanceRedressal: "",
          previousYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          },
          currentYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          }
        },
        {
          stakeholder: "Other (please specify)",
          grievanceRedressal: "",
          previousYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          },
          currentYear: {
            totalComplaints: "",
            pendingComplaints: "",
            remarks: ""
          }
        }
      ],
      riskBusinessIssue: [
        {
          materialIssue: "",
          riskOrOpportunity: "",
          reasonOfRiskOpportunity: "",
          mitigation: "",
          financialImplication: ""
        }
      ]
    },
    SectionB: {
      discolosureQuestions: [
        {
          questions:
            "1. a. Whether your entity’s policy/policies cover each principle and its core elements of the NGRBCs. (Yes/No)",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        {
          questions: "b. Has the policy been approved by the Board? (Yes/No)",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        {
          questions: "c. Web Link of the Policies, if available",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        {
          questions: "2. Whether the entity has translated the policy into procedures. (Yes / No)",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        {
          questions: "3. Do the enlisted policies extend to your value chain partners? (Yes/No)",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        {
          questions:
            "4. Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and mapped to each principle.",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        {
          questions: "5. Specific commitments, goals and targets set by the entity with defined timelines, if any.",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        {
          questions:
            "6. Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met.",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        }
      ],
      statementByDirector: "",
      highestAuthorityDetails: "",
      hasSpecifiedCommittee: "",
      ngrbcDirectorReview: [
        {
          subject: "Performance against above policies and follow up action",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        {
          subject:
            "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliances",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        }
      ],
      independentAssessmentAgency: [
        {
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        {
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        }
      ],
      reasonToBeStated: [
        {
          question: "The entity does not consider the Principles material to its business (Yes/No)",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        {
          question:
            "The entity is not at a stage where it is in a position to formulate and implement the policies on specified principles (Yes/No)",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        {
          question:
            "The entity does not have the financial or/human and technical resources available for the task (Yes/No)",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        {
          question: "It is planned to be done in the next financial year (Yes/No)",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        {
          question: "Any other reason (please specify)",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        }
      ],
      ngrbcFrequencyReview: [
        {
          subject: "Performance against above policies and follow up action",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        },
        {
          subject:
            "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliances",
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          p7: "",
          p8: "",
          p9: ""
        }
      ]
    },
    SectionC: {
      principleOne: {
        coverageByTraining: [
          {
            segment: "Board of Directors",
            totalPrograms: "",
            principlesCovered: "",
            peopleCovered: ""
          },
          {
            segment: "Key Managerial Personnel",
            totalPrograms: "",
            principlesCovered: "",
            peopleCovered: ""
          },
          {
            segment: "Employees other than BoD and KMPs",
            totalPrograms: "",
            principlesCovered: "",
            peopleCovered: ""
          },
          {
            segment: "Workers",
            totalPrograms: "",
            principlesCovered: "",
            peopleCovered: ""
          }
        ],

        settlementPenaltyFine: [
          {
            segment: "Penalty/Fine",
            ngrbcPrinciple: "",
            regulatoryName: "",
            amount: "",
            caseBrief: "",
            appealPreffered: ""
          }
        ],
        settlementPenaltySettlement: [
          {
            segment: "Settlement",
            ngrbcPrinciple: "",
            regulatoryName: "",
            amount: "",
            caseBrief: "",
            appealPreffered: ""
          }
        ],
        settlementPenaltyCompoundingFee: [
          {
            segment: "Compounding fee",
            ngrbcPrinciple: "",
            regulatoryName: "",
            amount: "",
            caseBrief: "",
            appealPreffered: ""
          }
        ],
        settlementPenaltyNonMonetary: [
          {
            segment: "Imprisonment",
            ngrbcPrinciple: "",
            regulatoryName: "",
            caseBrief: "",
            appealPreffered: ""
          },
          {
            segment: "Punishment",
            ngrbcPrinciple: "",
            regulatoryName: "",
            caseBrief: "",
            appealPreffered: ""
          }
        ],
        appealPreferred: [
          {
            caseDetail: "",
            regulatoryName: ""
          }
        ],
        antiCorruptionDetails: "",
        weblink: "",
        disciplinaryAction: [
          {
            groupType: "Directors",
            currentYear: "",
            previousYear: ""
          },
          {
            groupType: "KMPs",
            currentYear: "",
            previousYear: ""
          },
          {
            groupType: "Employees",
            currentYear: "",
            previousYear: ""
          },
          {
            groupType: "Workers",
            currentYear: "",
            previousYear: ""
          }
        ],
        conflictOfInterest: [
          {
            complaintType:
              "Number of complaints received in relation to issues of Conflict of Interest of the Directors",
            currentYear: {
              number: "",
              remarks: ""
            },
            previousYear: {
              number: "",
              remarks: ""
            }
          },
          {
            complaintType: "Number of complaints received in relation to issues of Conflict of Interest of the KMPs",
            currentYear: {
              number: "",
              remarks: ""
            },
            previousYear: {
              number: "",
              remarks: ""
            }
          }
        ],
        collectiveAction: "",
        daysOfAccountsPayable: [
          {
            payable: "Number of days of accounts payables",
            currentYear: "",
            previousYear: ""
          }
        ],
        openNessOfBusiness: [
          {
            parameters: "Concentration of Purchases",
            metrics: "a. Purchases from trading houses as % of total purchases",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Concentration of Purchases",
            metrics: "b. Number of trading houses where purchases are made from",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Concentration of Purchases",
            metrics: "c. Purchases from top 10 trading houses as % of total purchases from trading houses",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Concentration of Sales",
            metrics: "a. Sales to dealers / distributors as % of total sales",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Concentration of Sales",
            metrics: "b. Number of dealers / distributors to whom sales are made",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Concentration of Sales",
            metrics: "c. Sales to top 10 dealers / distributors as % of total sales to dealers / distributors",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Share of RPTs in",
            metrics: "a. Purchases (Purchases with related parties / Total Purchases)",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Share of RPTs in",
            metrics: "b. Sales (Sales to related parties / Total Sales)",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Share of RPTs in",
            metrics: "c. Loans & advances (Loans & advances given to related parties / Total loans & advances)",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Share of RPTs in",
            metrics: "d. Investments ( Investments in related parties / Total Investments made)",
            currentYear: "",
            previousYear: ""
          }
        ],
        awarenessPrograms: [
          {
            totalAwarenessPrograms: "",
            topicsCovered: "",
            valueChainPartnersCoverd: ""
          }
        ],
        entityHasProcess: "",
        entityHasProcessDetail: ""
      },
      principleTwo: {
        capitalExpenditure: [
          {
            investments: "R&D",
            currentYear: "",
            previousYear: "",
            envImpact: ""
          },
          {
            investments: "Capex",
            currentYear: "",
            previousYear: "",
            envImpact: ""
          }
        ],
        entityHasProcedures: "",
        sustainablePercent: "",
        sustainablePercentDescription: "",
        plasticRecycle: "",
        eWasteRecycle: "",
        hazardousWasteRecycle: "",
        otherWaste: "",
        ermApplicable: "",
        planInLineWithErm: "",
        lifeCycleAssessment: [
          {
            nicCode: "",
            productName: "",
            percentageTurnover: "",
            assessmentBoundary: "",
            externalAgency: "",
            publicResults: ""
          }
        ],
        envConcerns: [
          {
            productName: "",
            riskDescription: "",
            actionTaken: ""
          }
        ],
        percentageRecycle: [
          {
            inputMaterial: "",
            currentYear: "",
            previousYear: ""
          }
        ],
        recycleReuse: [
          {
            product: "Plastics (including packaging)",
            currentYear: {
              reUsed: "",
              reCycled: "",
              disposed: ""
            },
            previousYear: {
              reUsed: "",
              reCycled: "",
              disposed: ""
            }
          },
          {
            product: "E-waste",
            currentYear: {
              reUsed: "",
              reCycled: "",
              disposed: ""
            },
            previousYear: {
              reUsed: "",
              reCycled: "",
              disposed: ""
            }
          },
          {
            product: "Hazardous waste",
            currentYear: {
              reUsed: "",
              reCycled: "",
              disposed: ""
            },
            previousYear: {
              reUsed: "",
              reCycled: "",
              disposed: ""
            }
          },
          {
            product: "Other waste",
            currentYear: {
              reUsed: "",
              reCycled: "",
              disposed: ""
            },
            previousYear: {
              reUsed: "",
              reCycled: "",
              disposed: ""
            }
          }
        ],
        reclaimedProducts: [
          {
            category: "",
            productPercent: ""
          }
        ]
      },
      principleThree: {
        employeeWellBeing: {
          permanentEmployee: [
            {
              category: "Male",
              total: "",
              healthInsurance: "",
              healthInsurancePercent: "",
              accidentInsurance: "",
              accidentInsurancePercent: "",
              maternityBenefits: "",
              maternityBenefitsPercent: "",
              paternityBenefits: "",
              paternityBenefitsPercent: "",
              dayCareFacility: "",
              dayCareFacilityPercent: ""
            },
            {
              category: "Female",
              total: "",
              healthInsurance: "",
              healthInsurancePercent: "",
              accidentInsurance: "",
              accidentInsurancePercent: "",
              maternityBenefits: "",
              maternityBenefitsPercent: "",
              paternityBenefits: "",
              paternityBenefitsPercent: "",
              dayCareFacility: "",
              dayCareFacilityPercent: ""
            },
            {
              category: "Total",
              total: "",
              healthInsurance: "",
              healthInsurancePercent: "",
              accidentInsurance: "",
              accidentInsurancePercent: "",
              maternityBenefits: "",
              maternityBenefitsPercent: "",
              paternityBenefits: "",
              paternityBenefitsPercent: "",
              dayCareFacility: "",
              dayCareFacilityPercent: ""
            }
          ],
          nonPermanentEmployee: [
            {
              category: "Male",
              total: "",
              healthInsurance: "",
              healthInsurancePercent: "",
              accidentInsurance: "",
              accidentInsurancePercent: "",
              maternityBenefits: "",
              maternityBenefitsPercent: "",
              paternityBenefits: "",
              paternityBenefitsPercent: "",
              dayCareFacility: "",
              dayCareFacilityPercent: ""
            },
            {
              category: "Female",
              total: "",
              healthInsurance: "",
              healthInsurancePercent: "",
              accidentInsurance: "",
              accidentInsurancePercent: "",
              maternityBenefits: "",
              maternityBenefitsPercent: "",
              paternityBenefits: "",
              paternityBenefitsPercent: "",
              dayCareFacility: "",
              dayCareFacilityPercent: ""
            },
            {
              category: "Total",
              total: "",
              healthInsurance: "",
              healthInsurancePercent: "",
              accidentInsurance: "",
              accidentInsurancePercent: "",
              maternityBenefits: "",
              maternityBenefitsPercent: "",
              paternityBenefits: "",
              paternityBenefitsPercent: "",
              dayCareFacility: "",
              dayCareFacilityPercent: ""
            }
          ],
          permanentWorker: [
            {
              category: "Male",
              total: "",
              healthInsurance: "",
              healthInsurancePercent: "",
              accidentInsurance: "",
              accidentInsurancePercent: "",
              maternityBenefits: "",
              maternityBenefitsPercent: "",
              paternityBenefits: "",
              paternityBenefitsPercent: "",
              dayCareFacility: "",
              dayCareFacilityPercent: ""
            },
            {
              category: "Female",
              total: "",
              healthInsurance: "",
              healthInsurancePercent: "",
              accidentInsurance: "",
              accidentInsurancePercent: "",
              maternityBenefits: "",
              maternityBenefitsPercent: "",
              paternityBenefits: "",
              paternityBenefitsPercent: "",
              dayCareFacility: "",
              dayCareFacilityPercent: ""
            },
            {
              category: "Total",
              total: "",
              healthInsurance: "",
              healthInsurancePercent: "",
              accidentInsurance: "",
              accidentInsurancePercent: "",
              maternityBenefits: "",
              maternityBenefitsPercent: "",
              paternityBenefits: "",
              paternityBenefitsPercent: "",
              dayCareFacility: "",
              dayCareFacilityPercent: ""
            }
          ],
          nonPermanentWorker: [
            {
              category: "Male",
              total: "",
              healthInsurance: "",
              healthInsurancePercent: "",
              accidentInsurance: "",
              accidentInsurancePercent: "",
              maternityBenefits: "",
              maternityBenefitsPercent: "",
              paternityBenefits: "",
              paternityBenefitsPercent: "",
              dayCareFacility: "",
              dayCareFacilityPercent: ""
            },
            {
              category: "Female",
              total: "",
              healthInsurance: "",
              healthInsurancePercent: "",
              accidentInsurance: "",
              accidentInsurancePercent: "",
              maternityBenefits: "",
              maternityBenefitsPercent: "",
              paternityBenefits: "",
              paternityBenefitsPercent: "",
              dayCareFacility: "",
              dayCareFacilityPercent: ""
            },
            {
              category: "Total",
              total: "",
              healthInsurance: "",
              healthInsurancePercent: "",
              accidentInsurance: "",
              accidentInsurancePercent: "",
              maternityBenefits: "",
              maternityBenefitsPercent: "",
              paternityBenefits: "",
              paternityBenefitsPercent: "",
              dayCareFacility: "",
              dayCareFacilityPercent: ""
            }
          ]
        },
        spendingOnWellBeing: [
          {
            costIncurred: "Cost incurred on well-being measures as a % of total revenue of the company",
            currentYear: "",
            previousYear: ""
          }
        ],
        retirementBenifits: [
          {
            benifits: "PF",
            currentYear: {
              employeesCovered: "",
              workersCovered: "",
              deductedDeposited: ""
            },
            previousYear: {
              employeesCovered: "",
              workersCovered: "",
              deductedDeposited: ""
            }
          },
          {
            benifits: "Gratuity",
            currentYear: {
              employeesCovered: "",
              workersCovered: "",
              deductedDeposited: ""
            },
            previousYear: {
              employeesCovered: "",
              workersCovered: "",
              deductedDeposited: ""
            }
          },
          {
            benifits: "ESI",
            currentYear: {
              employeesCovered: "",
              workersCovered: "",
              deductedDeposited: ""
            },
            previousYear: {
              employeesCovered: "",
              workersCovered: "",
              deductedDeposited: ""
            }
          },
          {
            benifits: "Others-Specify",
            currentYear: {
              employeesCovered: "",
              workersCovered: "",
              deductedDeposited: ""
            },
            previousYear: {
              employeesCovered: "",
              workersCovered: "",
              deductedDeposited: ""
            }
          }
        ],
        accessibleToEmployees: "",
        equalOpportunityPolicy: "",
        retentionRate: {
          male: {
            gender: "Male",
            permanentEmployee: {
              returnToWork: "",
              retention: ""
            },
            nonPermanentEmployee: {
              returnToWork: "",
              retention: ""
            }
          },
          female: {
            gender: "Female",
            permanentEmployee: {
              returnToWork: "",
              retention: ""
            },
            nonPermanentEmployee: {
              returnToWork: "",
              retention: ""
            }
          },
          total: {
            gender: "Total",
            permanentEmployee: {
              returnToWork: "",
              retention: ""
            },
            nonPermanentEmployee: {
              returnToWork: "",
              retention: ""
            }
          }
        },
        grievanceMechanism: [
          {
            typeOfEmployee: "Permanent Workers",
            grievanceMechanismExists: "",
            grievanceMechanismDetail: ""
          },
          {
            typeOfEmployee: "Other than Permanent Workers",
            grievanceMechanismExists: "",
            grievanceMechanismDetail: ""
          },
          {
            typeOfEmployee: "Permanent Employees",
            grievanceMechanismExists: "",
            grievanceMechanismDetail: ""
          },
          {
            typeOfEmployee: "Other than Permanent Employees",
            grievanceMechanismExists: "",
            grievanceMechanismDetail: ""
          }
        ],
        membershipOfEmployees: [
          {
            category: "Total Permanent Employees",
            currentYear: {
              totalEmployees: "",
              employeeOfAssociation: ""
            },
            previousYear: {
              totalEmployees: "",
              employeeOfAssociation: ""
            }
          },
          {
            category: "Male",
            currentYear: {
              totalEmployees: "",
              employeeOfAssociation: ""
            },
            previousYear: {
              totalEmployees: "",
              employeeOfAssociation: ""
            }
          },
          {
            category: "Female",
            currentYear: {
              totalEmployees: "",
              employeeOfAssociation: ""
            },
            previousYear: {
              totalEmployees: "",
              employeeOfAssociation: ""
            }
          },
          {
            category: "Total Permanent Workers",
            currentYear: {
              totalEmployees: "",
              employeeOfAssociation: ""
            },
            previousYear: {
              totalEmployees: "",
              employeeOfAssociation: ""
            }
          },
          {
            category: "Male",
            currentYear: {
              totalEmployees: "",
              employeeOfAssociation: ""
            },
            previousYear: {
              totalEmployees: "",
              employeeOfAssociation: ""
            }
          },
          {
            category: "Female",
            currentYear: {
              totalEmployees: "",
              employeeOfAssociation: ""
            },
            previousYear: {
              totalEmployees: "",
              employeeOfAssociation: ""
            }
          }
        ],
        trainingGivenToEmployee: [
          {
            category: "Male",
            currentYear: {
              total: "",
              healthAndSafety: "",
              skillUpgradation: "",
              healthAndSafetyPercent: "",
              skillUpgradationPercent: ""
            },
            previousYear: {
              total: "",
              healthAndSafety: "",
              skillUpgradation: "",
              healthAndSafetyPercent: "",
              skillUpgradationPercent: ""
            }
          },
          {
            category: "Female",
            currentYear: {
              total: "",
              healthAndSafety: "",
              skillUpgradation: "",
              healthAndSafetyPercent: "",
              skillUpgradationPercent: ""
            },
            previousYear: {
              total: "",
              healthAndSafety: "",
              skillUpgradation: "",
              healthAndSafetyPercent: "",
              skillUpgradationPercent: ""
            }
          },
          {
            category: "Total",
            currentYear: {
              total: "",
              healthAndSafety: "",
              skillUpgradation: "",
              healthAndSafetyPercent: "",
              skillUpgradationPercent: ""
            },
            previousYear: {
              total: "",
              healthAndSafety: "",
              skillUpgradation: "",
              healthAndSafetyPercent: "",
              skillUpgradationPercent: ""
            }
          }
        ],
        trainingGivenToWorker: [
          {
            category: "Male",
            currentYear: {
              total: "",
              healthAndSafety: "",
              skillUpgradation: "",
              healthAndSafetyPercent: "",
              skillUpgradationPercent: ""
            },
            previousYear: {
              total: "",
              healthAndSafety: "",
              skillUpgradation: "",
              healthAndSafetyPercent: "",
              skillUpgradationPercent: ""
            }
          },
          {
            category: "Female",
            currentYear: {
              total: "",
              healthAndSafety: "",
              skillUpgradation: "",
              healthAndSafetyPercent: "",
              skillUpgradationPercent: ""
            },
            previousYear: {
              total: "",
              healthAndSafety: "",
              skillUpgradation: "",
              healthAndSafetyPercent: "",
              skillUpgradationPercent: ""
            }
          },
          {
            category: "Total",
            currentYear: {
              total: "",
              healthAndSafety: "",
              skillUpgradation: "",
              healthAndSafetyPercent: "",
              skillUpgradationPercent: ""
            },
            previousYear: {
              total: "",
              healthAndSafety: "",
              skillUpgradation: "",
              healthAndSafetyPercent: "",
              skillUpgradationPercent: ""
            }
          }
        ],
        performanceReviewEmployee: [
          {
            category: "Male",
            currentYear: {
              total: "",
              number: "",
              numberPercent: ""
            },
            previousYear: {
              total: "",
              number: "",
              numberPercent: ""
            }
          },
          {
            category: "Female",
            currentYear: {
              total: "",
              number: "",
              numberPercent: ""
            },
            previousYear: {
              total: "",
              number: "",
              numberPercent: ""
            }
          },
          {
            category: "Total",
            currentYear: {
              total: "",
              number: "",
              numberPercent: ""
            },
            previousYear: {
              total: "",
              number: "",
              numberPercent: ""
            }
          }
        ],
        performanceReviewWorker: [
          {
            category: "Male",
            currentYear: {
              total: "",
              number: "",
              numberPercent: ""
            },
            previousYear: {
              total: "",
              number: "",
              numberPercent: ""
            }
          },
          {
            category: "Female",
            currentYear: {
              total: "",
              number: "",
              numberPercent: ""
            },
            previousYear: {
              total: "",
              number: "",
              numberPercent: ""
            }
          },
          {
            category: "Total",
            currentYear: {
              total: "",
              number: "",
              numberPercent: ""
            },
            previousYear: {
              total: "",
              number: "",
              numberPercent: ""
            }
          }
        ],
        healthAndSafetyImplemented: "",
        hazardIdentifyProcess: "",
        reportHazard: "",
        accessToHealthcare: "",
        safetyRelatedIncidents: [
          {
            safetyIncidents: "Lost Time Injury Frequency Rate (LTIFR) (per one million-person hours worked)",
            ngrbcPrinciple: "Employees",
            regulatoryName: "",
            briefOfCase: ""
          },
          {
            parameters: "Lost Time Injury Frequency Rate (LTIFR) (per one million-person hours worked)",
            ngrbcPrinciple: "Workers",
            regulatoryName: "",
            briefOfCase: ""
          },
          {
            safetyIncidents: "Total recordable work-related injuries",
            ngrbcPrinciple: "Employees",
            regulatoryName: "",
            briefOfCase: ""
          },
          {
            parameters: "Total recordable work-related injuries",
            ngrbcPrinciple: "Workers",
            regulatoryName: "",
            briefOfCase: ""
          },
          {
            safetyIncidents: "No. of fatalities",
            ngrbcPrinciple: "Employees",
            regulatoryName: "",
            briefOfCase: ""
          },
          {
            parameters: "No. of fatalities",
            ngrbcPrinciple: "Workers",
            regulatoryName: "",
            briefOfCase: ""
          },
          {
            safetyIncidents: "High consequence work-related injury or ill-health (excluding fatalities)",
            ngrbcPrinciple: "Employees",
            regulatoryName: "",
            briefOfCase: ""
          },
          {
            parameters: "High consequence work-related injury or ill-health (excluding fatalities)",
            ngrbcPrinciple: "Workers",
            regulatoryName: "",
            briefOfCase: ""
          }
        ],
        measureToEnsureSafety: "",
        numberOfComplaints: [
          {
            complaintType: "Working Conditions",
            currentYear: {
              filedDuringYear: "",
              resolutionPending: "",
              remarks: ""
            },
            previousYear: {
              filedDuringYear: "",
              resolutionPending: "",
              remarks: ""
            }
          },
          {
            complaintType: "Health & Safety",
            currentYear: {
              filedDuringYear: "",
              resolutionPending: "",
              remarks: ""
            },
            previousYear: {
              filedDuringYear: "",
              resolutionPending: "",
              remarks: ""
            }
          }
        ],
        assessmentsForYear: [
          {
            assessmentType: "Health and safety practices",
            assessedPlants: ""
          },
          {
            assessmentType: "Working Conditions",
            assessedPlants: ""
          }
        ],
        correctiveActionsTaken: "",
        lifeEnsuranceEmployee: "",
        lifeEnsuranceWorker: "",
        measureTakenForStatutoryDues: "",
        workRelatedInjuryEmployee: [
          {
            category: "Employees",
            affectedEmployees: {
              currentYear: "",
              previousYear: ""
            },
            rehabilitatedEmployee: {
              currentYear: "",
              previousYear: ""
            }
          }
        ],
        workRelatedInjuryWorker: [
          {
            category: "Workers",
            affectedWorkers: {
              currentYear: "",
              previousYear: ""
            },
            rehabilitatedWorkers: {
              currentYear: "",
              previousYear: ""
            }
          }
        ],
        transAssitanceProgram: "",
        assessedValueChainPartners: [
          {
            assessmentType: "Health and safety practices",
            assessedPartners: ""
          },
          {
            assessmentType: "Working Conditions",
            assessedPartners: ""
          }
        ],
        correctiveActionsForEmployees: ""
      },
      principleFour: {
        processOfIdentification: "",
        capitalExpenditure: [
          {
            stakeHolderGroup: "",
            vulnerable: "",
            channelsOfCommunication: "",
            frequencyOfEngangement: "",
            purposeOfEngangement: ""
          }
        ],
        processOfConsultation: "",
        consultationForSupport: "",
        instancesOfEngagement: ""
      },
      principleFive: {
        trainingOnHumanRightsEmployees: [
          {
            category: "Permanent",
            currentYear: {
              total: "",
              noOfEmployees: "",
              employeePercent: ""
            },
            previousYear: {
              total: "",
              noOfEmployees: "",
              employeePercent: ""
            }
          },
          {
            category: "Other than permanent",
            currentYear: {
              total: "",
              noOfEmployees: "",
              employeePercent: ""
            },
            previousYear: {
              total: "",
              noOfEmployees: "",
              employeePercent: ""
            }
          },
          {
            category: "Total Employees",
            currentYear: {
              total: "",
              noOfEmployees: "",
              employeePercent: ""
            },
            previousYear: {
              total: "",
              noOfEmployees: "",
              employeePercent: ""
            }
          }
        ],
        trainingOnHumanRightsWorkers: [
          {
            category: "Permanent",
            currentYear: {
              total: "",
              noOfEmployees: "",
              workerPercent: ""
            },
            previousYear: {
              total: "",
              noOfEmployees: "",
              workerPercent: ""
            }
          },
          {
            category: "Other than permanent",
            currentYear: {
              total: "",
              noOfEmployees: "",
              workerPercent: ""
            },
            previousYear: {
              total: "",
              noOfEmployees: "",
              workerPercent: ""
            }
          },
          {
            category: "Total Workers",
            currentYear: {
              total: "",
              noOfEmployees: "",
              workerPercent: ""
            },
            previousYear: {
              total: "",
              noOfEmployees: "",
              workerPercent: ""
            }
          }
        ],
        minimumWageEmployees: [
          {
            category: "Permanent",
            currentYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: "",
              equalPercent: "",
              moreThanPercent: ""
            },
            previousYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: "",
              equalPercent: "",
              moreThanPercent: ""
            }
          },
          {
            category: "Male",
            currentYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            },
            previousYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            }
          },
          {
            category: "Female",
            currentYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            },
            previousYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            }
          },
          {
            category: "Other than Permanent",
            currentYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            },
            previousYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            }
          },
          {
            category: "Male",
            currentYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            },
            previousYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            }
          },
          {
            category: "Female",
            currentYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            },
            previousYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            }
          }
        ],
        minimumWageWorkers: [
          {
            category: "Permanent",
            currentYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            },
            previousYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            }
          },
          {
            category: "Male",
            currentYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            },
            previousYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            }
          },
          {
            category: "Female",
            currentYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            },
            previousYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            }
          },
          {
            category: "Other than Permanent",
            currentYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            },
            previousYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            }
          },
          {
            category: "Male",
            currentYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            },
            previousYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            }
          },
          {
            category: "Female",
            currentYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            },
            previousYear: {
              total: "",
              equalToMinimum: "",
              moreThanMinimum: ""
            }
          }
        ],
        medianRemuneration: [
          {
            category: "Board of Directors (BoD)",
            male: {
              number: "",
              salary: ""
            },
            female: {
              number: "",
              salary: ""
            }
          },
          {
            category: "Key Managerial Personnel",
            male: {
              number: "",
              salary: ""
            },
            female: {
              number: "",
              salary: ""
            }
          },
          {
            category: "Employees other than BoD and KMP",
            male: {
              number: "",
              salary: ""
            },
            female: {
              number: "",
              salary: ""
            }
          },
          {
            category: "Workers",
            male: {
              number: "",
              salary: ""
            },
            female: {
              number: "",
              salary: ""
            }
          }
        ],
        grossFemaleWages: [
          {
            title: "Gross wages paid to females as % of total wages",
            currentYear: "",
            previousYear: ""
          }
        ],
        internalMechanismToRefressGrievance: "",
        processOfConsultation: "",
        noOfComplaintsByEmployee: [
          {
            category: "Sexual Harassment",
            currentYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            }
          },
          {
            category: "Discrimination at workplace",
            currentYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            }
          },
          {
            category: "Child Labour",
            currentYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            }
          },
          {
            category: "Forced Labour/Involuntary Labour",
            currentYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            }
          },
          {
            category: "Wages",
            currentYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            }
          },
          {
            category: "Other human rights related issues",
            currentYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              filedDuringYear: "",
              pendingResolution: "",
              remarks: ""
            }
          }
        ],
        complaintsFiledUnderHarassment: [
          {
            category:
              "Total Complaints reported under Sexual Harassment on of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013 (POSH)",
            currentYear: "",
            previousYear: ""
          },
          {
            category: "Complaints on POSH as a % of female employees / workers",
            currentYear: "",
            previousYear: ""
          },
          {
            category: "Complaints on POSH upheld",
            currentYear: "",
            previousYear: ""
          }
        ],
        preventAdverseConsequencesToComplaints: "",
        humanRightsPartOfContract: "",
        plantsAndOfficessAssessed: [
          {
            category: "Child labour",
            percentageAssessed: ""
          },
          {
            category: "Forced/involuntary labour",
            percentageAssessed: ""
          },
          {
            category: "Sexual harassment",
            percentageAssessed: ""
          },
          {
            category: "Discrimination at workplace",
            percentageAssessed: ""
          },
          {
            category: "Wages",
            percentageAssessed: ""
          },
          {
            category: "Others – please specify",
            percentageAssessed: ""
          }
        ],
        correctiveActionSignificantRiskForTen: "",
        detailsOfBusinessProcessModification: "",
        detailsOfScopeByHumanRights: "",
        accessibleToDifferntlyAbled: "",
        valueChainPartnerAssessed: [
          {
            category: "Child labour",
            percentageAssessed: ""
          },
          {
            category: "Forced/involuntary labour",
            percentageAssessed: ""
          },
          {
            category: "Sexual harassment",
            percentageAssessed: ""
          },
          {
            category: "Discrimination at workplace",
            percentageAssessed: ""
          },
          {
            category: "Wages",
            percentageAssessed: ""
          },
          {
            category: "Others – please specify",
            percentageAssessed: ""
          }
        ],
        correctiveActionSignificantRiskForFour: ""
      },
      principleSix: {
        totalEnergyConsumptionRenewable: [
          {
            parameters: "Total electricity consumption (A)",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Total fuel consumption (B)",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Energy consumption through other sources (C)",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Total energy consumed from renewable sources (A+B+C)",
            currentYear: "",
            previousYear: ""
          }
        ],
        totalEnergyConsumptionRenewableNote: "",
        totalEnergyConsumptionNonRenewable: [
          {
            parameters: "Total electricity consumption (D)",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Total fuel consumption (E)",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Energy consumption through other sources (F)",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Total energy consumed from non renewable sources (D+E+F) ",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Total energy consumed (A+B+C+D+E+F)",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Energy intensity per rupee of turnover (Total energy consumed / Revenue from operations)",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters:
              "Energy intensity per rupee of turnover adjusted for Purchasing Power Parity (PPP) (Total energy consumed / Revenue from operations adjusted for PPP)",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Energy intensity in terms of physical output",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Energy intensity (optional) – the relevant metric may be selected by the entity",
            currentYear: "",
            previousYear: ""
          }
        ],
        totalEnergyConsumptionNonRenewableNote: "",
        designatedConsumerSites: "",
        disclosureWater: [
          {
            parameters: "(i) Surface water",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(ii) Groundwater",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(iii) Third party water",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(iv) Seawater / desalinated water",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(v) Others",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Total volume of water withdrawal (in kilo litres) (i + ii + iii + iv + v)",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Total volume of water consumption (in kilo litres)",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Water intensity per rupee of turnover (Total water consumption / Revenue from operations)",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters:
              "Water intensity per rupee of turnover adjusted for Purchasing Power Parity (PPP) (Total water consumption / Revenue from operations adjusted for PPP)",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Water intensity in terms of physical output",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Water intensity (optional) – the relevant metric may be selected by the entity",
            currentYear: "",
            previousYear: ""
          }
        ],
        disclosureWaterNote: "",
        waterDischarge: [
          {
            parameters: "(i) To Surface water",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- No treatment",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- With treatment – please specify level of treatment",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(ii) To Groundwater",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- No treatment",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- With treatment – please specify level of treatment",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(iii) To Seawater",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- No treatment",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- With treatment – please specify level of treatment",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(iv) Sent to third-parties",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- No treatment",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- With treatment – please specify level of treatment",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(v) Others",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- No treatment",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- With treatment – please specify level of treatment",
            currentYear: "",
            previousYear: ""
          }
        ],
        waterDischargeNote: "",
        zeroLiquidDischarge: "",
        airEmissions: [
          {
            parameters: "NOx",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "SOx",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Particulate matter (PM)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Persistent organic pollutants (POP)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Volatile organic compounds (VOC)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Hazardous air pollutants (HAP)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Others – please specify",
            unit: "",
            currentYear: "",
            previousYear: ""
          }
        ],
        airEmissionsNote: "",
        ghgEmissions: [
          {
            parameters:
              "Total Scope 1 emissions (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters:
              "Total Scope 2 emissions (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters:
              "Total Scope 1 and Scope 2 emission intensity per rupee of turnover (Total Scope 1 and Scope 2 GHG emissions / Revenue from operations)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters:
              "Total Scope 1 and Scope 2 emission intensity per rupee of turnover adjusted for Purchasing Power Parity (PPP) (Total Scope 1 and Scope 2 GHG emissions / Revenue from operations adjusted for PPP)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Total Scope 1 and Scope 2 emission intensity in terms of physical output",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters:
              "Total Scope 1 and Scope 2 emission intensity (optional) – the relevant metric may be selected by the entity",
            unit: "",
            currentYear: "",
            previousYear: ""
          }
        ],
        externalAgencyEvaluation: "",
        greenHouseReductionProjects: "",
        externalAgencyEvaluation1: "",
        waterDischargeByDestinationNote: "",
        scope3EmissionDetailsNote: "",
        wasteMangementByEntity: [
          {
            parameters: "Plastic waste (A)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "E-waste (B)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Bio-medical waste (C)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Construction and demolition waste (D)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Battery waste (E)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Radioactive waste (F)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Other Hazardous waste. Please specify, if any. (G)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters:
              "Other Non-hazardous waste generated (H). Please specify, if any. (Break-up by composition i.e. by materials relevant to the sector)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Total (A+B + C + D + E + F + G + H)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Waste intensity per rupee of turnover (Total waste generated / Revenue from operations)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters:
              "Waste intensity per rupee of turnover adjusted for Purchasing Power Parity (PPP) (Total waste generated / Revenue from operations adjusted for PPP)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Waste intensity in terms of physical output",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Waste intensity (optional) – the relevant metric may be selected by the entity",
            unit: "",
            currentYear: "",
            previousYear: ""
          }
        ],
        wasteMangementByEntityNote: "",
        wasteRecovered: [
          {
            parameters: "(i) Recycled",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(ii) Re-used",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(iii) Other recovery operations",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Total",
            unit: "",
            currentYear: "",
            previousYear: ""
          }
        ],
        wasteRecoveredNote: "",
        totalWasteDisposed: [
          {
            parameters: "(i) Incineration",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(ii) Landfilling",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(iii) Other disposal operations",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Total",
            unit: "",
            currentYear: "",
            previousYear: ""
          }
        ],
        totalWasteDisposedNote: "",
        wasteManagementPractice: "",
        operationInSensitiveAreas: [
          {
            location: "",
            typeOfOperations: "",
            clearanceComplied: ""
          }
        ],
        environmentalImpact: [
          {
            name: "",
            eiaNo: "",
            date: "",
            externalAgency: "",
            publicCommunication: "",
            webLink: ""
          }
        ],
        environMentalLawCompliance: [
          {
            specifyLaw: "",
            detailsOfNonCompliance: "",
            conditionsComplied: "",
            correctiveActions: ""
          }
        ],
        waterWithdrawel: [
          {
            nameOfArea: "",
            detailsOfNonCompliance: "",
            natureOfOperation: ""
          }
        ],
        waterWithdrawelInFormat: [
          {
            parameters: "(i) Surface water",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(ii) Groundwater",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(iii) Third party water",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(iv) Seawater / desalinated water",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(v) Others",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Total volume of water withdrawal (in kilolitres)",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Total volume of water consumption (in kilolitres)",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Water intensity per rupee of turnover (Water consumed / turnover)",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Water intensity (optional) – the relevant metric may be selected by the entity",
            currentYear: "",
            previousYear: ""
          }
        ],
        waterDischargeByDestination: [
          {
            parameters: "(i) Surface water",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- No treatment",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- With treatment – please specify level of treatment",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(ii) To Groundwater",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- No treatment",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- With treatment – please specify level of treatment",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(iii) To Seawater",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- No treatment",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- With treatment – please specify level of treatment",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(iv) Sent to third-parties",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- No treatment",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- With treatment – please specify level of treatment",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "(v) Others",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- No treatment",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "- With treatment – please specify level of treatment",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Total water discharged (in kilo litres)",
            currentYear: "",
            previousYear: ""
          }
        ],
        scope3EmissionDetails: [
          {
            parameters:
              "Total Scope 3 emissions (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available)",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters: "Total Scope 3 emissions per rupee of turnover",
            unit: "",
            currentYear: "",
            previousYear: ""
          },
          {
            parameters:
              "Total Scope 3 emission intensity (optional) – the relevant metric may be selected by the entity",
            unit: "",
            currentYear: "",
            previousYear: ""
          }
        ],
        impactOnBiodiversity: "",
        innovativeTechnologyUsed: [
          {
            initiativeUndertaken: "",
            initiativeDetails: "",
            intiativeOutcome: ""
          }
        ],
        disasterManagementPlan: "",
        impactOnEnviromentByValuChain: "",
        percentageOfValuChainPartners: ""
      },
      principleSeven: {
        affiliatedWithTrade: "",
        topTenTradeChambers: [
          {
            nameOfTrade: "",
            reachOfTrade: ""
          },
          {
            nameOfTrade: "",
            reachOfTrade: ""
          },
          {
            nameOfTrade: "",
            reachOfTrade: ""
          },
          {
            nameOfTrade: "",
            reachOfTrade: ""
          },
          {
            nameOfTrade: "",
            reachOfTrade: ""
          },
          {
            nameOfTrade: "",
            reachOfTrade: ""
          },
          {
            nameOfTrade: "",
            reachOfTrade: ""
          },
          {
            nameOfTrade: "",
            reachOfTrade: ""
          },
          {
            nameOfTrade: "",
            reachOfTrade: ""
          },
          {
            nameOfTrade: "",
            reachOfTrade: ""
          }
        ],
        correctiveActionsTakenBrief: [
          {
            nameOfAuthority: "",
            briefOfCase: "",
            correctiveActions: ""
          }
        ],
        publicPolicyPositionDetails: [
          {
            publicPolicyAdvocate: "",
            infoAvailableToPublic: "",
            frequencyOfReview: "",
            webLink: ""
          }
        ]
      },
      principleEight: {
        socialImpactAssessmentDetails: [
          {
            projectName: "",
            siaNotifNo: "",
            dateOfNotif: "",
            conductedByExternalAgency: "",
            resultInPublicDomain: "",
            webLink: ""
          }
        ],
        rehabProjectInfo: [
          {
            projectName: "",
            state: "",
            district: "",
            projectAffectedFamily: "",
            pafCovered: "",
            pafAmountPaid: ""
          }
        ],
        grievanceMechanism: "",
        percentInputMaterial: [
          {
            title: "Directly sourced from MSMEs/ small producers",
            currentYear: "",
            previousYear: ""
          },
          {
            title: "Directly from within India",
            currentYear: "",
            previousYear: ""
          }
        ],
        jobCreationSmallTown: [
          {
            location: "Rural",
            currentYear: "",
            previousYear: ""
          },
          {
            location: "Semi-urban",
            currentYear: "",
            previousYear: ""
          },
          {
            location: "Urban",
            currentYear: "",
            previousYear: ""
          },
          {
            location: "Metropolitan",
            currentYear: "",
            previousYear: ""
          }
        ],
        negativeSocialImpactMitigation: [
          {
            negativeSocialImpact: "",
            correctiveActions: ""
          }
        ],
        csrProject: [
          {
            state: "",
            aspDistrict: "",
            amountSpent: ""
          }
        ],
        preferentialProcurementPolicy: "",
        procurementGroup: "",
        percentOfTotalProcurement: "",
        benefitsDerivedFromProperty: [
          {
            intellectualProperty: "",
            owned: "",
            benifitsShared: "",
            basisOfCalculation: ""
          }
        ],
        correctiveActionUnderwayOrTaken: [
          {
            authorityName: "",
            briefOfCase: "",
            correctiveActions: ""
          }
        ],
        csrProjectTwo: [
          {
            csrProjectName: "",
            peopleBenifited: "",
            vulnerablePeople: ""
          }
        ]
      },
      principleNine: {
        consumerComplaintResponseMechanism: "",
        productTurnover: [
          {
            title: "Environmental and social parameters relevant to the product",
            percentOfTotal: ""
          },
          {
            title: "Safe and responsible usage",
            percentOfTotal: ""
          },
          {
            title: "Recycling and/or safe disposal",
            percentOfTotal: ""
          }
        ],
        totalConsumerComplaints: [
          {
            category: "Data privacy",
            currentYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            }
          },
          {
            category: "Advertising",
            currentYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            }
          },
          {
            category: "Cyber-security",
            currentYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            }
          },
          {
            category: "Delivery of essential services",
            currentYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            }
          },
          {
            category: "Restrictive Trade Practices",
            currentYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            }
          },
          {
            category: "Unfair Trade Practices",
            currentYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            }
          },
          {
            category: "Other",
            currentYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            },
            previousYear: {
              total: "",
              pendingResolution: "",
              remarks: ""
            }
          }
        ],
        productRecallInstanceDetail: [
          {
            title: "Voluntary recalls",
            number: "",
            reason: ""
          },
          {
            title: "Forced recalls",
            number: "",
            reason: ""
          }
        ],
        policyOnCyberSecurity: "",
        correctiveActionAdvertising: "",
        dataBreachInstances: "",
        personalInformationDataBreach: "",
        dataBreachImpact: "",
        productAccessPlatforms: "",
        safeUsageInformationProvided: "",
        mechanismToInformConsumerOfRisk: "",
        entityDisplaysProductInfo: ""
      }
    }
  },
  pageWiseErrors: {
    SectionA: {
      corporateNumberError: false
    }
  }
};

export const brsrReportSlice = createSlice({
  name: "brsrReport",
  initialState: initialBrsrReportState,
  reducers: {
    setPageWiseValue: (state, action) => {
      return {
        ...state,
        pageWiseValue: action.payload
      };
    },

    setPageWiseErrors: (state, action) => {
      return {
        ...state,
        pageWiseErrors: action.payload
      };
    }
  }
});

export const { setPageWiseValue, setPageWiseErrors } = brsrReportSlice.actions;

export default brsrReportSlice.reducer;
