import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Stack } from "@mui/system";
import { Popover, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSnackbar } from "../../components/snackbar/SnackbarContext";
import {
  useGetFacilityDataQuery,
  useLazyGetCategoryTableDataQuery,
  useLazyGetDataEntriesQuery,
  useLazyGetTemplateListQuery,
  useUpdateEmissionEntryMutation,
  usePostAuditLogDataMutation,
  useGetOrganizationDataQuery,
  useLazyGetUserDetailByIdQuery
} from "../../state/api";
import "./DataEntries.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";
import { apiUrl } from "../../components/ApiUrl";
import { fetchData } from "../../helper/ErrorHandler";

const icons = {
  edit: "images/icons/edit.svg",
  success: "images/icons/success-icon.svg",
  cancel: "images/icons/cancel-icon.svg"
};

const DataEntries = ({ canEdit, tab, setTabValue }) => {
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate()
  const [getTemplate, resultTemplate] = useLazyGetTemplateListQuery();
  const { data } = useGetFacilityDataQuery();
  const [getCategoryTable, resultCategory] = useLazyGetCategoryTableDataQuery();
  const [getDataEntries, resultData] = useLazyGetDataEntriesQuery();
  const [updateEmission] = useUpdateEmissionEntryMutation();

  const [postAuditLogData] = usePostAuditLogDataMutation({});

  const monthAbbreviations = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const currentYear = new Date().getFullYear();
  const previousYears = [];
  for (let i = 0; i < 10; i++) {
    previousYears.push(currentYear - i);
  }

  const postPerPage = 12;
  const [currentPage, SetCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [templateList, setTemplateList] = useState();
  const [templateData, setTemplateData] = useState();
  const [facilityData, setFacilityData] = useState("");
  const [monthData, setMonthData] = useState("");
  const [yearData, setYearData] = useState(currentYear);
  const [editEnabled, setEditEnabled] = useState();
  const [newObjArr, setNewObj] = useState();
  const [tableHeaders, setTableHeaders] = useState();
  const [formData, setFormData] = useState({});
  const [isEvidenceUploaded, setIsEvidenceUploaded] = useState()
  const [uploadFile, setUploadFile] = useState(null);
  const [userFileReader, setUserFileReader] = useState({});

  const inputRefs = useRef([]);

  const [updatedRowData, setUpdatedRowData] = useState({});

  const [prevFormData, setPrevFormData] = useState({});

  const userId = useSelector((state) => state.global.user);

  const orgData = useGetOrganizationDataQuery();

  const [user, setUser] = useState(null);
  const [getUser, userResult] = useLazyGetUserDetailByIdQuery();
  useEffect(() => {
    if (userResult.data?.data) {
      setUser(userResult.data?.data);
    }
  }, [userResult.data?.data]);

  useEffect(() => {
    getUser({ id: userId._id }, true);
  }, []);

  const filterValueInScope = ["All Scope", "Scope 1", "Scope 2", "Scope 3"];

  const [scopeValue, setscopeValue] = useState(filterValueInScope[0]);
  const [prevTemplateList, setPrevTemplateList] = useState();
  useEffect(() => {
    getTemplate();
    if (resultTemplate.data?.data && resultTemplate.data?.data.length > 0) {
      let rTemp;

      if (tab === "airemission") {
        rTemp = resultTemplate.data?.data?.filter((item) => item.scope.name === "air emission");
      } else {
        rTemp = resultTemplate.data?.data.filter((item) => item.scope.name !== "air emission");
      }

      setTemplateData(rTemp[0].templetes[0]._id);
      let newArr = [];
      let editArray = [];
      rTemp.forEach((item, key) => {
        newArr = [...newArr, ...item.templetes];
        editArray.push(false);
      });
      setTemplateList(newArr);
      setPrevTemplateList(newArr);
      setEditEnabled(editArray);
    }
  }, [resultTemplate.data]);

  useEffect(() => {
    if (templateData) {
      getCategoryTable(templateData);
    }

    if (resultCategory.data) {
      setTableHeaders(resultCategory.data.data.headers);
    }
  }, [resultCategory.data, templateData]);

  useEffect(() => {
    let rTemp;
    if (resultTemplate.data?.data) {
      if (tab === "airemission") {
        rTemp = resultTemplate.data?.data?.filter((item) => item.scope.name === "air emission");
      } else {
        rTemp = resultTemplate.data?.data.filter((item) => item.scope.name !== "air emission");
      }

      let body = {
        templete: rTemp[0].templetes[0]._id,
        year: yearData,
        from: `${parseInt(currentPage) - 1 < 0 ? 0 : parseInt(currentPage) - 1}`,
        size: postPerPage
      };
      getDataEntries(body);
      setTemplateData(rTemp[0].templetes[0]._id);
    }
  }, [resultTemplate.data?.data]);

  useEffect(() => {
    if (resultData.data?.data) {
      setNewObj(resultData.data?.data?.emissionList);
      setTotalCount(resultData.data?.data.totalCount);
    }
  }, [currentPage, resultData.data?.data?.emissionList, resultData.data?.data?.totalCount]);

  useEffect(() => {
    if (tab !== "airemission" && templateList) {
      const syntheticEvent = {
        target: {
          name: "templete",
          value: templateList[0]._id
        }
      };

      handleChange(syntheticEvent);
    }
  }, [scopeValue]);

  const handleChange = (e) => {
    let body = {
      templete: templateData,
      facility: facilityData,
      bill: isEvidenceUploaded,
      year: yearData,
      month: monthData,
      from: `${parseInt(currentPage) - 1 < 0 ? 0 : parseInt(currentPage) - 1}`,
      size: postPerPage
    };

    if (!monthData) delete body.month;
    if (!facilityData) delete body.facility;
    if (!isEvidenceUploaded) delete body.bill;

    switch (e.target.name) {
      case "templete":
        getCategoryTable(e.target.value);
        setTemplateData(e.target.value);
        body = { ...body, [e.target.name]: e.target.value };
        break;
      case "facility":
        setFacilityData(e.target.value);
        body = { ...body, [e.target.name]: e.target.value };
        if (!e.target.value) {
          delete body[e.target.name];
        }
        break;
      case "bill":
        setIsEvidenceUploaded(e.target.value);
        body = { ...body, [e.target.name]: e.target.value };
        if (!e.target.value) {
          delete body[e.target.name];
        }
        break;


      case "month":
        setMonthData(e.target.value);
        body = { ...body, [e.target.name]: e.target.value };
        if (!e.target.value) {
          delete body[e.target.name];
        }
        break;
      case "year":
        setYearData(e.target.value);
        body = { ...body, [e.target.name]: e.target.value };
        break;

      default:
        break;
    }
    getDataEntries(body);
  };

  const handlePageChange = async (e, value) => {
    if (value - 1 < 0) {
      SetCurrentPage(0);
    } else {
      SetCurrentPage((value - 1) * postPerPage);
    }
    try {
      if (value - 1 < 0) {
        await getDataEntries(getPayLoad(0));
      } else {
        await getDataEntries(getPayLoad((value - 1) * postPerPage));
      }
    } catch (error) {
      console.log(error);
      //  openSnackbar('successfull', 'success')
    }
  };

  const enableRowEdit = (row) => {
    setFormData(row);

    setPrevFormData(row);

    setUpdatedRowData(row);

    setEditEnabled(row._id);
  };

  const findFieldValues = (row, tableHeaders) => {
    const results = {};
    for (const key of Object.keys(row)) {
      const header = tableHeaders.find((item) => item.fieldName === key);
      if (header) {
        results[key] = header.values;
      } else {
        results[key] = null;
      }
    }
    return results;
  };

  const result = findFieldValues(prevFormData, tableHeaders);

  // console.log(result);

  function getChangedKeysWithValues(original, modified) {
    const changedKeys = {};
    for (const key in original) {
      if (original[key] !== modified[key]) {
        changedKeys[key] = {
          previous: original[key],
          current: modified[key]
        };
      }
    }
    return changedKeys;
  }
  const newObj = getChangedKeysWithValues(prevFormData, updatedRowData);

  // console.log(newObj , "------")

  const buildLookupTables = (lookupData) => {
    const lookupTables = {};

    for (const [key, value] of Object.entries(lookupData)) {
      if (Array.isArray(value) && value.length > 0 && value[0]._id) {
        lookupTables[key] = value.reduce((map, item) => {
          if (item._id && item.name) {
            map[item._id] = item.name;
          }
          return map;
        }, {});
      }
    }

    return lookupTables;
  };

  const replacePreviousValues = (changes, lookupTables) => {
    const updatedChanges = {};

    for (const [key, { previous, current }] of Object.entries(changes)) {
      const lookupTable = lookupTables[key];
      if (lookupTable) {
        updatedChanges[key] = {
          previous: lookupTable[previous] || previous,
          current: current
        };
      } else {
        updatedChanges[key] = {
          previous: previous,
          current: current
        };
      }
    }

    return updatedChanges;
  };

  const lookupTables = buildLookupTables(result);

  const updatedChanges = replacePreviousValues(newObj, lookupTables);

  function formatChangeMessage(changedFields) {
    let message = ``;
    for (const [field, { previous, current }] of Object.entries(changedFields)) {
      message += `'${field}' changed from '${previous}' to '${current}'.\n`;
    }
    return message;
  }

  const submitData = async () => {
    try {
      let payload = { ...formData };
      const id = formData._id;
      delete payload._id;
      payload = { id, data: payload, template: resultCategory.data.data._id };

      const response = await updateEmission(payload);
      if (response.error) {
        openSnackbar(response.error.data.message, "warning");
        return;
      }

      if (Object.keys(updatedChanges).length > 0) {
        postAuditLogData({
          action: "UPDATE",
          message: `${formatChangeMessage(updatedChanges)} by ${user.name}`,
          tab: "Measure",
          facility: "",
          organization: orgData.data?.data._id,
          user: user._id,
          ipAddress: "10.10.0.1"
        });
      }

      openSnackbar(response.data.message, "success");
      setFormData({});
      setEditEnabled("");
      getDataEntries(getPayLoad(currentPage));
    } catch (err) {
      openSnackbar(JSON.stringify(err), "error");
    }
  };

  const getFieldValue = (header, row) => {
    if (typeof header.values[0] === "object" && header.values[0]._id) {
      const headerValue = header.values.find((x) => x._id === row[header.fieldName]);
      if (!headerValue) return "";
      return headerValue.name;
    } else {
      return row[header.fieldName];
    }
  };

  const getPayLoad = (pageNo) => {
    let body = {
      templete: templateData ? templateData : resultTemplate.data?.data[0].templetes[0]._id,
      facility: facilityData,
      bill: isEvidenceUploaded,
      year: yearData,
      month: monthData,
      from: `${parseInt(pageNo) - 1 < 0 ? 0 : parseInt(pageNo) - 1}`,
      size: postPerPage
    };

    if (!monthData) delete body.month;
    if (!facilityData) delete body.facility;
    if (!isEvidenceUploaded) delete body.bill;

    return body;
  };

  const getDropDownOptions = (fieldName) => {
    //This function is to get dropDown Values for select dropdowns
    const header = tableHeaders.find((x) => x.fieldName === fieldName);
    let actualValues = [];
    if (header?.dependentHeaders?.length) {
      const rowData = formData;
      const dependentHeaderValues = header.dependentHeaders.reduce((acc, item) => {
        acc[item] = rowData[tableHeaders.find((x) => x._id === item)?.fieldName];
        return acc;
      }, {});
      actualValues = header.values.find((value) => {
        const isMatch = Object.keys(dependentHeaderValues).every((key) => {
          return value.dependentValues.some(
            (dv) => dv.dependentField === key && dv.value === dependentHeaderValues[key]
          );
        });
        return isMatch;
      });
      if (actualValues) {
        actualValues = actualValues.values;
      } else return [];
    } else actualValues = header?.values;

    return actualValues?.map((val) => ({
      value: val._id || val,
      label: val._id ? val.name : val
    }));
  };

  const handleInputChange = (fieldName, value) => {
    // This function handles Input value changes
    const newFormData = JSON.parse(JSON.stringify(formData));
    newFormData[fieldName] = value;
    setFormData(newFormData);
  };

  const handleSelectChange = (fieldName, value) => {
    // This function handles dropdown value changes
    const newFormData = JSON.parse(JSON.stringify(formData));
    updateRowValues(tableHeaders, newFormData, fieldName, value);
    setFormData(newFormData);

    const selectedOption = getDropDownOptions(fieldName).find((option) => option.value === value);

    // Update form data with the selected option's value
    setUpdatedRowData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption ? selectedOption.label : value
    }));
  };

  const updateRowValues = (headers, row, fieldName, value) => {
    //This function is used to update all row values if a dropdown value changes. Column Dependency handling
    const header = headers.find((x) => x.fieldName === fieldName);
    row[fieldName] = value;
    const dependencyHeaders = headers.filter((x) => x?.dependentHeaders?.includes(header._id));
    if (dependencyHeaders.length) {
      dependencyHeaders.forEach((x) => {
        updateRowValues(headers, row, x.fieldName, undefined);
      });
    } else return row;
  };

  const downloadBill = (url) => {
    const s3Url = url;
    const link = document.createElement("a");
    link.href = s3Url;
    link.target = "_blank";
    link.setAttribute("download", `FileName.pdf`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  useEffect(() => {
    if (scopeValue === "All Scope") {
      setTemplateList(prevTemplateList);
    } else {
      let filterArray = prevTemplateList?.filter((item) =>
        item.subLabel.toLowerCase().includes(scopeValue.toLowerCase())
      );
      setTemplateList(filterArray);
    }
  }, [scopeValue]);

  useEffect(() => {
    if (templateList && templateList?.length) {
      let body = {
        templete: templateData,
        facility: facilityData,
        bill: isEvidenceUploaded,
        year: yearData,
        month: monthData,
        from: `${parseInt(currentPage) - 1 < 0 ? 0 : parseInt(currentPage) - 1}`,
        size: postPerPage
      };

      if (!monthData) delete body.month;
      if (!facilityData) delete body.facility;
      if (!isEvidenceUploaded) delete body.bill;

      body = { ...body, ["templete"]: templateList[0]._id };

      setTemplateData(templateList[0]._id)
      getDataEntries(body)
    }
  }, [templateList])

  const hnadleAddData = () => {
    setTabValue("1")
  }

  const handleScopeChange = (value) => {
    setscopeValue(value)
  }

  const uploadFileInitiate = (index) => {
    const newFormData = JSON.parse(JSON.stringify(formData));

    if (newFormData["bill"]) {
      return;
    }
    if (inputRefs.current[index]) {
      inputRefs.current[index].click();
    }
  };

  const uploadFileInitiateNew = (index) => {
    if (inputRefs.current[index]) {
      inputRefs.current[index].click();
    }
  };

  const handleFileChange = (key, event) => {
    try {
      const file = event.target.files;

      if (file && file.length > 0) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setUploadFile(file[0]);
          setUserFileReader({ index: key, file: reader.result });
        };
        reader.readAsDataURL(file[0]);
      }
    } catch (error) {
      openSnackbar(error.message, "error");
    }
  };

  const handleFileUpload = async () => {
    // setIsFileLoading(true);
    if (uploadFile) {
      const formData = new FormData();
      formData.append("files", uploadFile);

      try {
        let fileReponse = await fetchData(`${apiUrl}/emission/upload/bill`, {
          method: "POST",
          credentials: "include",
          body: formData
        });
        let fileData = await fileReponse.json();
        // setIsFileLoading(false)
        return fileData.data;
      } catch (error) {
        // setIsFileLoading(false)
        throw error;
      }
    }
    // setIsFileLoading(false);
  };

  const deleteFile = () => {
    const newFormData = JSON.parse(JSON.stringify(formData));
    newFormData["bill"] = null
    setFormData(newFormData)
  };

  useEffect(() => {
    const updateFileUrl = async () => {
      try {
        const newFormData = JSON.parse(JSON.stringify(formData));
        let fileUrl = await handleFileUpload();
        newFormData["bill"] = fileUrl
        setFormData(newFormData)
        openSnackbar("File Uploaded", "success");
      } catch (error) {
        const errorMessage = error.response?.data?.message || "An error occurred";
        openSnackbar(errorMessage, "error");
      }
    };

    if (uploadFile) {
      updateFileUrl();
    }
  }, [uploadFile]);

  const popOverContent = (index) => (
    <div className="d-flex gap-2">
      <CustomTooltip
        content={"<p>Upload</p>"}
        showIcon={true}
        id={"facStatus-info"}
        childComponent={
          <div onClick={() => uploadFileInitiateNew(index)} className="cursor-pointer rounded-full hover-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M6 6H16C19.3137 6 22 8.68629 22 12C22 15.3137 19.3137 18 16 18H6C3.79086 18 2 16.2091 2 14C2 13.6914 2.03494 13.391 2.10108 13.1025C2.50845 11.3255 4.09943 10 6 10L16 10C17.1046 10 18 10.8954 18 12C18 13.1046 17.1046 14 16 14H6"
                stroke="#7E7E8F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        }
      />
      <CustomTooltip
        content={"<p>Download</p>"}
        showIcon={true}
        id={"facStatus-info"}
        childComponent={
          <div onClick={() => downloadBill(index)} className="cursor-pointer rounded-full hover-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
              />
            </svg>
          </div>
        }
      />
      <CustomTooltip
        content={"<p>Delete</p>"}
        showIcon={true}
        id={"facStatus-info"}
        childComponent={
          <div className="cursor-pointer rounded-full hover-icon">
            <img
              src="/images/icons/delete-icon.svg"
              alt=""
              onClick={() => deleteFile(index)}
              className="cursor-pointer"
            />
          </div>
        }
      />
    </div>
  );

  return (
    <>
      <div className="consumption-wrapper">
        <div className="d-flex justify-content-between align-items-center mt-15">
          <div className="d-inline-flex">
            <div className="mt-2">
              {tab === "airemission" ? (
                ""
              ) : (
                <Select
                  showSearch
                  value={scopeValue}
                  placeholder="Select Scope"
                  name="Scope"
                  optionFilterProp="Scope"
                  size="large"
                  onChange={(value) => handleScopeChange(value)}
                  style={{ width: "150px" }}
                >
                  {filterValueInScope?.map((item, ind) => (
                    <Select.Option key={ind} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </div>
            <div className="ms-10">
              <select
                value={templateData}
                onChange={handleChange}
                name="templete"
                className={`form-select modalInput`}
                aria-label=".form-select-lg example"
                style={{ width: `${tab === "airemission" ? "" : "410px"}` }}
              >
                {templateList &&
                  templateList.length > 0 &&
                  templateList.map((item, key) =>
                    tab === "airemission"
                      ? item.label === "Air Emission" && (
                        <option key={key} value={item._id}>
                          {item.label}
                        </option>
                      )
                      : item.label !== "Air Emission" && (
                        <option key={key} value={item._id}>
                          {item.label}
                        </option>
                      )
                  )}
              </select>
            </div>
            <div className="ms-10">
              <select
                value={facilityData}
                onChange={handleChange}
                name="facility"
                className={`form-select modalInput`}
                aria-label=".form-select-lg example"
              >
                <option value="">All Facilities</option>
                {user?.facilities?.map((item, key) => (
                  <option key={key} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="ms-10">
              <select
                value={isEvidenceUploaded}
                onChange={handleChange}
                name="bill"
                className={`form-select modalInput`}
                aria-label=".form-select-lg example"
              >
                <option value="">All Evidences</option>
                <option value="uploaded">Uploaded</option>
                <option value="not uploaded">Not Uploaded</option>
              </select>
            </div>


          </div>

          <div className="d-inline-flex">
            <div>
              <select
                value={monthData}
                onChange={handleChange}
                name="month"
                className={`form-select modalInput`}
                aria-label=".form-select-lg example"
              >
                <option value="">All Months</option>
                {monthAbbreviations.map((item, key) => (
                  <option key={key} value={item}>
                    <p>{item}</p>
                  </option>
                ))}
              </select>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <select
                value={yearData}
                onChange={handleChange}
                name="year"
                className={`form-select modalInput`}
                aria-label=".form-select-lg example"
              >
                {previousYears.map((item, key) => (
                  <option key={key} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {newObjArr && newObjArr.length > 0 ? (
          <TableContainer className="tableContainerStyle">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow className="fw-bold">
                  {tableHeaders?.map((key) => (
                    <TableCell>{key.label}</TableCell>
                  ))}
                  <TableCell className={`${canEdit ? "stickyTableCellHeader2" : "stickyTableCellHeader"}`}>
                    <p>Emission</p>
                    <p>(TCO2e)</p>
                  </TableCell>
                  {canEdit && <TableCell className="stickyTableCellHeader">Action</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {newObjArr.map((row, rowIndex) => (
                  <TableRow>
                    {tableHeaders?.map((key, index) => (
                      <TableCell className="emissionCell">
                        {editEnabled === row._id ? (
                          key.type === "dropdown" ? (
                            <Select
                              name={key.fieldName}
                              value={formData[key.fieldName]}
                              onChange={(value) => handleSelectChange(key.fieldName, value)}
                              required={key.isMandatory}
                              className="modalInputTable text-start"
                              size="large"
                              filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                              options={getDropDownOptions(key.fieldName)}
                              disabled={key?.dependentHeaders?.length && !getDropDownOptions(key.fieldName).length}
                            ></Select>
                          ) : key.type === "upload" ? (
                            <div
                              className="d-flex justify-content-center cursor-pointer"
                              onClick={() => uploadFileInitiate(key)}
                            >
                              {
                                formData["bill"] ? (
                                  <Popover
                                    style={{ width: "100px", height: "100px" }}
                                    trigger="click"
                                    placement="bottom"
                                    title={""}
                                    content={popOverContent(key)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                                      />
                                    </svg>
                                  </Popover>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M6 6H16C19.3137 6 22 8.68629 22 12C22 15.3137 19.3137 18 16 18H6C3.79086 18 2 16.2091 2 14C2 13.6914 2.03494 13.391 2.10108 13.1025C2.50845 11.3255 4.09943 10 6 10L16 10C17.1046 10 18 10.8954 18 12C18 13.1046 17.1046 14 16 14H6"
                                      stroke="#7E7E8F"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                )
                              }
                              <input
                                type="file"
                                ref={(el) => (inputRefs.current[key] = el)}
                                style={{ display: "none" }}
                                onChange={(e) => handleFileChange(key, e)}
                              />
                            </div>
                          ) :
                            (
                              <input
                                type={key.dataType === "string" ? "text" : "number"}
                                name={key.fieldName}
                                value={formData[key.fieldName] || ""}
                                onChange={(e) => handleInputChange(key.fieldName, e.target.value)}
                                required={key.isMandatory}
                                className="form-select modalInput"
                              />
                            )
                        ) : key.type === "dropdown" ? (
                          getFieldValue(key, row)
                        ) : isNaN(parseFloat(row[key.fieldName]).toFixed(2)) ? (
                          key.fieldName === "bill" && row["bill"] ? (
                            <div onClick={() => downloadBill(row["bill"])} className="cursor-pointer">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M8 5L5 5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19L19 19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5L16 5M12 15L12 4M12 15L8 11M12 15L16 11"
                                  stroke="#1671CA"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          ) : (
                            row[key.fieldName]
                          )
                        ) : (
                          parseFloat(row[key.fieldName]).toFixed(2)
                        )}
                      </TableCell>
                    ))}
                    <TableCell className={`${canEdit ? "stickyTableCell2" : "stickyTableCell"} emissionCell`}>
                      {row.totalEmission?.toFixed(4)}
                    </TableCell>
                    <TableCell className="stickyTableCell">
                      <div className="d-flex justify-content-center">
                        {editEnabled === row._id ? (
                          <>
                            <img
                              src={icons.success}
                              alt=""
                              onClick={() => submitData(rowIndex)}
                              className="cursor-pointer"
                            />
                            <img
                              src={icons.cancel}
                              alt=""
                              className="cursor-pointer"
                              onClick={() => setEditEnabled("")}
                            />
                          </>
                        ) : (
                          canEdit && (
                            <img
                              src={icons.edit}
                              alt=""
                              className="cursor-pointer"
                              onClick={(e) => enableRowEdit(row)}
                            />
                          )
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <div className="empty-container ">
              <img src="/empty-box.svg" alt="Empty image" style={{ width: "236px" }} />
              <p>No existing data entries to show. Please add data.</p>
              <button type="button" style={{ width: "176px" }} onClick={hnadleAddData} className="btn btn-success">
                + Add new data
              </button>
            </div>
          </div>
        )}
        <div className="mt-auto">
          <Stack spacing={2}>
            <Pagination count={Math.ceil(totalCount / postPerPage)} shape="rounded" onChange={handlePageChange} />
          </Stack>
        </div>
      </div>
    </>
  );
};

export default DataEntries;
