import { createSlice } from "@reduxjs/toolkit";

const initialSocialState = {
  pageWiseData: {

    Employment: {
      newEmployeeTurnover: {
        accordionTitle: "New Employee and Employee Turnover",
        newHireEmployee: [
          {
            title: "New employee hires",
            newHireEmployeCount: "",
            newHireEmployeRatio: ""
          }
        ],
        employeesTurnover: [
          {
            title: "Employees Turnover",
            newHireEmployeTurnOver: "",
            newHireEmployeTurnOverRatio: ""
          }
        ],
        voluntaryTurnover: [
          {
            title: "Voluntary Turnover",
            newHireEmployeTurnOver: "",
            newHireEmployeTurnOverRatio: ""
          }
        ],

        noteNewEmployeeGenderAgeRegion: "",
        employeeGenderWise: [
          {
            title: "Male",
            newHireEmployeCount: "",
            newHireEmployeePercentage: "",
            newHireEmployeRatio: ""
          },
          {
            title: "Female",
            newHireEmployeCount: "",
            newHireEmployeePercentage: "",
            newHireEmployeRatio: ""
          }
        ],
        employeeAgeWise: [
          {
            title: "Under 30 years old",
            newHireEmployeCount: "",
            newHireEmployeePercentage: "",
            newHireEmployeRatio: ""
          },
          {
            title: "30-50 years old",
            newHireEmployeCount: "",
            newHireEmployeePercentage: "",
            newHireEmployeRatio: ""
          },
          {
            title: "Over 50 years old",
            newHireEmployeCount: "",
            newHireEmployeePercentage: "",
            newHireEmployeRatio: ""
          }
        ],
        employeeRegionWise: [
          {
            title: "",
            newHireEmployeCount: "",
            newHireEmployeePercentage: "",
            newHireEmployeRatio: ""
          }
        ],

        noteEmployeeTornoverByGenderAgeRegion: "",

        employeeTurnoverGenderWise: [
          {
            title: "Male",
            newHireEmployeCount: "",
            newHireEmployeePercentage: "",
            newHireEmployeRatio: ""
          },
          {
            title: "Female",
            newHireEmployeCount: "",
            newHireEmployeePercentage: "",
            newHireEmployeRatio: ""
          }
        ],
        employeeTurnoverAgeWise: [
          {
            title: "Under 30 years old",
            newHireEmployeCount: "",
            newHireEmployeePercentage: "",
            newHireEmployeRatio: ""
          },
          {
            title: "30-50 years old",
            newHireEmployeCount: "",
            newHireEmployeePercentage: "",
            newHireEmployeRatio: ""
          },
          {
            title: "Over 50 years old",
            newHireEmployeCount: "",
            newHireEmployeePercentage: "",
            newHireEmployeRatio: ""
          }
        ],
        employeeTurnOverRegionWise: [
          {
            title: "Region",
            newHireEmployeCount: "",
            newHireEmployeePercentage: "",
            newHireEmployeRatio: ""
          }
        ]
      },

      benefitsProvided: {
        accordionTitle:
          "Benefits provided to full-time employees that are not provide to temporary or part-time employees",

        employeeByLocationOperation: [
          {
            location: "Location",
            numberOfFTEWithLifeInsurance: "",
            insuPercentage: "",
            numberOfFTEWithHealthInsurance: "",
            disabilityCoverage: "",
            coveragePercent: "",
            retirementProvision: "",
            provisionPercent: "",
            stockOwnership: "",
            ownerShipPercent: "",
            otherBenefits: ""
          }
        ],

        employeeBenefitsKind: [
          {
            location: "Location",
            sportsFacilities: "",
            careFacilities: "",
            workTtime: "",
            other: ""
          }
        ]
      },

      parentalLeave: {
        accordionTitle: "Parental Leave",

        employeeParentalLeave: [
          {
            title: "Male",
            totalNumberOfEmployeesEntitledPL: "",
            totalEmployeewhoTookPL: "",
            totalEmployeereturnToWorkAfterPL: "",
            workRatio: "",
            retentionRatio: ""
          },
          {
            title: "Female",
            totalNumberOfEmployeesEntitledPL: "",
            totalEmployeewhoTookPL: "",
            totalEmployeereturnToWorkAfterPL: ""
          },
          {
            title: "Total",
            totalNumberOfEmployeesEntitledPL: "",
            totalEmployeewhoTookPL: "",
            totalEmployeereturnToWorkAfterPL: ""
          }
        ]
      }
    },

    LaborManagementRelations: {
      noticePeriod: {
        accordionTitle: "Details about notice period",
        aboutNoticePeriod: [
          {
            title:
              "Minimum number of weeks' notice typically provided to employees and their representatives prior to the implementation of significant operational changes that could substantially affect them",
            yesNo: "",
            NumberOfWeeks: ""
          }
        ],
        collectiveAgreements: ""
      }
    },

   
    occupationalHealthSafety: {

      occupationalHealthSafetyMgmt: {
        accordionTitle: "Occupational Health and Safety Management",
        firstForeword: "",
        secondForeword: "",
        thirdForeword: ""
      },

      hazardIdentification: {
        accordionTitle: "Hazard Identification, risk assessment and incident investigation",
        firstForeword: "",
        secondForeword: "",
        thirdForeword: "",
        fourthForeword: ""
      },
      occupationalHealthService: {
        accordionTitle: "Occupational Health Service",
        firstForeword: ""
        // secondForeword: "",
        // thirdForeword: ""
      },
      workerParticipation: {
        accordionTitle: "Worker participation, Consultation, and Communication on occupational health and safety",
        firstForeword: "",
        secondForeword: ""
      },

      workerTraining:{
        accordionTitle:"Worker Training on Occupational Health and Safety",
         workerTrainingForeword: "",
       },

      promotionWorkerHealth: {
        accordionTitle:"Promotion of Worker Health",
        firstForeword: "",
        secondForeword: ""
      },

      preventionAndMitigation:{
        accordionTitle:"Prevention and mitigation of occupational health and safety impacts directly linked by business relationships Promotion of Worker Health",
        preventionAndMitigationForeword: "",
      },

      workersCovered: {
        accordionTitle:"Workers covered by an occupational health and safety management system",
        firstTable: [
          {
            title: "Number",
            numberPercentage: ""
          },
          {
            title: "Percentage",
            numberPercentage: ""
          }
        ],

        secondTable: [
          {
            title: "Number",
            numberPercentage: ""
          },
          {
            title: "Percentage",
            numberPercentage: ""
          }
        ],

        thirdTable: [
          {
            title: "Number",
            numberPercentage: ""
          },
          {
            title: "Percentage---",
            numberPercentage: ""
          }
        ],

        secondForeword: "",
        thirdForeword: ""
      },

      workRelatedInjury: {
        accordionTitle:"Work related injury",
        forAllEmployeesSection: {
          forAllEmployees: [
            {
              parameters: "Assembly",
              workrelatedinjury: "1.Recordable work-related injuries",
              number: "",
              rate: ""
            },
            {
              parameters: "Assembly",
              workrelatedinjury: "2.Recordable work-related injuries",
              number: "",
              rate: ""
            },
            {
              parameters: "Assembly",
              workrelatedinjury: "3.Recordable work-related injuries",
              number: "",
              rate: ""
            },
            {
              parameters: "Total",
              workrelatedinjury: "1.Fatalities as a result of work-related injury",
              number: "",
              rate: ""
            },
            {
              parameters: "Total",
              workrelatedinjury: "2.High consequence work-related injury (excluding fatalities)",
              number: "",
              rate: ""
            },
            {
              parameters: "Total",
              workrelatedinjury: "3.Recordable work-related injuries",
              number: "",
              rate: ""
            }
          ],

          bodyPart: [
            {
              parameter: "Back",
              assembly: "",
              total: ""
            },
            {
              parameter: "Eyes",
              assembly: "",
              total: ""
            },
            {
              parameter: "Feet",
              assembly: "",
              total: ""
            },
            {
              parameter: "Hand",
              assembly: "",
              total: ""
            },
            {
              parameter: "Legs",
              assembly: "",
              total: ""
            },
            {
              parameter: "Multiple parts or Whole body",
              assembly: "",
              total: ""
            },
            {
              parameter: "Neck",
              assembly: "",
              total: ""
            },
            {
              parameter: "Thorax",
              assembly: "",
              total: ""
            },
            {
              parameter: "Total number of lost time injuries",
              assembly: "",
              total: ""
            }
          ],

          injuriesByType: [
            {
              parameter: "Abrasion/cut/scratch",
              assembly: "",
              total: ""
            },
            {
              parameter: "Bone fracture",
              assembly: "",
              total: ""
            },
            {
              parameter: "Burn injury",
              assembly: "",
              total: ""
            },
            {
              parameter: "Chemical exposure",
              assembly: "",
              total: ""
            },
            {
              parameter: "Contusion/bruise",
              assembly: "",
              total: ""
            },
            {
              parameter: "Electric shock",
              assembly: "",
              total: ""
            },
            {
              parameter: "Eye injury",
              assembly: "",
              total: ""
            },
            {
              parameter: "Hearing loss",
              assembly: "",
              total: ""
            },
            {
              parameter: "Heatstroke/hypothermia",
              assembly: "",
              total: ""
            },
            {
              parameter: "Loss of consciousness",
              assembly: "",
              total: ""
            },
            {
              parameter: "Loss of limb",
              assembly: "",
              total: ""
            },
            {
              parameter: "Poisoning",
              assembly: "",
              total: ""
            },
            {
              parameter: "Sprain/strain/dislocation",
              assembly: "",
              total: ""
            },
            {
              parameter: "Whiplash injury",
              assembly: "",
              total: ""
            },
            {
              parameter: "Other",
              assembly: "",
              total: ""
            },
            {
              parameter: "Total number of lost time injuries",
              assembly: "",
              total: ""
            }
          ],

          hoursWorked: [
            {
              parameter: "Total",
              numberOfHoursWorked: ""
            },
            {
              parameter: "Assembly",
              numberOfHoursWorked: ""
            }
          ]
        },

        forAllWorkersSection: {
          forAllEmployees: [
            {
              parameters: "Total",
              workrelatedinjury: "1.Fatalities as a result of work-related injury",
              number: "",
              rate: ""
            },
            {
              parameters: "Total",
              workrelatedinjury: "2.High consequence work-related injury (excluding fatalities)",
              number: "",
              rate: ""
            },
            {
              parameters: "Total",
              workrelatedinjury: "3.Recordable work-related injuries",
              number: "",
              rate: ""
            },
            {
              parameters: "Assembly",
              workrelatedinjury: "1.Recordable work-related injuries",
              number: "",
              rate: ""
            },
            {
              parameters: "Assembly",
              workrelatedinjury: "2.Recordable work-related injuries",
              number: "",
              rate: ""
            },
            {
              parameters: "Assembly",
              workrelatedinjury: "3.Recordable work-related injuries",
              number: "",
              rate: ""
            }
          ],

          bodyPart: [
            {
              parameter: "Back",
              assembly: "",
              total: ""
            },
            {
              parameter: "Eyes",
              assembly: "",
              total: ""
            },
            {
              parameter: "Feet",
              assembly: "",
              total: ""
            },
            {
              parameter: "Hand",
              assembly: "",
              total: ""
            },
            {
              parameter: "Legs",
              assembly: "",
              total: ""
            },
            {
              parameter: "Multiple parts or Whole body",
              assembly: "",
              total: ""
            },
            {
              parameter: "Neck",
              assembly: "",
              total: ""
            },
            {
              parameter: "Thorax",
              assembly: "",
              total: ""
            },
            {
              parameter: "Total number of lost time injuries",
              assembly: "",
              total: ""
            }
          ],

          injuriesByType: [
            {
              parameter: "Abrasion/cut/scratch",
              assembly: "",
              total: ""
            },
            {
              parameter: "Bone fracture",
              assembly: "",
              total: ""
            },
            {
              parameter: "Burn injury",
              assembly: "",
              total: ""
            },
            {
              parameter: "Chemical exposure",
              assembly: "",
              total: ""
            },
            {
              parameter: "Contusion/bruise",
              assembly: "",
              total: ""
            },
            {
              parameter: "Electric shock",
              assembly: "",
              total: ""
            },
            {
              parameter: "Eye injury",
              assembly: "",
              total: ""
            },
            {
              parameter: "Hearing loss",
              assembly: "",
              total: ""
            },
            {
              parameter: "Heatstroke/hypothermia",
              assembly: "",
              total: ""
            },
            {
              parameter: "Loss of consciousness",
              assembly: "",
              total: ""
            },
            {
              parameter: "Loss of limb",
              assembly: "",
              total: ""
            },
            {
              parameter: "Poisoning",
              assembly: "",
              total: ""
            },
            {
              parameter: "Sprain/strain/dislocation",
              assembly: "",
              total: ""
            },
            {
              parameter: "Whiplash injury",
              assembly: "",
              total: ""
            },
            {
              parameter: "Other",
              assembly: "",
              total: ""
            },
            {
              parameter: "Total number of lost time injuries",
              assembly: "",
              total: ""
            }
          ],

          hoursWorked: [
            {
              parameter: "Total",
              numberOfHoursWorked: ""
            },
            {
              parameter: "Assembly",
              numberOfHoursWorked: ""
            }
          ]
        }

        // forEmployeesFirstTable: [
        //   {
        //     title: "Year (total)",
        //     numberOfFatalities: "",
        //     rateOfFatalities: ""
        //   }
        // ],

        // forEmployeesSecondTable: [
        //   {
        //     title: "Year (total)",
        //     numberOfInjuries: "",
        //     rateOfInjuries: ""
        //   }
        // ],
        // forEmployeesThirdTable: [
        //   {
        //     title: "Year (total)",
        //     numberOfInjuries: "",
        //     rateOfInjuries: ""
        //   }
        // ]
      },

      detailsOfWork:{
        accordionTitle:" Details of work related hazards",
        detailsOfWworkRelatedHazards: [
          {
            heading: "Incidents of work related hazards",
            foreword: ""
          },
          {
            heading: "How these hazards were determined ?",
            foreword: ""
          },
          {
            heading: "Which of these hazards have caused or contributed to high-consequence injuries?",
            foreword: ""
          },
          {
            heading:
              "Actions taken or underway to eliminate other work related hazards and minimize risks using hierarchy of controls",
            foreword: ""
          },
          {
            heading:
              "Any actions taken or underway to eliminate other work-related hazards and minimize risks using hierarchy of controls",
            foreword: ""
          },
          {
            heading: "Whether the rates have been calculated based on 200,000 or 1,000,000 hours worked",
            foreword: ""
          },
          {
            heading:
              "Whether and, if so, why any workers have been excluded from this disclosure, including the types of worker excluded",
            foreword: ""
          },
          {
            heading:
              "Any contextual information necessary to understand how the data have been compiled, such as any standards, methodologies and assumptions used",
            foreword: ""
          }
        ]
      },
    },

    trainingAndEducation: {
      reportingPeriod: {
        accordionTitle:
          "Average hours of training that the organization’s employees have undertaken during the reporting period",
        byGender: [
          {
            title: "Male",
            averageTrainingHours: ""
          },
          {
            title: "Female",
            averageTrainingHours: ""
          },
          {
            title: "Total Employee",
            averageTrainingHours: ""
          }
        ],

        byEmployeeCategory: [
          {
            category: "Permanent Employee",
            averageTraininghours: ""
          },
          {
            category: "Male",
            averageTraininghours: ""
          },
          {
            category: "Female",
            averageTraininghours: ""
          },
          {
            category: "Temporary Employee",
            averageTraininghours: ""
          },
          {
            category: "Male",
            averageTraininghours: ""
          },
          {
            category: "Female",
            averageTraininghours: ""
          },
          {
            category: "Non-guaranteed hours employee",
            averageTraininghours: ""
          },
          {
            category: "Male",
            averageTraininghours: ""
          },
          {
            category: "Female",
            averageTraininghours: ""
          },
          {
            category: "Full time employee",
            averageTraininghours: ""
          },
          {
            category: "Male",
            averageTraininghours: ""
          },
          {
            category: "Female",
            averageTraininghours: ""
          },
          {
            category: "Part time employee",
            averageTraininghours: ""
          },
          {
            category: "Male",
            averageTraininghours: ""
          },
          {
            category: "Female",
            averageTraininghours: ""
          }
        ]
      },

      assistanceProgram: {
        accordionTitle: "Programs for upgrading employee skills and transition assistance program",
        upgradingEmployeeskill: {
          foreword: ""
        },
        careerEndingsResulting: {
          foreword: ""
        }
      },

      employeePercentageOfCareerDevelopment: {
        accordionTitle: "Percentage of employees who received a regular performance and career development",
        byGender: [
          {
            title: "Male",
            number: "",
            percentage: ""
          },
          {
            title: "Female",
            number: "",
            percentage: ""
          }
        ],

        byEmployeeCategory: [
          {
            category: "Permanent Employee",
            number: "",
            percentage: ""
          },
          {
            category: "Male",
            number: "",
            percentage: ""
          },
          {
            category: "Female",
            number: "",
            percentage: ""
          },
          {
            category: "Temporary Employee",
            number: "",
            percentage: ""
          },
          {
            category: "Male",
            number: "",
            percentage: ""
          },
          {
            category: "Female",
            number: "",
            percentage: ""
          },
          {
            category: "Non-guaranteed hours employee",
            number: "",
            percentage: ""
          },
          {
            category: "Male",
            number: "",
            percentage: ""
          },
          {
            category: "Female",
            number: "",
            percentage: ""
          },
          {
            category: "Full time employee",
            number: "",
            percentage: ""
          },
          {
            category: "Male",
            number: "",
            percentage: ""
          },
          {
            category: "Female",
            number: "",
            percentage: ""
          },
          {
            category: "Part time employee",
            number: "",
            percentage: ""
          },
          {
            category: "Male",
            number: "",
            percentage: ""
          },
          {
            category: "Female",
            number: "",
            percentage: ""
          }
        ]
      }
    },

    diversityEqualOpportunity: {
      governanceBody: {
        accordionTitle: "Percentage of individuals within the organization's governance body",
        percentageOfIndividuals: [
          {
            title: "Male",
            number: "",
            percentage: ""
          },
          {
            title: "Female",
            number: "",
            percentage: ""
          },
          {
            title: "Total employee",
            number: "",
            percentage: ""
          },
          {
            title: "By age group",
            number: "",
            percentage: ""
          },
          {
            title: "Under 30 years old",
            number: "",
            percentage: ""
          },
          {
            title: "30-50 years old",
            number: "",
            percentage: ""
          },
          {
            title: "Over 50 years old",
            number: "",
            percentage: ""
          },
          {
            title: "Other indicators of diversity",
            number: "",
            percentage: ""
          },
          {
            title: "",
            number: "",
            percentage: ""
          }
        ]
      },

      percentageOfEmployees: {
        accordionTitle: "Percentage of employees per employee category in each of the following diversity categories",
        percentageOfEmployeesPerEmployee: [
          {
            category: "Permanent Employee",
            number: "",
            percentage: ""
          },
          {
            category: "Male",
            number: "",
            percentage: ""
          },
          {
            category: "Female",
            number: "",
            percentage: ""
          },
          {
            category: "Temporary Employee",
            number: "",
            percentage: ""
          },
          {
            category: "Male",
            number: "",
            percentage: ""
          },
          {
            category: "Female",
            number: "",
            percentage: ""
          },
          {
            category: "Non-guaranteed hours employee",
            number: "",
            percentage: ""
          },
          {
            category: "Male",
            number: "",
            percentage: ""
          },
          {
            category: "Female",
            number: "",
            percentage: ""
          },
          {
            category: "Full time employee",
            number: "",
            percentage: ""
          },
          {
            category: "Male",
            number: "",
            percentage: ""
          },
          {
            category: "Female",
            number: "",
            percentage: ""
          },
          {
            category: "Part time employee",
            number: "",
            percentage: ""
          },
          {
            category: "Male",
            number: "",
            percentage: ""
          },
          {
            category: "Female",
            number: "",
            percentage: ""
          },

          {
            category: "By age group",
            number: "",
            percentage: ""
          },
          {
            category: "Under 30 years old",
            number: "",
            percentage: ""
          },
          {
            category: "30-50 years old",
            number: "",
            percentage: ""
          },
          {
            category: "Over 50 years old",
            number: "",
            percentage: ""
          },
          {
            category: "Other indicators of diversity",
            number: "",
            percentage: ""
          },
          {
            category: "",
            number: "",
            percentage: ""
          }
        ]
      },

      ratioOfTheBasicSalary: {
        accordionTitle: "Ratio of the basic salary and remuneration of women to men",

        ratioOfTheBasicSalary: [
          {
            region: "",
            employeeCategory: "",
            basicSalaryFemale: "",
            basicSalaryMale: "",
            basicSalaryRatio: ""
          }
        ]
      }
    },

    discrimination:{
      nonDiscrimination: {
        accordionTitle: "Incidents of discrimination and corrective actions taken",
        totalNumberOfIncidents: "",
        statusOfTheIncidents: [
          {
            sNo: "",
            discriminatoryIncident: "",
            remediationPlans: "",
            managementProcesses: "",
            incidentsNoLonger: ""
          }
        ]
      },
    },

 
    freedom :{
      freedomOfAssociation: {
        accordionTitle: "Freedom of Association and Collective Bargaining",
        operationsAndSuppliers: [
          { collectiveBargaining: "", typeOfOperation: "", typeofSupplier: "", country: "", region: "" }
        ],
        measuresTakenByTheOrganization: ""
      },
    },
   

    humanRightsAssessment: {
      operations: {
        accordionTitle: "Operations that have been subject to human rights reviews or impact assessment",
        impactAssessment: [
          {
            title: "Operations subject to human rights policies or human right impact assessment",
            number: "",
            percentage: ""
          }
        ]
      },

      procedure: {
        accordionTitle: "Employee Training on human rights policies or procedure",

        employeeTraining: [
          {
            humanRightsAspects: "",
            totalNumberOfHours: ""
          }
        ],

        employeestrainedHumanRights: [
          {
            title: "Employees trained on human rights",
            number: "",
            percentage: ""
          }
        ]
      },

      rightsScreening: {
        accordionTitle:
          "Significant investment agreements and contracts that include human rights clauses or that underwent human rights screening",
        significantInvestment: [
          {
            agreementsAndContracts: "",
            totalNumberOfAgreements: "",
            percentage: ""
          }
        ],
        specifyTheDefinition: ""
      }
    }
  }
};

export const socialSlice = createSlice({
  name: "social",
  initialState: initialSocialState,
  reducers: {
    setPageWiseData: (state, action) => {
      return {
        ...state,
        pageWiseData: action.payload
      };
    }

    //   setPageWiseErrors: (state, action) => {
    //     return {
    //       ...state,
    //       pageWiseErrors: action.payload
    //     };
    //   }
  }
});

export const { setPageWiseData } = socialSlice.actions;

export default socialSlice.reducer;
