import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "../../../../../components/Accordion/Accordion";
import "../../../../Reports/BRSR/Brsr.scss";
import "../../../Social/Social.scss";
import { setFormWiseData } from "../../../../../state/govtSlice";
import "../../Governance.scss";

const RemunerationPolicies = ({formEditable, editMode}) => {
  const dispatch = useDispatch();
  const { formPageWiseData } = useSelector((state) => state.governance);

  let govtAllData = JSON.parse(JSON.stringify(formPageWiseData));

  const handleDetailsAboutRemunerationChange = (e, index) => {
    govtAllData.remunerationPolicies.remunerationPolicy.detailsAboutRemuneration[index][e.target.name] = e.target.value;
    dispatch(setFormWiseData(govtAllData));
  };

  const addNewRowDetailsAboutRemuneration =()=>{
     let tableRowObj={
      fixedVariablePay: "",
      incentivePayments: "",
      terminationPayments: "",
      clawbacks: "",
      retirementBenefits: ""
    }
    govtAllData.remunerationPolicies.remunerationPolicy.detailsAboutRemuneration.push(tableRowObj)
    dispatch(setFormWiseData(govtAllData));

  }

  const handleDiscriptionOfRemunerationChange = (e, index) => {
    govtAllData.discriptionOfRemuneration = e.target.value;
    dispatch(setFormWiseData(govtAllData));
  };


  const handleDetermineRemunerationProcessChange = (e, index) => {
    govtAllData.remunerationPolicies.determinerRemuneration.determineRemunerationProcess[index][e.target.name] = e.target.value;
    dispatch(setFormWiseData(govtAllData));
  };
  
  const handleAnnualCompensationRatioChange = (e, index) => {
    govtAllData.remunerationPolicies.compensationRatio.annualCompensationRatio[index][e.target.name] = e.target.value;
    dispatch(setFormWiseData(govtAllData));
  };

  // const deleteRow = (rowIndex, name) => {
  //   if (rowIndex > 0) {
  //     let tempArray = [...govtAllData.remunerationPolicies[name]];
  //     tempArray.splice(rowIndex, 1);
  //     govtAllData.remunerationPolicies[name] = tempArray;
  //     dispatch(setFormWiseData(govtAllData));
  //   }
  // };

  const deleteRow = (parentKey, arrayKey, rowIndex) => {
    const parentObject = govtAllData.remunerationPolicies?.[parentKey];
    const dataArray = parentObject?.[arrayKey];
    if (Array.isArray(dataArray)) {
      if (rowIndex >= 0 && rowIndex < dataArray.length) {
        const updatedArray = dataArray.filter((_, index) => index !== rowIndex);

        const updatedParentObject = {
          ...parentObject,
          [arrayKey]: updatedArray
        };

        const updatedData = {
          ...govtAllData,
          remunerationPolicies: {
            ...govtAllData.remunerationPolicies,
            [parentKey]: updatedParentObject
          }
        };

        dispatch(setFormWiseData(updatedData));
      }
    }
  };

  return (
    <div className="esg-table-content">
      <div className="form-bullet-points-div ">
        <Accordion title={"Details about the remuneration policy of the Organisation"} index={0}>
          <div className="table-with-bullet-point">
            <p className="form-bullet-points">
              Describe the remuneration policies for members of the highest governance body and senior executives for
            </p>
            <table className="form-table">
              <thead>
                <tr>
                  <th scope="col">Fixed pay and variable pay</th>
                  <th scope="col">Sign-on bonuses or recruitment incentive payments</th>
                  <th scope="col">Termination payments</th>
                  <th scope="col">Clawbacks</th>
                  <th scope="col">Retirement benefits</th>
                  <th className="col-md-1" scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {govtAllData?.remunerationPolicies?.remunerationPolicy?.detailsAboutRemuneration.map((item, index) => (
                  <tr key={item + index}>
                    <td>
                      <input
                        type="text"
                        name="fixedVariablePay"
                        value={item.fixedVariablePay}
                        onChange={(e) => handleDetailsAboutRemunerationChange(e, index)}
                       className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="incentivePayments"
                        value={item.incentivePayments}
                        onChange={(e) => handleDetailsAboutRemunerationChange(e, index)}
                       className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="terminationPayments"
                        value={item.terminationPayments}
                        onChange={(e) => handleDetailsAboutRemunerationChange(e, index)}
                       className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="clawbacks"
                        value={item.clawbacks}
                        onChange={(e) => handleDetailsAboutRemunerationChange(e, index)}
                       className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="retirementBenefits"
                        value={item.retirementBenefits}
                        onChange={(e) => handleDetailsAboutRemunerationChange(e, index)}
                       className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td className="small-width-columns">
                      {index !== 0 && (
                        <img
                          src="/images/icons/delete-icon.svg"
                          alt=""
                          onClick={() => deleteRow("remunerationPolicy", "detailsAboutRemuneration" ,index)}
                          className="cursor-pointer"
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button className="btn btn-outline-success add-new-row mb-4" onClick={() => addNewRowDetailsAboutRemuneration()}>
            <p>+Add Row</p>
          </button>

          <div className="foreword-container mb-4">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                Describe how the remuneration policies for members of the highest governance body and senior executives
                relate to their objectives and performance in relation to the management of the organization’s impacts
                on the economy, environment, and people.
              </p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={govtAllData.discriptionOfRemuneration}
                  name="discriptionOfRemuneration"
                  onChange={(e) => handleDiscriptionOfRemunerationChange(e)}
                  className="no-style-input w-100"
                  disabled ={editMode && !formEditable}
                />
              </div>
            </div>
          </div>
        </Accordion>
      </div>

      <div className="form-bullet-points-div ">
        <Accordion title={"Process to determine remuneration"} index={1}>
          {govtAllData?.remunerationPolicies?.determinerRemuneration?.determineRemunerationProcess?.map((item, index) => (
            <div className="foreword-container mb-4 mt-1" key={item + index}>
              <div className="form-textfield-container px-4 pb-4">
                <p className="form-bullet-points">{item.heading}</p>
                {item.firstSubHeading && item.secondSubHeading && item.thirdSubHeading && item.fourthSubHeading ? (
                  <ol className="d-flex flex-column gap-3">
                    <li>{item.firstSubHeading}</li>
                    <li>{item.secondSubHeading}</li>
                    <li>{item.thirdSubHeading}</li>
                    <li>{item.fourthSubHeading}</li>
                  </ol>
                ) : (
                  ""
                )}

                <div className="custom-textfield ">
                  <textarea
                    rows="1"
                    placeholder="Write your foreword here."
                    value={item.forewordValue}
                    name="forewordValue"
                    onChange={(e) => handleDetermineRemunerationProcessChange(e, index)}
                    className="no-style-input w-100"
                     disabled ={editMode && !formEditable}

                  />
                </div>
              </div>
            </div>
          ))}
        </Accordion>
      </div>

      <div className="form-bullet-points-div ">
        <Accordion title={"Annual total compensation ratio"} index={2}>
          {govtAllData?.remunerationPolicies?.compensationRatio?.annualCompensationRatio?.map((item, index) => (
            <div className="foreword-container mb-4 mt-1" key={item + index}>
              <div className="form-textfield-container px-4 pb-4">
                <p className="form-bullet-points">{item.heading}</p>
                <div className="custom-textfield ">
                  <textarea
                    rows="1"
                    placeholder="Write your foreword here."
                    value={item.forewordValue}
                    name="forewordValue"
                    onChange={(e) => handleAnnualCompensationRatioChange(e, index)}
                    className="no-style-input w-100"
                     disabled ={editMode && !formEditable}
                  />
                </div>
              </div>
            </div>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default RemunerationPolicies;
