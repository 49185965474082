import React, { useRef, useState } from "react";
import "./EnvBreakDown.scss";
import { Breadcrumbs, Link, Pagination, Stack, Typography } from "@mui/material";
import { Button, Progress, Tooltip } from "antd";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { capitalizeFirstLetter } from "../../../../../helper/Utils/utilitiyFunctions";
import CustomModal from "../../../../../components/Modal/Modal";
import Lottie from "react-lottie";
import * as animationData from "../../../../../helper/tick-animation.json";
import { useLazyGetEnvBreakdownDataQuery } from "../../../../../state/api";
import Item from "antd/es/list/Item";
import WidgetLoader from "../../../../../components/Loaders/WidgetLoader";

const allMonth = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const EnvBreakDown = ({ data }) => {
  const [openModal, setopenModal] = useState(false);
  const postPerPage = 10;
  const [currentPage, SetCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [openCongratsModal, setopenCongratsModal] = useState(false);
  const [getBreakDownData, resultBreakDown] = useLazyGetEnvBreakdownDataQuery();

  const timerRef = useRef(null);

  useState(() => {
    if (data) {
      getBreakDownData({
        facility: data.facility._id,
        scope: data.scope.name,
        category: data.category.name,
        subCategory: data.subCategory._id,
        year: data.year,
        from: `${parseInt(currentPage) - 1 < 0 ? 0 : parseInt(currentPage) - 1}`,
        size: postPerPage
      });
    }
  }, [data]);

  
  const openModalHandle = () => {
    setopenModal(true);
  };

  const breadcrumbs = [
    <Link className="fs-6" underline="none" key="1" color="#2E2E3A" sx={{ textDecoration: "none" }}>
      {data.facility.name}
    </Link>,
    <Link className="fs-6" underline="none" key="1" color="#2E2E3A" sx={{ textDecoration: "none" }}>
      {capitalizeFirstLetter(data.scope)}
    </Link>,
    <Link className="fs-6" underline="none" key="1" color="#2E2E3A" sx={{ textDecoration: "none" }}>
      {data.category.name}
    </Link>,
    <Typography className="fs-6" key="3" color="#2E2E3A">
      {data.subCategory.name}
    </Typography>
  ];

  const handleSave = () => {
    setopenModal(false);
    closeModalWithTimeout();
  };

  const closeModalWithTimeout = () => {
    setopenCongratsModal(true);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    } else {
      timerRef.current = setTimeout(() => {
        setopenCongratsModal(false);
      }, 5000);
    }
  };

  const handleCongratsModal = () => {
    setopenCongratsModal(false);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
      setopenCongratsModal(false);
    }
  };

  const getPayLoad = (pageNo) => {
    let body = {
      facility: data.facility._id,
      scope: data.scope.name,
      category: data.category.name,
      subCategory: data.subCategory._id,
      year: data.year,
      from: `${parseInt(pageNo) - 1 < 0 ? 0 : parseInt(pageNo) - 1}`,
      size: postPerPage
    };

    return body;
  };

  const handlePageChange = async (e, value) => {
    if (value - 1 < 0) {
      SetCurrentPage(0);
    } else {
      SetCurrentPage((value - 1) * postPerPage);
    }
    try {
      if (value - 1 < 0) {
        await getBreakDownData(getPayLoad(0), true);
      } else {
        await getBreakDownData(getPayLoad((value - 1) * postPerPage), true);
      }
    } catch (error) {
      console.log(error);
    }
  };



  const calculatePercentage = (monthArray) => {
    const totalMonths = allMonth.length;
    const presentMonths = monthArray.length;
    const percentage = (presentMonths / totalMonths) * 100;
    return Math.round(percentage);
  };

  const getStrokeColor = (percentage) => {
    if (percentage === 100) {
      return "#4CAF50";
    } else if (percentage > 0 && percentage < 100) {
      return "#FBC02D";
    } else {
      return "#F44336";
    }
  };


  const getSLiceWithElips = (value, to, size) => {
    if (value !== "") {
      if (value.length > size) {
        return value.slice(0, to) + "...";
      } else {
        return value;
      }
    }
  };
  return (
    <>
      <CustomModal modalOpen={openModal} setModalOpen={setopenModal} outSideClose={false}>
        <div className="d-flex gap-4 flex-column">
          <div className="d-flex justify-content-between align-items-center">
            <p className="text-dark fs-6 fw-medium lh-1 d-inline">Edit Remarks</p>
            <img
              src="/images/icons/modal-close-icon.svg"
              alt="icon"
              className="cursor-pointer"
              sizes="40px"
              onClick={() => setopenModal(false)}
            />
          </div>
          <textarea name="remark" placeholder="Placeholder" rows={8} className="remark-textArea"></textarea>
          <div className="d-flex justify-content-between  gap-4">
            <button type="button" className="btn btn-outline-success ms-1" onClick={() => setopenModal(false)}>
              Cancel
            </button>
            <button type="button" className="btn btn-success" onClick={handleSave}>
              {"Save"}
            </button>
          </div>
        </div>
      </CustomModal>

      <CustomModal modalOpen={openCongratsModal} setModalOpen={setopenCongratsModal}>
        <div
          style={{
            width: "100%"
          }}
        >
          <div className="d-flex justify-content-end">
            <img
              src="/images/icons/cross-icon.svg"
              alt=""
              className="cursor-pointer"
              onClick={handleCongratsModal}
              style={{ width: "48px", height: "48px" }}
            />
          </div>
          <div className="px-5 py-3">
            <Lottie options={{ loop: false, autoplay: true, animationData }} height={222} width={265} />
            <div className="d-flex flex-column gap-1 justify-content-center align-items-center mt-4">
              <p className="text-dark fs-4 fw-medium">Your remark is successfully saved</p>
            </div>
          </div>
        </div>
      </CustomModal>

      <div className="heading-cont">
        <p>Emission Source Breakdown</p>
      </div>
      {resultBreakDown.isLoading ? (
        <div className="empty-wrapper mt-2">
          <WidgetLoader />
        </div>
      ) : resultBreakDown?.data?.data?.data?.length === 0 || resultBreakDown?.data === undefined? (
        <div className="empty-wrapper mt-2">
          <div className="empty-container">
            <img src="/empty-box.svg" alt="EmptyImage" style={{ width: "236px" }} />
            <p>No existing data entries to show. Please add data.</p>
            {/* <button type="button" style={{ width: "176px" }} onClick={handleback} className="btn btn-success">
              + Add new data
            </button> */}
          </div>
        </div>
      ) : (
        resultBreakDown?.data?.data?.data?.length > 0 && (
          <div className="env-breakdown-wrapper">
            <div className="bread-crumbs-wrapper">
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
              </Breadcrumbs>
            </div>
            <div className="env-beakdown-table">
              <div style={{ width: "70%" }} className="first-table">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Activity 1</th>
                      <th>Activity 2</th>
                      <th>Activity 3</th>
                      <th>Activity 4</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resultBreakDown?.data?.data.data?.map((item, index) => (
                      <tr key={item + "breakdown"}>
                        <td>
                          <Tooltip title={item.activity1.length>10 ? item.activity1 :""} arrow placement="top">
                            {item.activity1 ? getSLiceWithElips(item.activity1, "12", "10") : "NA"}
                          </Tooltip>
                        </td>
                        <td>
                          <Tooltip title={item.activity2.length>10 ? item.activity2 :""} arrow placement="top">
                            {item.activity2 ? getSLiceWithElips(item.activity2, "12", "10") : "NA"}
                          </Tooltip>
                        </td>
                        <td>
                          <Tooltip title={item.activity3.length>10 ? item.activity3 :""} arrow placement="top">
                            {item.activity3 ? getSLiceWithElips(item.activity3, "12", "10") : "NA"}
                          </Tooltip>
                        </td>
                        <td>
                          <Tooltip title={item.activity4.length>10 ? item.activity4 :""} arrow placement="top">
                            {item.activity4 ? getSLiceWithElips(item.activity4, "12", "10") : "NA"}
                          </Tooltip>
                        </td>
            
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div style={{ width: "25%" }} className="scrollable">
                <table className="table">
                  <thead>
                    <tr>
                      {allMonth.map((month) => (
                        <th key={month}>{month}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {resultBreakDown?.data?.data?.data?.map((item, ind) => (
                      <tr key={item._id + ind}>
                        {allMonth.map((month) => (
                          <td key={month}>
                            {item.month.includes(month) ? (
                              <img src="/images/icons/green-tick-circle.svg" alt="Checkmark" />
                            ) : (
                              <img src="/images/icons/red-cross-box.svg" alt="Cross" />
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="thirdtable">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-nowrap">Data Completeness</th>
                      {/* <th className="text-nowrap">Mark as Complete</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {resultBreakDown?.data?.data?.data?.map((item, ind) => (
                      <tr key={item + "progress"}>
                        <td>
                          <Progress
                            type="circle"
                            percent={calculatePercentage(item.month) === 0 ? "100" : calculatePercentage(item.month)}
                            size={50}
                            strokeColor={getStrokeColor(calculatePercentage(item.month))}
                            format={() =>
                              calculatePercentage(item.month) === 100
                                ? `${calculatePercentage(item.month)}%`
                                : `${calculatePercentage(item.month)}%`
                            }
                            status={calculatePercentage(item.month) === 0 ? "exception" : ""}
                          />
                        </td>
                        {/* <td>
                           Completed
                          <Button type="primary" size="default" onClick={openModalHandle}>
                            Mark Complete
                          </Button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )
      )}

      <div className="mt-auto py-4">
        <Stack spacing={2}>
          <Pagination count={Math.ceil(resultBreakDown?.data?.data?.total / postPerPage)} shape="rounded" onChange={handlePageChange} />
        </Stack>
      </div>
    </>
  );
};
