// SnackbarContext.js
import React, { createContext, useContext, useState } from 'react';
import CustomSnackbar from './Snackbar'; // Import your Snackbar component

const SnackbarContext = createContext();

export function SnackbarProvider({ children }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [snackbarAutoHideDuration, setSnackbarAutoHideDuration] = useState(3000); // Default duration in milliseconds

  const openSnackbar = (message, severity = 'info', autoHideDuration = 3000) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarAutoHideDuration(autoHideDuration);
    setSnackbarOpen(true);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ openSnackbar }}>
      {children}
      <CustomSnackbar
        open={snackbarOpen}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
        autoHideDuration={snackbarAutoHideDuration}
      />
    </SnackbarContext.Provider>
  );
}

export function useSnackbar() {
  return useContext(SnackbarContext);
}
