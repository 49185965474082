import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Link, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import CustomModal from "../../../../components/Modal/Modal";
import { useSnackbar } from "../../../../components/snackbar/SnackbarContext";
import {
  useCreateActionMeasureMutation,
  useDeleteActionMeasureMutation,
  useLazyGetActionMeasureQuery,
  useLazyGetActionPlanQuery,
  useLazyGetAllActionActionQuery,
  useUpdateActionMeasureMutation,
  useGetOrganizationDataQuery,
  usePostAuditLogDataMutation,
  useLazyGetUserDetailByIdQuery
} from "../../../../state/api";
import "../../scss/commonScss.scss";
import "../Action.scss";
import { useSelector } from "react-redux";

const tableReductionHeaders = ["REDUCTION MEASURE", "NO. OF ACTIONS", "ASSIGNEE", "COMPLETION RATE"];

const NewActionPlan = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { facility, actionPId, actionName } = location.state;

  const [getActionPlan, resultActionPlan] = useLazyGetActionPlanQuery();
  const [getActionMeasure, resultActionMeasure] = useLazyGetActionMeasureQuery();
  const [createActionMeasure] = useCreateActionMeasureMutation();
  const [updateActionMeasure] = useUpdateActionMeasureMutation();
  const [deleteMeasure] = useDeleteActionMeasureMutation();
  const [getAllActions, resultAllActions] = useLazyGetAllActionActionQuery();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenEdit, setModalOpenEdit] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const [actionPlan, setActionPlan] = useState(null);
  const [measureName, setMeasureName] = useState("");
  const [measureId, setMeasureId] = useState("");
  const [prevMeasureName, setPrevMeasureName] = useState("");
  const { openSnackbar } = useSnackbar();
  const userId = useSelector((state) => state.global.user);

  const orgData = useGetOrganizationDataQuery();
  const [postAuditLogData] = usePostAuditLogDataMutation({});

  const postPerPage = 12;
  const [actionMeasureList, setActionMeasureList] = useState(0);
  const [pg, setPg] = useState(0);

  const [user, setUser] = useState(null)
  const [getUser, userResult] = useLazyGetUserDetailByIdQuery()
    useEffect(() => {
    if (userResult.data?.data) {
      setUser(userResult.data?.data)
    }
  }, [userResult.data?.data])

  useEffect(() => {
    getUser({ id: userId._id }, true)
  }, [])


  useEffect(() => {
    getAllActions({ actionPlan: actionPId });
  }, []);

  const openModal = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    getActionPlan({ facility, skip: 0, limit: 15 }, true);
    if (resultActionPlan.data?.data) {
      let actionP = resultActionPlan.data?.data?.actionPlanList.find((item) => item._id === actionPId);
      if (actionP) {
        setActionPlan(actionP);
      }
    }
  }, [resultActionPlan.data]);

  useEffect(() => {
    getActionMeasure({ actionPlan: actionPId, skip: pg * postPerPage, limit: postPerPage }, true);
  }, [pg]);

  useEffect(() => {
    if (resultActionMeasure.data?.data) {
      setActionMeasureList(resultActionMeasure.data?.data);
    }
  }, [resultActionMeasure.data?.data]);

  const handlePageChange = async (e, value) => {
    setPg(value);
  };

  const handleMeasureChange = (e) => {
    setMeasureName(e.target.value);
  };

  const openEditModal = (item) => {
    setMeasureId(item._id);
    setMeasureName(item.name);
    setPrevMeasureName(item.name)
    setModalOpenEdit(true);
  };

  const createMeasure = async () => {
    try {
      let body = { name: measureName, actionPlan: actionPId };
      if (body.id) {
        delete body.id;
      }
      const response = await createActionMeasure(body);
      if (response.error) {
        openSnackbar(response.error.data?.message, "error");
      } else {
        openSnackbar(response.data?.message, "success");

        postAuditLogData({
          action: "CREATE",
          message: ` ${user.name} created new reduction measure ${measureName}`,
          tab: "Action Plan",
          facility: "",
          organization: orgData.data?.data._id,
          user: userId._id,
          ipAddress: "10.10.0.1"
        });

        setMeasureName("");
        setModalOpen(false);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      openSnackbar(errorMessage, "success");
    }
  };

  const updateMeasure = async () => {
    try {
      let body = { id: measureId, data: { name: measureName } };
      const response = await updateActionMeasure(body);
      if (response.error) {
        openSnackbar(response.error.data?.message, "error");
      } else {
        openSnackbar(response.data?.message, "success");
        postAuditLogData({
          action: "UPDATE",
          message: ` ${"name"} of ${measureName} change from ${measureName} to ${prevMeasureName}`,
          tab: "Action Plan",
          facility: "",
          organization: orgData.data?.data._id,
          user: userId._id,
          ipAddress: "10.10.0.1"
        });
        setMeasureName("");
        setMeasureId("");
        setModalOpenEdit(false);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      openSnackbar(errorMessage, "success");
    }
  };

  const deleteAction = (id) => {
    setMeasureId(id);
    setConfirmModal(true);
  };

  const deleteActionMeasure = async () => {
    try {
      let response = await deleteMeasure(measureId);
      let deleteMeasurName =  actionMeasureList?.actionMeasureList?.find((item) => item._id === measureId)?.name
      if (response.error) {
        openSnackbar(response.error.data?.message, "error");
      } else {
        openSnackbar(response.data?.message, "success");
         
        postAuditLogData({
          action: "DELETE",
          message: `${deleteMeasurName} ${user.name}`,
          tab: "Action Plan",
          facility: "",
          organization: orgData.data?.data._id,
          user: userId._id,
          ipAddress: "10.10.0.1"
        });

        setConfirmModal(false);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      openSnackbar(errorMessage, "error");
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const breadcrumbs = [
    <div className="d-flex gap-2 align-items-center">
      <div style={{ width: "15px" }} className="d-flex">
        <ArrowBackIosIcon fontSize="small" className="w-100" />
      </div>
      <Link className="fs-6 cursor-pointer" underline="hover" key="1" color="inherit" onClick={goBack}>
        {actionName}
      </Link>
    </div>
  ];

  return (
    <>
      <Tooltip id={"modal-info-reduction"} style={{ width: "300px", zIndex: 9991 }} place="right" />

      <CustomModal modalOpen={modalOpenEdit} setModalOpen={setModalOpenEdit} outSideClose={false}>
        <div className="modal-container-wide">
          <div className="d-flex justify-content-between">
            <p className="modal-title-plan">Update reduction measure</p>
            <div
              data-tooltip-id={"modal-info-reduction"}
              data-tooltip-html={`<span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">This reduction measure is getting created outside the simulation<br/></span><span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word"><br/></span><span style="color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Reduction measures created in action plan are not reflected back in the simulations. They are a part of your action plan on top of the simulated measures.</span>`}
            >
              <InfoOutlinedIcon />
            </div>
          </div>
          <div>
            <div className="mt-3">
              <p className="label-text-modal">Name</p>
              <input
                type="text"
                className="form-control"
                placeholder="New measure for action plan"
                name="measureName"
                value={measureName}
                onChange={handleMeasureChange}
              />
            </div>
            <div className="d-flex gap-3 mt-4">
              <button type="button" className="btn btn-success" onClick={updateMeasure}>
                Update Measure
              </button>
              <button type="button" className="btn btn-outline-success" onClick={() => setModalOpenEdit(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal modalOpen={modalOpen} setModalOpen={setModalOpen} outSideClose={false}>
        <div className="modal-container-wide">
          <div className="d-flex justify-content-between">
            <p className="modal-title-plan">Create reduction measure</p>
            <div
              data-tooltip-id={"modal-info-reduction"}
              data-tooltip-html={`<span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Completion year for the simulation<br/></span><span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word"><br/></span><span style="color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">End year may or may not be the same as any of your target years.</span>`}
            >
              <InfoOutlinedIcon />
            </div>
          </div>
          <div>
            <div className="mt-3">
              <p className="label-text-modal">Name</p>
              <input
                type="text"
                className="form-control"
                placeholder="New measure for action plan"
                name="measureName"
                value={measureName}
                onChange={handleMeasureChange}
              />
            </div>
            <div className="d-flex gap-3 mt-4">
              <button type="button" className="btn btn-success" onClick={createMeasure}>
                Create Measure
              </button>
              <button type="button" className="btn btn-outline-success" onClick={() => setModalOpen(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal modalOpen={confirmModal} setModalOpen={setConfirmModal} outSideClose={false}>
        <p className="delete-modal-font">Are you sure you want to delete this action plan ?</p>
        <p className="delete-modal-small-font">
          {actionMeasureList?.actionMeasureList?.find((item) => item._id === measureId)?.name}
        </p>
        <div className="d-flex gap-3 mt-4">
          <button type="button" className="btn btn-outline-danger" onClick={() => setConfirmModal(false)}>
            Cancel
          </button>
          <button type="button" className="btn btn-danger" onClick={() => deleteActionMeasure()}>
            Delete
          </button>
        </div>
      </CustomModal>
      {
        <>
          <div className="d-flex justify-content-between align-items-center my-4">
            <div>
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
              </Breadcrumbs>
              <div className="d-flex gap-4 mt-4">
                <p className="fs-12p">
                  Facility: <span className=" fw-semibold">{actionPlan?.facility?.name}</span>
                </p>
                <p className="fs-12p">
                  Reference Reporting Year: <span className="fw-semibold">{actionPlan?.simulation?.reportingYear}</span>
                </p>
              </div>
            </div>
            <div>
              <div className="d-flex justify-content-between align-items-center gap-2">
                <p className="fs-12p">Total Emissions:</p>
                <p className="fs-12p fw-semibold">{actionPlan?.simulation?.totalEmission} tCO2e</p>
              </div>
              <div className="d-flex justify-content-between align-items-center gap-2">
                <p className="fs-12p mt-2">Projected Emissions:</p>
                <p className="fs-12p mt-2 fw-semibold">{actionPlan?.simulation?.projectedEmissions} tCO2e</p>
              </div>
              <div className="d-flex justify-content-between align-items-center gap-2">
                <p className="fs-12p mt-2">Completion Rate:</p>
                <p className="fs-12p mt-2 fw-semibold">{actionPlan?.progress}%</p>
              </div>
            </div>
          </div>
          <div className="data-container-box">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <p className="box-title-name">Reduction Measures</p>
              <button onClick={() => openModal()} className="btn btn-success create-new-button-wide">
                + New Reduction Measure
              </button>
            </div>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {tableReductionHeaders?.map((key, index) => (
                    <TableCell className="fw-bold text-start">{key}</TableCell>
                  ))}
                  <TableCell className="fw-bold text-start">ACTIONS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {actionMeasureList?.actionMeasureList?.length > 0 ? (
                  actionMeasureList?.actionMeasureList?.map((item, index) => (
                    <TableRow>
                      <TableCell className="text-start">
                        <p
                          className="clickable-text"
                          onClick={() =>
                            navigate("/action/newactionplan/newactionmeasure", {
                              state: { measureId: item._id, measureName: item.name }
                            })
                          }
                        >
                          {item.name}
                        </p>
                      </TableCell>
                      <TableCell className="text-start">
                        {resultAllActions?.data?.data?.result?.length > 0
                          ? resultAllActions?.data?.data?.result?.filter((action) => action.actionMeasure === item._id)
                              ?.length
                          : 0}
                      </TableCell>
                      <TableCell className="text-start">{item.assignee ? item.assignee : "Not assigned"}</TableCell>
                      <TableCell className="text-start">
                        <div className="d-flex gap-2 align-items-center justify-content-start">
                          <div className="progress-container-completion">
                            <div className="progress-bar" style={{ width: `${item.progress}%` }}></div>
                          </div>
                          <p className="completion-text">{item.progress} %</p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="d-flex gap-2">
                          <div className="cursor-pointer">
                            <img src={"/images/icons/edit.svg"} alt="edit" onClick={() => openEditModal(item)} />
                          </div>
                          <div className="cursor-pointer">
                            <img
                              src={"/images/icons/delete-icon.svg"}
                              alt="delete"
                              onClick={() => deleteAction(item._id)}
                            />
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <div className="d-flex justify-content-center align-items-center h-300p">No measure added</div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <div className="mt-auto">
              <TablePagination
                rowsPerPageOptions={false}
                component="div"
                count={actionMeasureList?.totalCount}
                rowsPerPage={postPerPage}
                page={pg}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      }
    </>
  );
};

export default NewActionPlan;
