import React, { useEffect, useState } from "react";
import UploadCard from "../../../components/UploadCard/UploadCard";
import { useLazyGetTemplateListQuery } from "../../../state/api";

import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";

import "../../Measure/Measure.scss";
import "./SelectCards.scss";

const labelStyle = {
  top: "-6"
};

const SelectCards = ({ addSelectedCards, cancelSelect, activeTabId }) => {
  const [getTemplate, resultTemplate] = useLazyGetTemplateListQuery();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCards, setSelectedCards] = useState([]);

  const handleCardSelect = (cardId, cardImage, cardTitle, isSelected) => {
    if (isSelected) {
      setSelectedCards([...selectedCards, { id: cardId, image: cardImage, title: cardTitle }]);
    } else {
      setSelectedCards(selectedCards.filter((item) => item.id !== cardId));
    }
  };

  const handleAddSelected = () => {
    addSelectedCards(selectedCards);
  };

  useEffect(() => {
    if (searchTerm) {
      getTemplate({ search: searchTerm });
    } else {
      getTemplate({});
    }
  }, [resultTemplate.data]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === "") {
      onSearch(searchTerm);
    }
  };

  const onSearch = (search) => {
    getTemplate({ search });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSearch(searchTerm);
    }
  };

  return (
    <>
      <div className="measure-wrapper">
        <div className="measureTableHeader gap-2">
          <div style={{ fontWeight: "bold", fontSize: "20px" }}>Select emission source</div>
          <div className="d-flex flex-wrap gap-2">
            <div className="me-10">
              <TextField
                id="search"
                type="search"
                label="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyPress={handleKeyPress}
                sx={{ background: "white", border: "none" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" onClick={() => onSearch(searchTerm)} style={{ cursor: "pointer" }}>
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
                InputLabelProps={{
                  style: labelStyle
                }}
                className="ps-search-bar"
              />
            </div>
            <div></div>
            <div className="buttonContainer">
              <button type="button" className="btn btn-success" onClick={handleAddSelected}>
                + Add Selected
              </button>

              <button
                type="button"
                className="btn btn-outline-success"
                style={{ width: "150px" }}
                onClick={() => cancelSelect()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div className="cardContentContainer">
          {resultTemplate.data?.data.map(
            (item, key) =>
              item.scope.name !== "air emission" && (
                <div className="measureCardContainer" key={item.scope.name}>
                  <div className="scopeDesc">
                    <p className="scopeDescriptionPara">{item.scope.name}</p>
                    <p className="descriptionOfScope">{item.scope.description}</p>
                  </div>
                  <div className="cardsContainer">
                    {item.templetes.map((subItem, subKey) => (
                      <UploadCard
                        key={subItem._id}
                        itemId={subItem._id}
                        data={subItem}
                        canSelect={true}
                        activeTabId={activeTabId}
                        onCardSelect={handleCardSelect}
                      />
                    ))}
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </>
  );
};

export default SelectCards;
