export const emissionEndpoints = (build) => ({
  getTemplateList: build.query({
    query: (arg) => ({
      url: `emission/templete/list`,
      params: { ...arg }
    }),
    providesTags: ["Cards"]
  }),

  getDataEntries: build.query({
    query: (arg) => ({
      url: `emission/data`,
      params: { ...arg }
    }),
    providesTags: ["DataEntries"]
  }),
  getMonthWiseData: build.query({
    query: (year) => `emission/data/month?year=${year}`
  }),

  getScopeWiseData: build.query({
    query: (year) => `emission/data/scope?year=${year}`
  }),

  getFacilityWiseData: build.query({
    query: (year) => `emission/data/month/facility?year=${year}`
  }),
  getCategoryTableData: build.query({
    query: (id) => `emission/templete/detail?id=${id}`,
    providesTags: ["DataTableEntries"]
  }),
  postCalculateEmissionRow: build.mutation({
    query: (postBody) => ({
      url: `emission/calculate`,
      method: "POST",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: ["Facility"]
  }),
  postEmissionTableData: build.mutation({
    query: (postBody) => ({
      url: `emission/data`,
      method: "POST",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: [
      "Dashscope",
      "Dashmonthscope",
      "Dashmonthfacility",
      "Topfacility",
      "Topcategory",
      "Topsubcategory",
      "Scopecategory",
      "AnalysisFilter",
      "scopewiseEm",
      "analysisrenewable",
      "analysisscope",
      "analysistrendyear",
      "analysistrendsource",
      "analysisfacility",
      "analysismonth",
      "analysissubcategory",
      "analysiscategory",
      "analysisyeartrend"
    ]
  }),
  updateEmissionEntry: build.mutation({
    query: (postBody) => ({
      url: "emission/data",
      method: "PUT",
      body: postBody
    }),

    invalidatesTags: [
      "DataEntries",
      "Dashscope",
      "Dashmonthscope",
      "Dashmonthfacility",
      "Topfacility",
      "Topcategory",
      "Topsubcategory",
      "Scopecategory",
      "AnalysisFilter",
      "scopewiseEm",
      "analysisrenewable",
      "analysisscope",
      "analysistrendyear",
      "analysistrendsource",
      "analysisfacility",
      "analysismonth",
      "analysissubcategory",
      "analysiscategory",
      "analysisyeartrend"
    ],

    transformResponse: (response) => {
      return response;
    }
  })
});
