import React, { useRef, useState } from "react";
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import { apiUrl } from "../ApiUrl";
import ButtonLoader from "../Loaders/ButtonLoader";
import { useSnackbar } from "../snackbar/SnackbarContext";
import "./DragDropFile.scss";
import "./excel.preview.scss";


const fileTypes = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel"
];

function changeExcelHeaders(data) {
  const names = data.map(x => x.name);
  names.unshift(" ");
  const keys = data.map(x => x.key).map(x => x + 1);
  keys.unshift(0);
  let newData = [];
  for (let i = 0; i < keys.length; i++) {
    newData.push({
      name: names[i],
      key: keys[i]
    });
  }
  return newData;
}

function fixDecimal(data) {
  const formattedData = data.map(row => {
    const formattedRow = [];
    for (let i = 0; i < row.length; i++) {
      if (typeof row[i] === "number") {
        formattedRow.push(row[i].toFixed(2))
      } else if (!row[i] || row[i] === " " || row[i] === "") {
        formattedRow.push(null)
      } else {
        formattedRow.push(row[i])
      }
    }
    return formattedRow;
  });

  return formattedData
}

const DragDropFile = ({ setModalOpen }) => {
  const { openSnackbar } = useSnackbar()
  const [dragActive, setDragActive] = useState(false);
  const [file, setFile] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const inputRef = useRef(null);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files) {
      setFile(e.dataTransfer.files[0]);
      ExcelRenderer(e.dataTransfer.files[0], (err, resp) => {
        if (err) {
          openSnackbar("Something went wrong while preview", "warning");
        } else {
          setData({
            cols: changeExcelHeaders(resp.cols),
            rows: fixDecimal(resp.rows)
          });
        }
      });
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files) {
      if (fileTypes.includes(e.target.files[0].type)) {
        setFile(e.target.files[0]);
        ExcelRenderer(e.target.files[0], (err, resp) => {
          if (err) {
            openSnackbar("Something went wrong while preview", "warning");
            throw new Error(err)
          } else {
            setData({
              cols: changeExcelHeaders(resp.cols),
              rows: fixDecimal(resp.rows)
            });
          }
        });
      } else {
        openSnackbar("Please select a valid xlx or xlxs file", "warning");
      }
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const deleteFileData = () => {
    if (loading) {
      return
    }
    setData(null)
    setFile(null)
  }

  const handleSubmit = async (e) => {

    if (loading) {
      return
    }

    e.preventDefault();
    let formData = new FormData();
    const url = `${apiUrl}/emission/data/file`;

    formData.append('files', file);
    formData.append('year', JSON.stringify(2020));

    try {
      setLoading(true);
      let response = await fetch(url, {
        method: "POST",
        credentials: "include",
        body: formData,
      });
      response = await response.json();

      if (response.success) {
        openSnackbar(response.message, "success");
        setModalOpen(false);
      } else openSnackbar(response.message, "warning");

      setLoading(false);

    } catch (error) {
      setLoading(false);
      openSnackbar(error.message, 'error')
    }
  };

  return (
    <>
      <div>
        {file ? (
          <div className="form-file-upload">
            <div
              className="formTableContainer"
            >
              {data && (
                <OutTable data={data.rows} columns={data.cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />
              )}
            </div>
            <div className="d-flex justify-content-end">
              <div>
                <button type="button" className="btn btn-success" onClick={handleSubmit} disabled={loading}>
                  {
                    loading ? <div className="loaderContainer"><ButtonLoader /></div> : "Submit"
                  }
                </button>
              </div>
              <div className="ms-10">
                <button type="button" className="btn btn-outline-success" onClick={deleteFileData} disabled={loading}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        ) : (
          <form
            className="form-file-upload"
            onDragEnter={handleDrag}
          >
            <input
              ref={inputRef}
              type="file"
              id="input-file-upload"
              multiple={true}
              onChange={handleChange}
            />
            <label
              id="label-file-upload"
              htmlFor="input-file-upload"
              className={dragActive ? "drag-active" : ""}
            >
              <div>
                <div>
                  <img src="images/icons/uploadIcon.png" alt="upload" />{" "}
                </div>
                <p className="mt-15">
                  Drag and drop your bulk upload sheet here to upload.
                </p>
                <p className="mt-15">Or</p>
                <div className="button-wrapper">
                  <button type="button" className="btn btn-success me-2" onClick={onButtonClick} >
                    Browse
                  </button>
                  <button type="button" className="btn btn-outline-success" onClick={() => setModalOpen(false)} >
                    Cancel
                  </button>
                </div>
                {loading && <p>Uploading...</p>}
              </div>
            </label>
            {dragActive && (
              <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop} ></div>
            )}
          </form>
        )}
      </div>
    </>
  );
};

export default DragDropFile;
