import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "../../../../../components/Accordion/Accordion";
import "../../../../Reports/BRSR/Brsr.scss";
import "../../../Social/Social.scss";
import { setFormWiseData } from "../../../../../state/govtSlice";
import "../../Governance.scss";
import { numberRegex } from "../../../../../helper/Rejex";

const Tax = ({ formEditable, editMode }) => {
  const dispatch = useDispatch();
  const { formPageWiseData } = useSelector((state) => state.governance);

  let govtAllData = JSON.parse(JSON.stringify(formPageWiseData));

  const handleApproachToTaxDescriptionChange = (e) => {
    govtAllData.tax.approach.approachToTaxDescription = e.target.value;
    dispatch(setFormWiseData(govtAllData));
  };

  const handleTaxGovernanceControlAndRiskManagementChange = (e, index) => {
    govtAllData.tax.taxGovernance.taxGovernanceControlAndRiskManagement[index][e.target.name] = e.target.value;
    dispatch(setFormWiseData(govtAllData));
  };

  const handleStakeholderEngagementDiscriptionChange = (e) => {
    govtAllData.tax.stakeholder.stakeholderEngagementDiscription = e.target.value;
    dispatch(setFormWiseData(govtAllData));
  };

  const handleCountryByCountryReportingChange = (e, index) => {
    const { name, value } = e.target;
    if (
      name === "numberOfEmployees" ||
      name === "revenuesFromThirdParty" ||
      name === "revenuesFromIntraGroup" ||
      name === "profitoLssbeforeTax"||
      name ==="corporateIncomeTaxPaidCashBasic" ||
      name === "corporateIncomeTaxAccruedOnProfitLoss" ||
      name === "tangibleAssets"
    ) {
      if (numberRegex.test(value) || value === "") {
        govtAllData.tax.reporting.countryByCountryReporting[index][e.target.name] = e.target.value;
      }
    } else {
      govtAllData.tax.reporting.countryByCountryReporting[index][e.target.name] = e.target.value;
    }

    dispatch(setFormWiseData(govtAllData));
  };

  return (
    <div className="esg-table-content">
      <div className="form-bullet-points-div ">
        <Accordion title={"Approach to tax"} index={0}>
          <div className="foreword-container mb-4 mt-1">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">A description of the approach to tax, including:</p>

              <ol className="d-flex flex-column gap-3">
                <li>
                  whether the organization has a tax strategy and, if so, a link to this strategy if publicly available
                </li>
                <li>
                  the governance body or executive-level position within the organization that formally reviews and
                  approves the tax strategy, and the frequency of this review
                </li>
                <li>the approach to regulatory compliance</li>
                <li>
                  how the approach to tax is linked to the business and sustainable development strategies of the
                  organization
                </li>
              </ol>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={govtAllData?.tax?.approach?.approachToTaxDescription}
                  name="approachToTaxDescription"
                  onChange={(e) => handleApproachToTaxDescriptionChange(e)}
                  className="no-style-input w-100"
                  disabled={editMode && !formEditable}
                />
              </div>
            </div>
          </div>
        </Accordion>
      </div>

      <div className="form-bullet-points-div ">
        <Accordion title={"Tax governance, control, and risk management"} index={1}>
          {govtAllData?.tax?.taxGovernance?.taxGovernanceControlAndRiskManagement?.map((item, index) => (
            <div className="foreword-container mb-4 mt-1" key={item + index}>
              <div className="form-textfield-container px-4 pb-4">
                <p className="form-bullet-points">{item.heading}</p>
                {item.firstSubHeading && item.secondSubHeading && item.thirdSubHeading && item.fourthSubHeading ? (
                  <ol className="d-flex flex-column gap-3">
                    <li>{item.firstSubHeading}</li>
                    <li>{item.secondSubHeading}</li>
                    <li>{item.thirdSubHeading}</li>
                    <li>{item.fourthSubHeading}</li>
                  </ol>
                ) : (
                  ""
                )}

                <div className="custom-textfield ">
                  <textarea
                    rows="1"
                    placeholder="Write your foreword here."
                    value={item.forewordValue}
                    name="forewordValue"
                    onChange={(e) => handleTaxGovernanceControlAndRiskManagementChange(e, index)}
                    className="no-style-input w-100"
                    disabled={editMode && !formEditable}
                  />
                </div>
              </div>
            </div>
          ))}
        </Accordion>
      </div>

      <div className="form-bullet-points-div ">
        <Accordion title={"Stakeholder engagement and management of concerns related to tax"} index={2}>
          <div className="foreword-container mb-4 mt-1">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                A description of the approach to stakeholder engagement and management of stakeholder concerns related
                to tax, including
              </p>

              <ol className="d-flex flex-column gap-3">
                <li>the approach to engagement with tax authorities</li>
                <li>the approach to public policy advocacy on tax</li>
                <li>
                  the processes for collecting and considering the views and concerns of stakeholders, including
                  external stakeholders.
                </li>
              </ol>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={govtAllData?.tax?.stakeholder?.stakeholderEngagementDiscription}
                  name="stakeholderEngagementDiscription"
                  onChange={(e) => handleStakeholderEngagementDiscriptionChange(e)}
                  className="no-style-input w-100"
                  disabled={editMode && !formEditable}
                />
              </div>
            </div>
          </div>
        </Accordion>
      </div>

      <div className="form-bullet-points-div ">
        <Accordion title={"Country by country reporting"} index={3}>
          <div className="table-with-bullet-point">
            <p className="form-bullet-points">
              All tax jurisdictions where the entities included in the organization’s audited consolidated financial
              statements, or in the financial information filed on public record, are resident for tax purposes
            </p>
            <div className="table-scrollable">
              <table className="form-table form-table mb-4 w-auto">
                <thead>
                  <tr>
                    <th className="col-md-1" scope="col">
                      SI.No.
                    </th>
                    <th style={{ minWidth: "150px" }} scope="col">
                      Name of the Resident entities
                    </th>
                    <th style={{ minWidth: "250px" }} scope="col">
                      Primary activities of the organization
                    </th>
                    <th style={{ minWidth: "250px" }} scope="col">
                      Number of employees
                    </th>
                    <th style={{ minWidth: "250px" }} scope="col">
                      Basis of calculation of number of employees
                    </th>
                    <th style={{ minWidth: "250px" }} scope="col">
                      Revenues from third-party sales
                    </th>
                    <th style={{ minWidth: "250px" }} scope="col">
                      Revenues from intra-group transactions with other tax jurisdictions
                    </th>
                    <th style={{ minWidth: "250px" }} scope="col">
                      Profit/loss before tax
                    </th>
                    <th style={{ minWidth: "250px" }} scope="col">
                      Tangible assets other than cash and cash equivalents
                    </th>
                    <th style={{ minWidth: "250px" }} scope="col">
                      Corporate income tax paid on a cash basis
                    </th>
                    <th style={{ minWidth: "250px" }} scope="col">
                      Corporate income tax accrued on profit/loss
                    </th>
                    <th style={{ minWidth: "450px" }}>
                      Reasons for the difference between corporate income tax accrued on profit/loss and the tax due if
                      the statutory tax rate is applied to profit/loss before tax
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {govtAllData?.tax?.reporting?.countryByCountryReporting.map((item, index) => (
                    <tr key={item + index}>
                      <td>
                        <input
                          type="text"
                          name="SINo"
                          value={item.SINo}
                          onChange={(e) => handleCountryByCountryReportingChange(e, index)}
                          className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                          readOnly={editMode && !formEditable}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="residentEntitiesName"
                          value={item.residentEntitiesName}
                          onChange={(e) => handleCountryByCountryReportingChange(e, index)}
                          className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                          readOnly={editMode && !formEditable}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="primaryActivities"
                          value={item.primaryActivities}
                          onChange={(e) => handleCountryByCountryReportingChange(e, index)}
                          className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                          readOnly={editMode && !formEditable}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="numberOfEmployees"
                          value={item.numberOfEmployees}
                          onChange={(e) => handleCountryByCountryReportingChange(e, index)}
                          className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                          readOnly={editMode && !formEditable}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="basisCalculation"
                          value={item.basisCalculation}
                          onChange={(e) => handleCountryByCountryReportingChange(e, index)}
                          className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                          readOnly={editMode && !formEditable}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="revenuesFromThirdParty"
                          value={item.revenuesFromThirdParty}
                          onChange={(e) => handleCountryByCountryReportingChange(e, index)}
                          className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                          readOnly={editMode && !formEditable}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="revenuesFromIntraGroup"
                          value={item.revenuesFromIntraGroup}
                          onChange={(e) => handleCountryByCountryReportingChange(e, index)}
                          className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                          readOnly={editMode && !formEditable}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="profitoLssbeforeTax"
                          value={item.profitoLssbeforeTax}
                          onChange={(e) => handleCountryByCountryReportingChange(e, index)}
                          className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                          readOnly={editMode && !formEditable}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="tangibleAssets"
                          value={item.tangibleAssets}
                          onChange={(e) => handleCountryByCountryReportingChange(e, index)}
                          className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                          readOnly={editMode && !formEditable}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="corporateIncomeTaxPaidCashBasic"
                          value={item.corporateIncomeTaxPaidCashBasic}
                          onChange={(e) => handleCountryByCountryReportingChange(e, index)}
                          className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                          readOnly={editMode && !formEditable}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="corporateIncomeTaxAccruedOnProfitLoss"
                          value={item.corporateIncomeTaxAccruedOnProfitLoss}
                          onChange={(e) => handleCountryByCountryReportingChange(e, index)}
                          className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                          readOnly={editMode && !formEditable}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="diffrence"
                          value={item.diffrence}
                          onChange={(e) => handleCountryByCountryReportingChange(e, index)}
                          className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                          readOnly={editMode && !formEditable}
                        ></input>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Accordion>
      </div>
    </div>
  );
};

export default Tax;
