export const ProductEndPoint = (build) => ({
  getFacilitySearch: build.query({
    query: (arg) => ({
      url: `facility/search`,
      params: { ...arg }
    }),
    providesTags: ["ProductsList"]
  }),
  getCBAMCodeSearch: build.query({
    query: (arg) => ({
      url: `cbam/products/search`,
      params: { ...arg }
    }),
    invalidatesTags: ["ProductsList"],
    providesTags: (result) =>
      result ? [{ type: "FacilitySearch", id: result.query }] : ["FacilitySearch", "ProductsList"]
  }),
  getCBAMCodeList: build.query({
    query: () => ({
      url: `cbam/products/search`,
      params: { keyword: "", pageLength: 9999, pageNo: 0 }
    }),
    providesTags: ["ProductsList"]
  }),
  deleteProductCBAM: build.mutation({
    query: (id) => ({
      url: `cbam/products/delete/${id}`,
      method: "DELETE"
    }),
    invalidatesTags: ["ProductsList"]
  }),
  getInternalCodeDropdownList: build.query({
    query: () => ({
      url: `cbam/products/list/internalCode/`
    }),
    providesTags: ["ProductsList"]
  }),

  getCountryList: build.query({
    query: () => ({
      url: `/common/country/list/`,
      method: "GET"
    }),
    providesTags: ["ProductsList"]
  }),

  createCBAMProduct: build.mutation({
    query: (body) => ({
      url: "cbam/products/",
      method: "POST",
      body: body
    }),
    invalidatesTags: ["ProductsList"],
    providesTags: (result) => (result ? [{ type: "ProductsList", id: result.id }] : [])
  }),

  getProductList: build.query({
    query: (arg) => ({
      url: `cbam/products/`,
      params: { ...arg }
    }),
    providesTags: (result) => (result ? [{ type: "ProductsList", id: result.query }] : ["ProductsList"])
  }),

  updateProductDetails: build.mutation({
    query: ({ id, body }) => ({
      url: `cbam/products/update/${id}`,
      method: "PATCH",
      body: body
    }),
    invalidatesTags: ["ProductsList"]
  }),
  getProductDetails: build.query({
    query: (id) => ({
      url: `cbam/products/details/${id}`
    }),
    providesTags: ["ProductsList"]
  }),
  getProductDropdownList: build.query({
    query: () => ({
      url: `cbam/products/list/productName`
    }),
    providesTags: ["ProductsList"]
  })
});
