import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Autocomplete, Box, Grid, TextField, Tooltip, Typography } from "@mui/material";
import { Select } from "antd";
import { useEffect, useState } from "react";
import CustomModal from "../../../../../src/components/Modal/Modal";
import { useSnackbar } from "../../../../components/snackbar/SnackbarContext";
import {
  useCreateImporterMutation,
  useGetCountryListQuery,
  useGetImporterDetailsQuery,
  useGetProductListQuery,
  useUpdateImporterMutation
} from "../../../../state/api";

export const AddImporterModal = ({
  modalOpen,
  setModalOpen,
  productId,
  setProductId,
  isDuplicate,
  setIsDuplicate,
  addImporterModal,
  editFlow
}) => {
  const ImporterInitState = { name: "", code: "", products: [], country: "", city: "", address: "", status: "Draft" };
  const [ImporterDetails, setImporterDetails] = useState(ImporterInitState);
  const { openSnackbar } = useSnackbar();
  const [errorHappend, setErrorHappend] = useState(false);
  const [createImporter] = useCreateImporterMutation();
  const { data: ImporterUniqueData, refetch: refetchUniqueData } = useGetImporterDetailsQuery(productId, {
    skip: !productId
  });
  const [updateImporter] = useUpdateImporterMutation();
  const countryData = useGetCountryListQuery();
  const [productListForAdding, setproductListForAdding] = useState();
  const getProductList = useGetProductListQuery({ pageLength: 9999, pageNo: 0, search: "" });
  const ProductListForDropdown =
    getProductList?.data?.data?.products.filter((item) => item.status === "Active").map((item) => item.productName) ||
    [];
  const CountryArray = countryData?.data?.data?.map((item) => item.name) || [];

  const resetComponentState = () => {
    setImporterDetails(ImporterInitState);
    setproductListForAdding();
    setProductId(null);
    setIsDuplicate(false);
  };

  useEffect(() => {
    setErrorHappend(false);
    setImporterError({
      addressError: false,
      cityError: false,
      CodeError: false,
      countryError: false,
      nameError: false,
      productsError: false,
      statusError: false
    });
  }, [addImporterModal]);

  useEffect(() => {
    if (ImporterDetails?.name?.length > 1) {
      setImporterError((prevErrors) => ({
        ...prevErrors,
        nameError: false
      }));
      setErrorHappend(false);
    }
    if (ImporterDetails?.code?.length > 1) {
      setImporterError((prevErrors) => ({
        ...prevErrors,
        codeError: false
      }));
      setErrorHappend(false);
    }
    if (ImporterDetails?.city?.length > 1) {
      setImporterError((prevErrors) => ({
        ...prevErrors,
        cityError: false
      }));
      setErrorHappend(false);
    }
    if (ImporterDetails?.country?.length > 1) {
      setImporterError((prevErrors) => ({
        ...prevErrors,
        countryError: false
      }));
      setErrorHappend(false);
    }
    if (ImporterDetails?.products?.length >= 1) {
      setImporterError((prevErrors) => ({
        ...prevErrors,
        productsError: false
      }));
      setErrorHappend(false);
    }
    if (ImporterDetails?.products?.length > 1) {
      setImporterError((prevErrors) => ({
        ...prevErrors,
        productsError: false
      }));
      setErrorHappend(false);
    }
    if (ImporterDetails?.address?.length > 1) {
      setImporterError((prevErrors) => ({
        ...prevErrors,
        addressError: false
      }));
      setErrorHappend(false);
    }
  }, [ImporterDetails]);

  useEffect(() => {
    if (productId) {
      setImporterDetails({
        name: ImporterUniqueData?.data?.importerName,
        code: ImporterUniqueData?.data?.eoriCode,
        country: ImporterUniqueData?.data?.country,
        address: ImporterUniqueData?.data?.address,
        city: ImporterUniqueData?.data?.city,
        status: ImporterUniqueData?.data?.status,
        products: ImporterUniqueData?.data?.products?.map((item) => item._id)
      });

      setproductListForAdding(
        ImporterUniqueData?.data?.products?.map((item) => {
          return item.productName;
        })
      );
    } else {
      resetComponentState();
    }
  }, [ImporterUniqueData?.data, modalOpen, productId]);

  const handleFieldChange = (event) => {
    setImporterDetails({ ...ImporterDetails, [event.target.name]: event.target.value });
  };

  const handleCountryChange = (event, newValue) => {
    setImporterDetails({ ...ImporterDetails, country: newValue });
  };

  const [importerError, setImporterError] = useState({
    addressError: false,
    cityError: false,
    CodeError: false,
    countryError: false,
    nameError: false,
    productsError: false,
    statusError: false
  });

  const handleSubmit = async () => {
    const errors = {
      codeError: ImporterDetails?.code?.trim() === "",
      countryError: !ImporterDetails?.country || ImporterDetails?.country?.trim() === "",
      nameError: ImporterDetails?.name?.trim() === "",
      productsError: ImporterDetails?.products?.length === 0
    };

    setImporterError(errors);
    const hasErrors = Object.values(errors).some((error) => error);

    if (hasErrors) {
      setErrorHappend(true);
    } else {
      if (!productId || isDuplicate) {
        if (!ImporterDetails.city) ImporterDetails.city = undefined;
        if (!ImporterDetails.address) ImporterDetails.address = undefined;

        ImporterDetails.name = isDuplicate ? `Copy of ${ImporterDetails.name}` : ImporterDetails.name;

        const data = await createImporter(ImporterDetails);
        if (data?.data?.success) {
          openSnackbar(data?.data?.message, "success");
          setIsDuplicate(false);
          setModalOpen(false);
        } else {
          openSnackbar(data?.data?.message, "success");
        }
      } else {
        ImporterDetails.products = [...new Set(ImporterDetails.products)];
        const data = await updateImporter({ id: productId, body: ImporterDetails });
        if (data?.data?.success) {
          refetchUniqueData();
          openSnackbar(data?.data?.message, "success");
        }
        setModalOpen(false);
      }
    }
  };

  const handleStatus = (value) => {
    setImporterDetails({ ...ImporterDetails, status: value.target.value });
  };

  const handleProductCN = (event, value) => {
    console.log("valuevalue", value);
    const valuesArray = value?.map((item) => item.value);
    console.log("valuevalue", valuesArray);
    const selectedProductIds = valuesArray.map((item) => {
      const selectedProduct = getProductList?.data?.data?.products.find((product) => product.productName === item);
      return selectedProduct ? selectedProduct._id : null;
    });
    let filteredIds = selectedProductIds.filter((id) => id !== null);
    setproductListForAdding(valuesArray);
    if (editFlow) {
      const completeArray = getProductList?.data?.data?.products;
      const getProductIds = (value, completeArray) => {
        return valuesArray.map((item) => {
          if (typeof item === "object") {
            const foundProduct = completeArray.find((product) => product?.productName === item?.label);
            return foundProduct ? foundProduct?._id : null;
          } else {
            const foundProduct = completeArray.find((product) => product.productName === item);
            return foundProduct ? foundProduct._id : null;
          }
        });
      };
      const productIds = getProductIds(value, completeArray);
      setImporterDetails({ ...ImporterDetails, products: productIds });
    } else {
      const uniqueFilteredIds = [...new Set(filteredIds)];
      setImporterDetails({ ...ImporterDetails, products: uniqueFilteredIds });
    }
  };

  return (
    <>
      <CustomModal modalOpen={modalOpen} setModalOpen={setModalOpen} outSideClose={true}>
        <Box sx={{ minWidth: 700, minHeight: 500 }}>
          <div
            className="d-flex justify-content-between"
            style={{ display: "flex", justifyContent: "", flexDirection: "column" }}
          >
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              {productId ? (isDuplicate ? "Duplicate Product" : "Edit Importer Detail") : "Add New Importer"}
            </Typography>
            {errorHappend && (
              <div
                style={{
                  marginTop: ".5rem",
                  width: "75%",
                  backgroundColor: "red",
                  height: "2rem",
                  borderRadius: ".25rem",
                  color: "#ffff",
                  paddingLeft: ".4rem",
                  paddingRight: ".4rem",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  textAlign: "center"
                }}
              >
                <div>
                  <InfoOutlinedIcon />
                  &nbsp;Error: Please fill the mandatory fields to add new Importer.
                </div>
              </div>
            )}
          </div>

          <Grid container columns={10} spacing={2} className="mt-0">
            <Grid item xs={10}>
              <p className="labelTextModal">
                Importer Name<span style={{ color: "red" }}>*</span>
              </p>
              <TextField
                autoComplete="off"
                placeholder={
                  !importerError.nameError ? "Enter Importer/Company Name" : "Importer/Company Name can’t be empty"
                }
                style={{
                  border: importerError.nameError ? "1px solid red" : "",
                  color: importerError.nameError ? "red" : ""
                }}
                name="name"
                value={isDuplicate ? `Copy of ${ImporterDetails?.name}` : ImporterDetails?.name}
                onChange={handleFieldChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={10}>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div>
                  <p className="labelTextModal">
                    EORI (Economic Operators Registration and Identification) Code
                    <span style={{ color: "red" }}>*</span>
                  </p>
                </div>
                <div>
                  <Tooltip
                    title="EORI is a unique no. that customs authorities use to identify economic operators in the European Union (EU)."
                    arrow
                    placement="top"
                  >
                    <InfoOutlinedIcon sx={{ cursor: "auto", fontSize: "1.2rem" }} />
                  </Tooltip>
                </div>
              </div>

              <TextField
                autoComplete="off"
                placeholder={!importerError.codeError ? "Enter EORI Code" : "EORI can’t be empty"}
                style={{
                  border: importerError.codeError ? "1px solid red" : "",
                  color: importerError.codeError ? "red" : ""
                }}
                name="code"
                inputProps={{ maxLength: 20 }}
                value={ImporterDetails?.code}
                onChange={handleFieldChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={10}>
              <p className="labelTextModal">
                Choose Product<span style={{ color: "red" }}>*</span>
              </p>
              <Select
                showSearch
                mode="multiple"
                name="scopes"
                placeholder={!importerError.productsError ? "Type or search Product" : "Product Name can't be empty"}
                style={{
                  border: importerError.productsError ? "1px solid red" : "",
                  borderRadius: importerError.productsError ? "10px" : ""
                }}
                size="large"
                value={productListForAdding}
                onChange={handleProductCN}
                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                // onChange={(value) => handleSelectInputChange("scopes", value)}
                className="w-100"
              >
                {ProductListForDropdown?.map((item, index) => (
                  <Select.Option label={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
              {/* <Autocomplete
                id="facility"
                disablePortal
                options={ProductListForDropdown}
                value={productListForAdding}
                onChange={handleProductCN}
                multiple
                filterSelectedOptions
                popupIcon={<KeyboardArrowDownIcon />}
                renderInput={(params) => (
                  <TextField
                    style={{ border: importerError.productsError ? "1px solid red" : "" }}
                    placeholder={
                      !importerError.productsError ? "Type or search Product" : "Product Name can't be empty"
                    }
                    name="products"
                    fullWidth
                    {...params}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused, &:hover": {
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #D2D2D2"
                          }
                        }
                      }
                    }}
                  /> */}
              {/* )} /> */}
            </Grid>
            <Grid item xs={10} md={10}>
              <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                <div style={{ color: "#1F93EF", width: "14%", fontSize: "12px", marginRight: ".5rem" }}>
                  Importer&nbsp;Address
                </div>
                <div style={{ width: "86%", borderBottom: "1px solid #1F93EF" }}></div>
              </div>
            </Grid>
            <Grid container sx={{ paddingTop: "-1rem" }} item xs={12} spacing={1}>
              <Grid item xs={6}>
                <p className="labelTextModal">
                  Country<span style={{ color: "red" }}>*</span>
                </p>
                <Autocomplete
                  id="cncode"
                  disablePortal
                  options={CountryArray}
                  popupIcon={<KeyboardArrowDownIcon />}
                  value={ImporterDetails?.country ? ImporterDetails?.country : ""}
                  onChange={handleCountryChange}
                  renderInput={(params) => (
                    <TextField
                      style={{ border: importerError.countryError ? "1px solid red" : "" }}
                      placeholder={!importerError.countryError ? "Enter Country Name" : "Country Name can't be empty"}
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <p className="labelTextModal">City</p>
                <TextField
                  placeholder="Enter City Name"
                  name="city"
                  autoComplete="off"
                  value={ImporterDetails?.city}
                  onChange={handleFieldChange}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid item xs={10}>
              <p className="labelTextModal">Address</p>
              <TextField
                placeholder="Enter Complete Address"
                autoComplete="off"
                name="address"
                maxLength={256}
                value={ImporterDetails?.address}
                onChange={handleFieldChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={10}>
              <p className="labelTextModal">
                Importer Status<span style={{ color: "red" }}>*</span>
              </p>
              <div>
                <select
                  style={{
                    backgroundColor: ImporterDetails?.status === "Active" ? `#DAFCD6` : "",
                    color: ImporterDetails?.status === "Active" ? `#22B60E` : "",
                    borderRadius: "10px",
                    borderColor: "#c2c2c2"
                  }}
                  value={ImporterDetails?.status}
                  onChange={handleStatus}
                  name="status"
                  className="form-select modalInput"
                  aria-label=".form-select-lg example"
                >
                  <option style={{ color: "black" }} value="Draft">
                    Draft
                  </option>
                  <option style={{ color: "black" }} value="Active">
                    Active
                  </option>
                  <option style={{ color: "black" }} value="Inactive">
                    Inactive
                  </option>
                </select>
              </div>
            </Grid>
            <Grid item xs={5}>
              <button className="btn btn-success" onClick={handleSubmit}>
                Save Importer
              </button>
            </Grid>
            <Grid item xs={5}>
              <button
                className="btn btn-outline-success"
                onClick={() => {
                  setModalOpen(false);
                  setImporterDetails(ImporterInitState);
                  setproductListForAdding();
                  setProductId(null);
                  setIsDuplicate(false);
                }}
              >
                Cancel
              </button>
            </Grid>
          </Grid>
        </Box>
      </CustomModal>
    </>
  );
};
