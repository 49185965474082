import React, { useEffect, useState } from "react";
import UploadCard from "../../../components/UploadCard/UploadCard";
import { useLazyGetTemplateListQuery, useLazyGetUserDetailByIdQuery } from "../../../state/api";

import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, Tab, Tabs, TextField } from "@mui/material";
import DragDropFile from "../../../components/FileUpload/DragDropFile";
import CustomModal from "../../../components/Modal/Modal";
import DataEntries from "../../DataEntries/DataEntries";
import "../Measure.scss";
import { useSelector } from "react-redux";

const labelStyle = {
  top: "-6",
};

const AirEmissions = () => {
  const [getTemplate, resultTemplate] = useLazyGetTemplateListQuery();

  const [modalOpen, setModalOpen] = useState(false);
  const [tabValue, setTabValue] = useState("1");
  const [searchTerm, setSearchTerm] = useState("");

  const userId = useSelector((state) => state.global.user);
  const [user, setUser] = useState(null)
  const [getUser, userResult] = useLazyGetUserDetailByIdQuery()

  useEffect(() => {
    getUser({ id: userId._id }, true)
  }, [])

  useEffect(() => {
    if (userResult.data?.data) {
      setUser(userResult.data?.data)
    }
  }, [userResult.data?.data])

  useEffect(() => {
    if (searchTerm) {
      getTemplate({ search: searchTerm }, true);
    } else {
      getTemplate({}, true);
    }
  }, [resultTemplate.data]);


  const handleTabsChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === "") {
      onSearch("");
    }
  };

  const onSearch = (search) => {
    getTemplate({ search });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSearch(searchTerm);
    }
  };

  const openBulkUploadModal = () => {
    if (!user?.userGroup?.permissions?.measure?.bulkUpload) {
      return
    }
    setModalOpen(true)
  }

  return (
    <>
      {modalOpen && (
        <CustomModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <DragDropFile setModalOpen={setModalOpen} />
          </div>
        </CustomModal>
      )}
      <div className="measure-wrapper">
        <div>
          <Tabs
            value={tabValue}
            onChange={handleTabsChange}
            aria-label="wrapped label tabs example"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <Tab value="1" label="Data" />
            {user?.userGroup?.permissions?.measure?.viewConsumptionData && <Tab value="2" label="Your Consumption Data" />}
          </Tabs>
        </div>
        {tabValue === "1" ? (
          <>
            <div className="measureTableHeader gap-2">
              <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                Select emission source
              </div>
              <div className="d-flex flex-wrap gap-2">
                <div className="me-10">
                  <TextField
                    id="search"
                    type="search"
                    label="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onKeyPress={handleKeyPress}
                    sx={{ background: "white", border: "none" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" onClick={() => onSearch(searchTerm)} style={{ cursor: "pointer" }}>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      style: labelStyle,
                    }}
                    className="ps-search-bar"
                  />
                </div>
                <div></div>
                <div>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={openBulkUploadModal}
                    disabled={!user?.userGroup?.permissions?.measure?.bulkUpload}
                  >
                    Bulk Upload
                  </button>
                </div>
              </div>
            </div>
            {resultTemplate.data?.data.map((item, key) => (
              item.scope.name === "air emission" && 
              <div className="measureCardContainer" key={item.scope.name}>
                <div className="scopeDesc">
                  <p className="scopeDescriptionPara">
                    {item.scope.name}
                  </p>
                  <p className="descriptionOfScope">{item.scope.description}</p>
                </div>
                <div className="cardsContainer">
                  {item.templetes.map((subItem, subKey) => (
                    <UploadCard key={subItem._id} itemId={subItem._id} data={subItem} canSelect={false} viewOnly={!user?.userGroup?.permissions?.measure?.editAirData} />
                  ))}
                </div>
              </div>
            ))}
          </>
        ) : (
          <DataEntries canEdit={user?.userGroup?.permissions?.measure?.editConsumptionData} tab={"airemission"} />
        )}
      </div>
    </>
  );
};

export default AirEmissions;
