import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CustomModal from "../../components/Modal/Modal";
import CustomTabs from "../../components/Tabs/CustomTabs";
import { useSnackbar } from "../../components/snackbar/SnackbarContext";
import {
  useGetFacilityDataQuery,
  useLazyGetUserDetailByIdQuery,
  usePostEsgFormDataMutation,
  useLazyGetEsgDataEntriesQuery,
  useUpdateEsgEntryDataMutation
} from "../../state/api";
import { setTabWiseValueGlobal, setTabs } from "../../state/esgTabsSlice";
import "../SingleUpload/SingleUpload.scss";
import Employment from "../Measure/Social/SocialSteps/Employment/Employment";
import Occupational from "../Measure/Social/SocialSteps/Occupational/Occupational";
import LaborMgmtRelations from "../Measure/Social/SocialSteps/LaborMgmtRelations/LaborMgmtRelations";
import HumanRightsAssessment from "../Measure/Social/SocialSteps/HumanRightsAssessment/HumanRightsAssessment";
import TrainingEducation from "../Measure/Social/SocialSteps/TrainingEducation/TrainingEducation";
import DiversityEqualOpportunity from "../Measure/Social/SocialSteps/DiversityEqualOpportunity/DiversityEqualOpportunity";
import NonDiscrimination from "../Measure/Social/SocialSteps/NonDiscrimination/NonDiscrimination";
import FreedomAssociation from "../Measure/Social/SocialSteps/FreedomAssociation/FreedomAssociation";
import { setPageWiseData } from "../../state/SocialSlice";
import { emptyCorruptionPageData, emptyFormPageWiseData, emptyPageWiseData } from "../../helper/EmptyEsgData";
import { StructureComposition } from "../Measure/Governance/GovernanceForm/StructureComposition/StructureComposition";
import GovernanceBodyStructure from "../Measure/Governance/GovernanceForm/GovernanceBodyStructure/GovernanceBodyStructure";
import EsgSelectCard from "./EsgSelectCard/EsgSelectCard";
import GovernanceBodyManagement from "../Measure/Governance/GovernanceForm/GovernanceBodyManagement/GovernanceBodyManagement";
import DelegationResponsibility from "../Measure/Governance/GovernanceForm/DelegationResponsibility/DelegationResponsibility";
import Sustainability from "../Measure/Governance/GovernanceForm/Sustainability/Sustainability";
import ConflictInterest from "../Measure/Governance/GovernanceForm/ConflictInterest/ConflictInterest";
import Communication from "../Measure/Governance/GovernanceForm/Communication/Communication";
import KnowledgePerformance from "../Measure/Governance/GovernanceForm/KnowledgePerformance/KnowledgePerformance";
import RemunerationPolicies from "../Measure/Governance/GovernanceForm/RemunerationPolicies/RemunerationPolicies";
import AntiCompetitive from "../Measure/Governance/GovernanceForm/AntiCompetitive/AntiCompetitive";
import Tax from "../Measure/Governance/GovernanceForm/Tax/Tax";

import { setFormWiseData } from "../../state/govtSlice";
import { setFormWiseData as setCorruptFormData } from "../../state/antiCorruptionSlice";
import Curroption from "../Measure/Governance/GovernanceForm/Curroption/Curroption";
import Lottie from "react-lottie";
import * as animationData from "../../helper/tick-animation.json";
import moment from "moment";

const EsgUpload = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { pageWiseData } = useSelector((state) => state.social);

  const { formPageWiseData } = useSelector((state) => state.governance);

  const { formWiseData } = useSelector((state) => state.corrupt);

  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const pathname = window.location.pathname;
  const [postEsgFormData] = usePostEsgFormDataMutation({});
  const [updateEsgFormData] = useUpdateEsgEntryDataMutation({});

  const [getEsgFormData, resultEsgFormData] = useLazyGetEsgDataEntriesQuery();

  const { data } = useGetFacilityDataQuery();

  const receivedDataId = location.state?.data;
  const receivedDataTitle = location.state?.title;
  const receivedDataImage = location.state?.image;
  const receivedDataLink = location.state?.link;

  const { openSnackbar } = useSnackbar();

  const [activeTab, setActiveTab] = useState(receivedDataId);

  const [openSelectCategoryModal, setOpenSelectCategoryModal] = useState(false);

  const { tabWiseValueGlobal, tabs } = useSelector((state) => state.esgTabs);

  const [apiLoading, setApiLoading] = useState(false);
  const userId = useSelector((state) => state.global.user);
  const [user, setUser] = useState(null);
  const [getUser, userResult] = useLazyGetUserDetailByIdQuery();
  const [formDataEntry, setFormDataEntry] = useState();
  const [editMode, seteditMode] = useState(false);
  const [formEditable, setformEditable] = useState(false);
  const [openCongratsModal, setopenCongratsModal] = useState(false);

  const timerRef = useRef(null);

  const [facData, setFacData] = useState({
    facility: "",
    year: dayjs()
  });

  const [canEdit, setCanEdit] = useState({
    facEdit: false,
    yearEdit: false
  });

  useEffect(() => {
    const date = dayjs(facData.year, "YYYY-MM");
    const year = date.format("YYYY");
    try {
      if (facData.facility) {
        getEsgFormData({ id: activeTab, facilityId: facData.facility, year });
      }
    } catch (error) {
      dispatch(setPageWiseData(emptyPageWiseData));
      dispatch(setFormWiseData(emptyFormPageWiseData));
    }
  }, [facData.facility, facData.year, activeTab]);

  useEffect(() => {
    if (resultEsgFormData.data?.data?.data) {
      seteditMode(true);
      let socialAllData = JSON.parse(JSON.stringify(pageWiseData));

      let governanceAllData = JSON.parse(JSON.stringify(formPageWiseData));

      let corruptionData = JSON.parse(JSON.stringify(formWiseData));

      if (params.has("employment")) {
        socialAllData.Employment = resultEsgFormData.data.data.data[0].entry;
        setFormDataEntry(resultEsgFormData.data.data.data[0].entry);
        dispatch(setPageWiseData(socialAllData));
      } else if (params.has("labor")) {
        socialAllData.LaborManagementRelations = resultEsgFormData.data.data.data[0].entry;
        setFormDataEntry(resultEsgFormData.data.data.data[0].entry);
        dispatch(setPageWiseData(socialAllData));
      } else if (params.has("health")) {
        socialAllData.occupationalHealthSafety = resultEsgFormData.data.data.data[0].entry;
        setFormDataEntry(resultEsgFormData.data.data.data[0].entry);
        dispatch(setPageWiseData(socialAllData));
      } else if (params.has("training")) {
        socialAllData.trainingAndEducation = resultEsgFormData.data.data.data[0].entry;
        setFormDataEntry(resultEsgFormData.data.data.data[0].entry);
        dispatch(setPageWiseData(socialAllData));
      } else if (params.has("diversity")) {
        socialAllData.diversityEqualOpportunity = resultEsgFormData.data.data.data[0].entry;
        setFormDataEntry(resultEsgFormData.data.data.data[0].entry);
        dispatch(setPageWiseData(socialAllData));
      } else if (params.has("discrimination")) {
        socialAllData.nonDiscrimination = resultEsgFormData.data.data.data[0].entry;
        setFormDataEntry(resultEsgFormData.data.data.data[0].entry);
        dispatch(setPageWiseData(socialAllData));
      } else if (params.has("freedom")) {
        socialAllData.freedomOfAssociation = resultEsgFormData.data.data.data[0].entry;
        setFormDataEntry(resultEsgFormData.data.data.data[0].entry);
        dispatch(setPageWiseData(socialAllData));
      } else if (params.has("humanrights")) {
        socialAllData.humanRightsAssessment = resultEsgFormData.data.data.data[0].entry;
        setFormDataEntry(resultEsgFormData.data.data.data[0].entry);
        dispatch(setPageWiseData(socialAllData));
      } else if (params.has("composition")) {
        governanceAllData.governanceStructureComposition = resultEsgFormData.data.data.data[0].entry;
        setFormDataEntry(resultEsgFormData.data.data.data[0].entry);
        dispatch(setFormWiseData(governanceAllData));
      } else if (params.has("structure")) {
        governanceAllData.structureGovernancBbody = resultEsgFormData.data.data.data[0].entry;
        setFormDataEntry(resultEsgFormData.data.data.data[0].entry);
        dispatch(setFormWiseData(governanceAllData));
      } else if (params.has("role")) {
        governanceAllData.roleOfHighestGovernanceBody = resultEsgFormData.data.data.data[0].entry;
        setFormDataEntry(resultEsgFormData.data.data.data[0].entry);
        dispatch(setFormWiseData(governanceAllData));
      } else if (params.has("delegation")) {
        governanceAllData.delegationOfResponsibility = resultEsgFormData.data.data.data[0].entry;
        setFormDataEntry(resultEsgFormData.data.data.data[0].entry);
        dispatch(setFormWiseData(governanceAllData));
      } else if (params.has("sustainability")) {
        governanceAllData.roleOfGovernanceBodyInSustainability = resultEsgFormData.data.data.data[0].entry;
        setFormDataEntry(resultEsgFormData.data.data.data[0].entry);
        dispatch(setFormWiseData(governanceAllData));
      } else if (params.has("conflict")) {
        governanceAllData.conflictOfInterest = resultEsgFormData.data.data.data[0].entry;
        setFormDataEntry(resultEsgFormData.data.data.data[0].entry);
        dispatch(setFormWiseData(governanceAllData));
      } else if (params.has("communication")) {
        governanceAllData.communicationOfCriticalConcerns = resultEsgFormData.data.data.data[0].entry;
        setFormDataEntry(resultEsgFormData.data.data.data[0].entry);
        dispatch(setFormWiseData(governanceAllData));
      } else if (params.has("performance")) {
        governanceAllData.knowledgeAndPerformance = resultEsgFormData.data.data.data[0].entry;
        setFormDataEntry(resultEsgFormData.data.data.data[0].entry);
        dispatch(setFormWiseData(governanceAllData));
      } else if (params.has("renumeration")) {
        governanceAllData.remunerationPolicies = resultEsgFormData.data.data.data[0].entry;
        setFormDataEntry(resultEsgFormData.data.data.data[0].entry);
        dispatch(setFormWiseData(governanceAllData));
      } else if (params.has("anti")) {
        governanceAllData.antiCompetitive = resultEsgFormData.data.data.data[0].entry;
        setFormDataEntry(resultEsgFormData.data.data.data[0].entry);
        dispatch(setFormWiseData(governanceAllData));
      } else if (params.has("corruption")) {
        corruptionData.antiCorruption = resultEsgFormData.data.data.data[0].entry;
        setFormDataEntry(resultEsgFormData.data.data.data[0].entry);
        dispatch(setCorruptFormData(corruptionData));
      } else if (params.has("tax")) {
        governanceAllData.tax = resultEsgFormData.data.data.data[0].entry;
        setFormDataEntry(resultEsgFormData.data.data.data[0].entry);
        dispatch(setFormWiseData(governanceAllData));
      }
    } else {
      setFormDataEntry(null);
      seteditMode(false);
      setformEditable(false);
      if (pathname.includes("social")) {
        dispatch(setPageWiseData(emptyPageWiseData));
      } else if (params.has("corruption")) {
        dispatch(setCorruptFormData(emptyCorruptionPageData));
      } else {
        dispatch(setFormWiseData(emptyFormPageWiseData));
      }
    }
  }, [resultEsgFormData?.data?.data?.data]);

  useEffect(() => {
    getUser({ id: userId._id }, true);
  }, []);

  useEffect(() => {
    if (userResult.data?.data) {
      setUser(userResult.data?.data);
    }
  }, [userResult.data?.data]);

  useEffect(() => {
    if (data?.data?.facilities && data?.data?.facilities?.length > 0)
      setFacData({ ...facData, facility: data.data.facilities[0]._id });
  }, [data?.data]);

  const handleChange = (e, valueObj) => {
    setCanEdit({ ...canEdit, facEdit: false });
    setFacData({ ...facData, facility: valueObj.value });
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("year");
  };

  const handleYearChange = (value, dateString) => {
    if (dateString) {
      setCanEdit({ ...canEdit, yearEdit: false });
      setFacData({ ...facData, year: dayjs(dateString) });
    } else {
      setFacData({ ...facData, year: undefined });
    }
  };

  const handleTabChange = (cardId) => {
    setActiveTab(cardId);
    let findLink = tabs.find((item) => item.id === cardId);
    navigate(`/${findLink.link}`);
  };

  const closeTab = (value) => {
    let allTab = JSON.parse(JSON.stringify(tabs));
    let allTabVal = JSON.parse(JSON.stringify(tabWiseValueGlobal));
    const { [value]: _, ...newObj } = allTabVal;
    let remainingTabs = allTab.filter((item) => item.id !== value);

    if (value === activeTab) {
      let findLink = allTab.find((item) => item.id === remainingTabs[0]?.id);
      if (findLink) {
        navigate(`/${findLink.link}`);
      }
      setActiveTab(remainingTabs[0]?.id);
    }

    dispatch(setTabs(remainingTabs));
    dispatch(setTabWiseValueGlobal(newObj));

    if (remainingTabs.length === 0) {
      if (pathname.includes("social")) {
        navigate("/social");
      } else {
        navigate("/governance");
      }
    }
  };

  const cancelSelect = () => {
    setOpenSelectCategoryModal(false);
  };

  const addSelectedCards = (arrOfCards) => {
    let tabVal = JSON.parse(JSON.stringify(tabs));
    let newTabs = [...tabVal, ...arrOfCards];
    dispatch(setTabs(newTabs));
    setOpenSelectCategoryModal(false);
  };

  const cancelDataUpload = () => {
    clearTimeout(timerRef.current);
    dispatch(setTabWiseValueGlobal({}));
    dispatch(setTabs([]));
    setopenCongratsModal(false);
    if (pathname.includes("social")) {
      navigate("/social", { state: { openCongratsModal: openCongratsModal } });
    } else {
      navigate("/governance");
    }
  };

  useEffect(() => {
    let existingTabs = JSON.parse(JSON.stringify(tabs));
    const tabExists = existingTabs.some((obj) => obj.id === activeTab);

    if (!tabExists) {
      dispatch(
        setTabs([
          ...existingTabs,
          {
            id: receivedDataId,
            title: receivedDataTitle,
            image: receivedDataImage,
            link: receivedDataLink
          }
        ])
      );
    }
  }, [receivedDataId, receivedDataTitle, receivedDataImage, dispatch]);

  const handleSubmit = async (e) => {

    if (apiLoading) return;
    try {
      setApiLoading(true);
      if (!facData.year) {
        openSnackbar("Year and month are required", "warning");
        setApiLoading(false);
        return;
      }
      if (!facData.facility) {
        openSnackbar("Facility is required", "warning");
        setApiLoading(false);
        return;
      }

      const date = dayjs(facData.year, "YYYY-MM");
      const year = date.format("YYYY");
      let finalData = {};
      finalData.year = year;
      finalData.facilityId = facData.facility;
      finalData.esgTemplate = activeTab;
      finalData.entry = params.has("employment")
        ? pageWiseData.Employment
        : params.has("labor")
        ? pageWiseData.LaborManagementRelations
        : params.has("health")
        ? pageWiseData.occupationalHealthSafety
        : params.has("training")
        ? pageWiseData.trainingAndEducation
        : params.has("diversity")
        ? pageWiseData.diversityEqualOpportunity
        : params.has("discrimination")
        ? pageWiseData.discrimination
        : params.has("freedom")
        ? pageWiseData.freedom
        : params.has("humanrights")
        ? pageWiseData.humanRightsAssessment
        : params.has("composition")

        //governance start from here 
        ? formPageWiseData.  governance
        : params.has("structure")
        ? formPageWiseData.structureComposition
        : params.has("role")
        ? formPageWiseData.highestGovernance
        : params.has("delegation")
        ? formPageWiseData.managingImpacts
        : params.has("sustainability")
        ? formPageWiseData.sustainability
        : params.has("conflict")
        ? formPageWiseData.conflictInterest
        : params.has("communication")
        ? formPageWiseData.criticalConcerns
        : params.has("performance")
        ? formPageWiseData.knowledgeAndPerformance
        : params.has("renumeration")
        ? formPageWiseData.remunerationPolicies
        : params.has("anti")
        ? formPageWiseData.organisation
        : params.has("corruption")
        ? formWiseData.antiCorruption
        : formPageWiseData.tax;

      let res;
      if (formDataEntry) {
        res = await updateEsgFormData({
          id: activeTab,
          facilityId: facData.facility,
          year,
          entry: finalData.entry
        });
      } else {
        res = await postEsgFormData(finalData);
      }

      if (res?.data?.success) {
        closeModalWithTimeout();
      } else {
        openSnackbar(res?.error?.data?.message, "warning");
        setApiLoading(false);
      }
    } catch (err) {
      openSnackbar(err.message, "error");
      setApiLoading(false);
    }
  };

  const closeModalWithTimeout = () => {
    setopenCongratsModal(true);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    } else {
      timerRef.current = setTimeout(() => {
        setopenCongratsModal(false);
        cancelDataUpload();
      }, 5000);
    }
  };

  const renderComponent = () => {
    if (params.has("employment")) {
      return <Employment editMode={editMode} formEditable={formEditable} />;
    } else if (params.has("labor")) {
      return <LaborMgmtRelations editMode={editMode} formEditable={formEditable} />;
    } else if (params.has("health")) {
      return <Occupational editMode={editMode} formEditable={formEditable} />;
    } else if (params.has("training")) {
      return <TrainingEducation editMode={editMode} formEditable={formEditable} />;
    } else if (params.has("diversity")) {
      return <DiversityEqualOpportunity editMode={editMode} formEditable={formEditable} />;
    } else if (params.has("discrimination")) {
      return <NonDiscrimination editMode={editMode} formEditable={formEditable} />;
    } else if (params.has("freedom")) {
      return <FreedomAssociation editMode={editMode} formEditable={formEditable} />;
    } else if (params.has("humanrights")) {
      return <HumanRightsAssessment editMode={editMode} formEditable={formEditable} />;
    } else if (params.has("composition")) {
      return <StructureComposition editMode={editMode} formEditable={formEditable} />;
    } else if (params.has("structure")) {
      return <GovernanceBodyStructure editMode={editMode} formEditable={formEditable} />;
    } else if (params.has("role")) {
      return <GovernanceBodyManagement editMode={editMode} formEditable={formEditable} />;
    } else if (params.has("delegation")) {
      return <DelegationResponsibility editMode={editMode} formEditable={formEditable} />;
    } else if (params.has("sustainability")) {
      return <Sustainability editMode={editMode} formEditable={formEditable} />;
    } else if (params.has("conflict")) {
      return <ConflictInterest editMode={editMode} formEditable={formEditable} />;
    } else if (params.has("communication")) {
      return <Communication editMode={editMode} formEditable={formEditable} />;
    } else if (params.has("performance")) {
      return <KnowledgePerformance editMode={editMode} formEditable={formEditable} />;
    } else if (params.has("renumeration")) {
      return <RemunerationPolicies editMode={editMode} formEditable={formEditable} />;
    } else if (params.has("anti")) {
      return <AntiCompetitive editMode={editMode} formEditable={formEditable} />;
    } else if (params.has("corruption")) {
      return <Curroption editMode={editMode} formEditable={formEditable} />;
    } else {
      return <Tax editMode={editMode} formEditable={formEditable} />;
    }
  };

  const enableEdit = () => {
    let findId = tabs.find((item) => item.id === activeTab);
    if (findId.id === activeTab) {
      setformEditable(!formEditable);
    } else {
    }
  };

  const handleCongratsModal = () => {
    setopenCongratsModal(false);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    cancelDataUpload();
  };

  useEffect(() => {
    if (tabs.length > 1 && pathname.includes("social") && tabs.find((item) => item.link.includes("social"))) {
      let tabVal = JSON.parse(JSON.stringify(tabs));
      let findTab = tabVal.filter((item) => item.link.includes("social"));
      let newTabs = [...findTab];
      dispatch(setTabs(newTabs));
    } else if (
      tabs.length > 1 &&
      pathname.includes("governance") &&
      tabs.find((item) => item.link.includes("governance"))
    ) {
      let tabVal = JSON.parse(JSON.stringify(tabs));
      let findTab = tabVal.filter((item) => item.link.includes("governance"));
      let newTabs = [...findTab];
      dispatch(setTabs(newTabs));
    }
  }, [tabs.length]);

  return (
    <>
      {
        <CustomModal modalOpen={openSelectCategoryModal} setModalOpen={setOpenSelectCategoryModal} outSideClose={false}>
          <div className="selectCardsContainer">
            <EsgSelectCard
              addSelectedCards={addSelectedCards}
              cancelSelect={cancelSelect}
              activeTabId={tabs}
              esgType={pathname.includes("social") ? "social" : "govt"}
            />
          </div>
        </CustomModal>
      }
      {
        <CustomModal modalOpen={openCongratsModal} setModalOpen={() => cancelDataUpload()}>
          <div
            style={{
              width: "100%"
            }}
          >
            <div className="d-flex justify-content-end">
              <img
                src="/images/icons/cross-icon.svg"
                alt=""
                className="cursor-pointer"
                onClick={handleCongratsModal}
                style={{ width: "48px", height: "48px" }}
              />
            </div>
            <div className="px-5 py-3">
              <Lottie options={{ loop: false, autoplay: true, animationData }} height={222} width={265} />
              <div className="d-flex flex-column gap-1 justify-content-center align-items-center mt-4">
                <p className="text-dark fs-4 fw-medium">Congratulations!!</p>
                <p className="fs-6 text-dark fw-normal">Your ESG data is successfully submitted</p>
              </div>
            </div>
          </div>
        </CustomModal>
      }
      <div className="d-flex flex-column h-100">
        <div>
          <h3>Data Upload Session</h3>
        </div>
        <div className="filterContent">
          <div className="d-flex">
            <Select
              showSearch
              value={facData.facility !== "" ? facData.facility : null}
              placeholder="Select a facility"
              name="facility"
              optionFilterProp="facility"
              size="large"
              onChange={handleChange}
              style={{ width: "200px" }}
            >
              {user?.facilities?.map((item, ind) => (
                <Select.Option key={ind} value={item._id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
            <div className="calenderSelect">
              <DatePicker
                picker="year"
                id={{
                  start: "2020",
                  end: "2024"
                }}
                defaultValue={dayjs()}
                value={facData.year ? facData.year : undefined}
                format={"YYYY"}
                onChange={handleYearChange}
                size="large"
                placeholder="Select month and year"
                style={{ width: "230px" }}
                disabledDate={disabledDate}
              />
            </div>
          </div>
          <div className="buttonContainer">
            {editMode ? (
              <img
                src={formEditable ? "/images/active-edit-icon.svg" : "/images/edit-icon.svg"}
                alt=""
                className="cursor-pointer"
                style={{ marginRight: "16px" }}
                onClick={() => enableEdit()}
              />
            ) : (
              ""
            )}

            <button
              type="button"
              className="btn btn-success"
              onClick={handleSubmit}
              disabled={editMode && !formEditable}
            >
              {editMode ? "Update" : "Submit"}
            </button>
            <button type="button" className="btn btn-outline-danger ms-2" onClick={cancelDataUpload}>
              Cancel
            </button>
          </div>
        </div>
        <div className="tab-wrapper">
          {tabs?.map((item, key) => (
            <CustomTabs
              key={key + "tabs"}
              closeTab={() => closeTab(item.id)}
              value={item.id}
              image={`/images/templateLogo/${item.image}.svg`}
              title={item.title}
              activeTab={activeTab}
              handleTabChange={handleTabChange}
            />
          ))}
          {tabs?.find((item) => item.id === activeTab)?.title && (
            <div className="tab-container inactive" onClick={() => setOpenSelectCategoryModal(true)}>
              <img src={"/images/icons/add-icon.svg"} alt="add" />
              <div className="title">Add new category</div>
            </div>
          )}
        </div>
        <div className="table-wrapper">{renderComponent()}</div>
      </div>
    </>
  );
};

export default EsgUpload;
