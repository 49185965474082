function deepMergeObject(target, source) {
  //this function is used to deep merge two objects
  for (const key in source) {
    if (typeof source[key] === "object" && source[key] !== null) {
      if (!target[key]) {
        target[key] = Array.isArray(source[key]) ? [] : {};
      }
      deepMergeObject(target[key], source[key]);
    } else {
      target[key] = source[key];
    }
  }
  return target;
}

function deepCopyWithUndefined(obj) {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  const copiedObj = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      copiedObj[key] = deepCopyWithUndefined(obj[key]);
    }
  }

  return copiedObj;
}

function allValuesTrue(obj) {
  for (let key in obj) {
    if (!obj[key]) {
      return false;
    }
  }
  return true;
}

function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}


module.exports = {
  deepMergeObject,
  deepCopyWithUndefined,
  allValuesTrue,
  isValidEmail
};
