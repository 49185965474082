import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import styles from './Loader.scss';

const cx = classNames.bind(styles);

const ButtonLoader = ({nameOfClass}) => {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    return (
        <div className={cx(`${nameOfClass ? nameOfClass : 'bLoader'}`, { mounted: mounted })}>
            <div className={cx('loader-18', { mounted: mounted })}></div>
        </div>
    )
}

export default ButtonLoader