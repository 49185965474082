import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Link, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from "@mui/material";
import { Checkbox, DatePicker, Radio, Select } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { apiUrl } from "../../../../../components/ApiUrl";
import CustomModal from "../../../../../components/Modal/Modal";
import { useSnackbar } from "../../../../../components/snackbar/SnackbarContext";
import { fetchData } from "../../../../../helper/ErrorHandler";
import {
  useCreateActionActionMutation,
  useCreateActionTemplateMutation,
  useDeleteActionActionMutation,
  useGetCurrencyDataQuery,
  useLazyGetActionActionQuery,
  useLazyGetActionMeasureDetailQuery,
  useLazyGetActionMeasureQuery,
  useLazyGetActionPlanQuery,
  useLazyGetAnalysisFilterQuery,
  useUpdateActionActionMutation,
  useLazyGetUserDetailByIdQuery,
  usePostAuditLogDataMutation,
  useGetOrganizationDataQuery,
} from "../../../../../state/api";
import "../../../scss/commonScss.scss";
import "../../Action.scss";
import "./NewActionMeasure.scss";
import { useSelector } from "react-redux";
import findChangedFields from "../../../../../helper/Utils/utilitiyFunctions";

const tableMeasureReductionHeaders = ["NAME", "BUDGET ($)", "FROM", "TO", "COMPLETION RATE", "ASSIGNEE"];

const catArr = [
  "Program and change management",
  "Industrial Processes and Product use (IPPU)",
  "Homeworking",
  "Products",
  "Mobility",
  "Energy",
  "Awareness",
  "Logistic",
  "Waste"
];

export function cleanUpObject(inputObject) {
  const resultObject = {};

  for (const key in inputObject) {
    if (inputObject.hasOwnProperty(key)) {
      const subObject = inputObject[key];
      const selectedValue = Object.keys(subObject).find((value) => subObject[value]);

      resultObject[key] = selectedValue;
    }
  }

  return resultObject;
}

const NewActionMeasure = () => {
  const [createTemplate] = useCreateActionTemplateMutation();
  const [getActionAction, resultActionAction] = useLazyGetActionActionQuery();
  const [createActionAction] = useCreateActionActionMutation();
  const [updateActionAction] = useUpdateActionActionMutation();
  const currData = useGetCurrencyDataQuery();
  const [getFacFilter, resultFilter] = useLazyGetAnalysisFilterQuery();
  const [getActionPlan, resultActionPlan] = useLazyGetActionPlanQuery();
  const [getActionMeasure, resultActionMeasure] = useLazyGetActionMeasureQuery();
  const [getActionMeasureDetail, resultActionMeasureDetail] = useLazyGetActionMeasureDetailQuery();
  const [deleteActionApi] = useDeleteActionActionMutation();

  const navigate = useNavigate();
  const location = useLocation();

  const { measureId, measureName } = location.state;

  const [modalOpen, setModalOpen] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [actionModalEdit, setActionModalEdit] = useState(false);
  const [bodyValues, setBodyValues] = useState({
    category: [],
    name: "",
    description: "",
    impactLevel: [],
    actionVisibility: "Private"
  });

  
  const [tabSelected, setTabSelected] = useState({
    reductionPotential: { Low: false, Medium: false, High: false },
    budget: { Low: false, Medium: false, High: false },
    complexity: { Low: false, Medium: false, High: false }
  });

  const { openSnackbar } = useSnackbar();

  const [templateId, setTemplateId] = useState(null);
  const [actionId, setActionId] = useState(null);

  const fileInputRefOrg = useRef(null);

  const [actionPostData, setActionPostData] = useState({
    facility: "",
    actionPlan: "",
    actionMeasure: "",
    startDate: "",
    endDate: "",
    targetedValue: "",
    unit: "",
    currentValue: "",
    budget: "",
    currency: ""
  });

  
  const [prevActionPostData, setPrevActionPostData] = useState({
    facility: "",
    actionPlan: "",
    actionMeasure: "",
    startDate: "",
    endDate: "",
    targetedValue: "",
    unit: "",
    currentValue: "",
    budget: "",
    currency: ""
  });

  const postPerPage = 12;
  const [totalCount, setTotalCount] = useState(0);
  const [pg, setPg] = useState(0);
  const [logoReader, setLogoReader] = useState(null);
  const [binaryImage, setBinaryImage] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [actionList, setActionList] = useState(false);
  const userId = useSelector((state) => state.global.user)

  const [user, setUser] = useState(null);
  const [postAuditLogData] = usePostAuditLogDataMutation({});
  const orgData = useGetOrganizationDataQuery();
  const [getUser, userResult] = useLazyGetUserDetailByIdQuery();
  useEffect(() => {
    if (userResult.data?.data) {
      setUser(userResult.data?.data);
    }
  }, [userResult.data?.data]);

  useEffect(() => {
    getUser({ id: userId._id }, true);
  }, []);

  useEffect(() => {
    getActionAction({ actionMeasure: measureId, skip: pg * postPerPage, limit: postPerPage }, true);
  }, [pg]);

  useEffect(() => {
    getFacFilter(
      {
        filters: ["global", "category"]
      },
      true
    );
  
  }, []);

  useEffect(()=>{
    if (user?.facilities) {
      setActionPostData({ ...actionPostData, facility: user?.facilities[0]?._id });
    }
  },[user?.facilities])

  useEffect(() => {
    if (actionPostData.facility) {
      if (actionPostData.facility !== "all") {
        getActionPlan({ facility: actionPostData.facility, skip: 0, limit: 200 }, true);
      } else {
        getActionPlan({ skip: 0, limit: 200 }, true);
      }
    }
  }, [actionPostData.facility]);

  useEffect(() => {
    if (actionPostData.actionPlan) {
      getActionMeasure({ actionPlan: actionPostData.actionPlan, skip: 0, limit: 200 }, true);
    }
  }, [actionPostData.actionPlan]);

  useEffect(() => {
    getActionMeasureDetail(measureId);
  }, []);

  useEffect(() => {
    if (resultActionAction.data?.data) {
      setActionList(resultActionAction.data?.data);
    }
  }, [resultActionAction.data?.data]);

  const openModal = () => {
    setModalOpen(true);
  };

  const handlePageChange = async (e, value) => {
    setPg(value);
  };

  const handleButtonClickOrg = () => {
    fileInputRefOrg.current.click();
  };

  const handleImageChange = (event) => {
    try {
      const file = event.target.files;

      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setBinaryImage(file[0]);
          setLogoReader(reader.result);
        };
        reader.readAsDataURL(file[0]);
      }
    } catch (error) {
      openSnackbar(error.message, "error");
    }
  };

  const handleImageUploadTemp = async (templateId) => {
    if (binaryImage) {
      const formData = new FormData();
      formData.append("image", binaryImage);
      formData.append("id", templateId);

      try {
        await fetchData(`${apiUrl}/act/template/update/image`, {
          method: "PUT",
          credentials: "include",
          body: formData
        });
      } catch (error) {
        throw error;
      }
    }
  };

  const onCheckChange = (e) => {
    let impLvl = [...bodyValues.impactLevel];
    if (impLvl.indexOf(e.target.name) === -1) {
      impLvl.push(e.target.name);
    } else {
      impLvl = impLvl.filter((val) => val !== e.target.name);
    }
    setBodyValues({ ...bodyValues, impactLevel: impLvl });
  };

  const onRadioChange = (e) => {
    setBodyValues({ ...bodyValues, actionVisibility: e.target.value });
  };

  const handleButtonClick = (category, name) => {
    switch (category) {
      case "Low":
        setTabSelected({ ...tabSelected, [name]: { Low: true, Medium: false, High: false } });
        break;
      case "Medium":
        setTabSelected({ ...tabSelected, [name]: { Low: false, Medium: true, High: false } });
        break;
      case "High":
        setTabSelected({ ...tabSelected, [name]: { Low: false, Medium: false, High: true } });
        break;
      default:
        break;
    }
  };

  const handleModalFieldChange = (e) => {
    setBodyValues({ ...bodyValues, [e.target.name]: e.target.value });
  };

  const addRemoveCategory = (item) => {
    let cats = [...bodyValues.category];
    if (cats.indexOf(item) === -1) {
      cats.push(item);
    } else {
      cats = cats.filter((val) => val !== item);
    }
    setBodyValues({ ...bodyValues, category: cats });
  };

  const createActions = async () => {
    try {
      let tabSelectedBody = cleanUpObject(tabSelected);
      let bodyObj = { ...bodyValues, ...tabSelectedBody };
      let response = await createTemplate(bodyObj);
      if (response.data && binaryImage) {
        setTemplateId(response.data.data?._id);
        handleImageUploadTemp(response.data.data?._id);
      }
      if (response.error) {
        openSnackbar(response.error.data?.message, "error");
      } else {
        openSnackbar(response.data?.message, "success");

        postAuditLogData({
          action: "CREATE",
          message: `${bodyValues.name} created by  ${user.name}`,
          tab: "Action Plan",
          facility: "",
          organization: orgData.data?.data._id,
          user: userId._id,
          ipAddress: "10.10.0.1"
        });

        getActionMeasureDetail(measureId);
        setModalOpen(false);
        setActionModal(true);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      openSnackbar(errorMessage, "success");
    }
  };

  const openEditModal = (item) => {
    let body = { ...item };
    delete body._id;

    setActionId(item._id);
    setActionModalEdit(true);
    setActionPostData({ ...body });

    let prevActionPlan = (
      resultActionPlan.data?.data?.actionPlanList.find((result) => result._id === item.actionPlan) || {}
    ).name;
    let prevfacility = (
      resultActionPlan.data?.data?.actionPlanList.find((result) => result._id === item.actionPlan) || {}
    ).name;
    let prevActonMeasure = resultActionMeasure.data?.data?.actionMeasureList.find(
      (reMeasure) => reMeasure._id === item.actionMeasure
    ).name;
    console.log("prev", prevActonMeasure);

    setPrevActionPostData({
      facility: prevfacility ? prevfacility : "All facility",
      actionPlan: prevActionPlan ? prevActionPlan : "NA",
      actionMeasure: prevActonMeasure,
      startDate: item.startDate,
      endDate: item.endDate,
      targetedValue: item.targetedValue,
      unit: item.unit,
      currentValue: item.currentValue,
      budget: item.budget,
      currency: currData.data?.data.find((cur) => cur._id === item.currency).name
    });

  };

  const handleYearChange = (name, dateString) => {
    const formattedDate = dayjs(dateString).format("DD-MM-YYYY");
    setActionPostData({ ...actionPostData, [name]: formattedDate });
  };

  const handleActionInputChange = (e) => {
    setActionPostData({ ...actionPostData, [e.target.name]: e.target.value });
  };

  const handleModalSelectFilterChange = (name, val) => {
    setActionPostData({ ...actionPostData, [name]: val });
  };

  const addToAction = async () => {
    let body = { ...actionPostData, actionTemplate: templateId };
    try {
      const response = await createActionAction(body);
      if (response.error) {
        openSnackbar(response.error.data?.message, "error");
      } else {
        openSnackbar(response.data?.message, "success");

        postAuditLogData({
          action: "CREATE",
          message: ` ${actionPostData.actionPlan} created by  by ${user.name}`,
          tab: "Action Plan",
          facility: "",
          organization: orgData.data?.data._id,
          user: userId._id,
          ipAddress: "10.10.0.1"
        });
        setActionPostData({
          facility: "",
          actionPlan: "",
          actionMeasure: "",
          startDate: "",
          endDate: "",
          targetedValue: "",
          unit: "",
          currentValue: "",
          budget: "",
          currency: ""
        });
        setModalOpen(false);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      openSnackbar(errorMessage, "error");
    }
  };

  function formatChangeMessage(changedFields) {
    let message = ``;
    for (const [field, { prevValue, currentValue }] of Object.entries(changedFields)) {
      message += `'${field}' of ${""}  changed from '${prevValue}' to '${currentValue ===undefined ?"NA":currentValue}'.\n `;
    }

    return message;
  }
  const updateAction = async () => {
    let body = {
      id: actionId,
      data: {
        ...actionPostData,
        actionTemplate: actionPostData.actionTemplate._id,
        assignee: actionPostData.assignee._id
      }
    };
    delete body.data.progress;
    delete body.data.updatedAt;
    delete body.data.createdAt;
    delete body.data._id;
    delete body.data.assignee;

    
    let prevActionPlan = (
      resultActionPlan.data?.data?.actionPlanList.find((result) => result._id === actionPostData.actionPlan) || {}
    ).name;
    let prevfacility = (
      resultActionPlan.data?.data?.actionPlanList.find((result) => result._id === actionPostData.actionPlan) || {}
    ).name;
    let prevActonMeasure = resultActionMeasure.data?.data?.actionMeasureList.find(
      (reMeasure) => reMeasure._id === actionPostData.actionMeasure
    ).name;

    let currentActionData = {
      facility: prevfacility ? prevfacility : "All facility",
      actionPlan: prevActionPlan ? prevActionPlan : "NA",
      actionMeasure: prevActonMeasure,
      startDate: actionPostData.startDate,
      endDate: actionPostData.endDate,
      targetedValue: actionPostData.targetedValue,
      unit: actionPostData.unit,
      currentValue: actionPostData.currentValue,
      budget: actionPostData.budget,
      currency: currData.data?.data.find((cur) => cur._id === actionPostData.currency).name
    };

    try {
      const response = await updateActionAction(body);
      if (response.error) {
        openSnackbar(response.error.data?.message, "error");
      } else {
        openSnackbar(response.data?.message, "success");
    
        postAuditLogData({
          action: "UPDATE",
          message: ` ${formatChangeMessage(findChangedFields(prevActionPostData, currentActionData))}  by ${user.name}`,
          tab: "Action Plan",
          facility: "",
          organization: orgData.data?.data._id,
          user: userId._id,
          ipAddress: "10.10.0.1"
        });

        getActionMeasureDetail(measureId);
        setActionPostData({
          facility: "",
          actionPlan: "",
          actionMeasure: "",
          startDate: "",
          endDate: "",
          targetedValue: "",
          unit: "",
          currentValue: "",
          budget: "",
          currency: ""
        });
        setActionModalEdit(false);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      openSnackbar(errorMessage, "error");
    }
  };

  const deleteAction = (id) => {
    setActionId(id);
    setConfirmModal(true);
  };

  const deleteActionAction = async () => {
    try {
      let response = await deleteActionApi(actionId);
      let deleteActionName = actionList?.actionActionList.find((item) => item._id === actionId).actionTemplate.name;
      if (response.error) {
        openSnackbar(response.error.data?.message, "error");
      } else {
        setConfirmModal(false);
        openSnackbar(response.data?.message, "success");

        postAuditLogData({
          action: "DELETE",
          message: ` ${deleteActionName} deleted by ${user.name}`,
          tab: "Action Plan",
          facility: "",
          organization: orgData.data?.data._id,
          user: userId._id,
          ipAddress: "10.10.0.1"
        });

      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      openSnackbar(errorMessage, "error");
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const breadcrumbs = [
    <div className="d-flex gap-2 align-items-center">
      <div style={{ width: "15px" }} className="d-flex">
        <ArrowBackIosIcon fontSize="small" className="w-100" />
      </div>
      <Link className="fs-6 cursor-pointer" underline="hover" key="1" color="inherit" onClick={goBack}>
        {measureName}
      </Link>
    </div>
  ];

  return (
    <>
      <Tooltip id={"modal-info-reductionPotential"} style={{ width: "300px", zIndex: 9991 }} place="right" />
      <Tooltip id={"modal-info-budget"} style={{ width: "300px", zIndex: 9991 }} place="right" />
      <Tooltip id={"modal-info-complexity"} style={{ width: "300px", zIndex: 9991 }} place="right" />

      <CustomModal modalOpen={actionModal} setModalOpen={setActionModal} outSideClose={false}>
        <div className="main-modal-container modal-container-wide">
          <div className="d-flex gap-2 align-items-center">
            <p style={{ fontSize: "24px", fontWeight: "bold" }}>Add action to action plan</p>
            <div
              data-tooltip-id={"modal-info-action"}
              data-tooltip-html={`<span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Completion year for the simulation<br/></span><span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word"><br/></span><span style="color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">End year may or may not be the same as any of your target years.</span>`}
            >
              <InfoOutlinedIcon />
            </div>
          </div>
          <div className="divider-container">
            <h6 className="line-divider ">
              <span className="span-line-divider mt-2">Select Action Plan</span>
            </h6>
          </div>
          <div className="w-50">
            <p className="mb-2 fs-12p">
              Facility <sup>*</sup>
            </p>
            <Select
              value={actionPostData.facility}
              placeholder="Facility"
              optionFilterProp="facility"
              size="large"
              onChange={(val) => handleModalSelectFilterChange("facility", val)}
              className="w-100"
            >
              <Select.Option value={"all"}>All Facilties</Select.Option>
              {resultFilter.data?.data?.facility?.map((item, key) => (
                <Select.Option key={key} value={item._id} label={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="d-flex gap-2 mt-3">
            <div className="w-50">
              <p className="mb-2 fs-12p">Action plan</p>
              <Select
                value={actionPostData.actionPlan}
                placeholder="Action Plan"
                optionFilterProp="actionPlan"
                size="large"
                onChange={(val) => handleModalSelectFilterChange("actionPlan", val)}
                className="w-100"
              >
                <Select.Option value={"all"}>All Actions</Select.Option>
                {resultActionPlan.data?.data?.actionPlanList?.map((item, key) => (
                  <Select.Option key={key} value={item._id} label={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="w-50">
              <p className="mb-2 fs-12p">Reduction Measure</p>
              <Select
                value={actionPostData.actionMeasure}
                placeholder="Status"
                optionFilterProp="facility"
                size="large"
                onChange={(val) => handleModalSelectFilterChange("actionMeasure", val)}
                className="w-100"
              >
                <Select.Option value={"all"}>All Measures</Select.Option>
                {resultActionMeasure.data?.data?.actionMeasureList?.map((item, key) => (
                  <Select.Option key={key} value={item._id} label={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>

          <div className="divider-container">
            <h6 className="line-divider ">
              <span className="span-line-divider mt-2">Period</span>
            </h6>
          </div>
          <div className="d-flex gap-2 mt-3">
            <div className="w-50">
              <p className="mb-2 fs-12p">Start Date</p>
              <DatePicker
                value={actionPostData.startDate && dayjs(actionPostData.startDate, "DD-MM-YYYY")}
                format={"DD-MM-YYYY"}
                onChange={(val) => handleYearChange("startDate", val)}
                size="large"
                placeholder="dd-mm-yyyy"
                className="w-100"
              />
            </div>
            <div className="w-50">
              <p className="mb-2 fs-12p">End Date</p>
              <DatePicker
                value={actionPostData.endDate && dayjs(actionPostData.endDate, "DD-MM-YYYY")}
                format={"DD-MM-YYYY"}
                onChange={(val) => handleYearChange("endDate", val)}
                size="large"
                placeholder="dd-mm-yyyy"
                className="w-100"
              />
            </div>
          </div>
          <div className="divider-container">
            <h6 className="line-divider ">
              <span className="span-line-divider mt-2 fs-12p">Target</span>
            </h6>
          </div>
          <div className="d-flex gap-2">
            <div className="flex-fill">
              <p className="mb-2 fs-12p">
                Targeted Value <sup>*</sup>
              </p>
              <input
                type="text"
                className="form-control"
                placeholder="Target"
                aria-label="Target"
                name="targetedValue"
                value={actionPostData.targetedValue}
                onChange={handleActionInputChange}
              />
            </div>
            <div className="flex-fill">
              <div className="d-flex gap-2">
                <p className="mb-2 fs-12p">
                  Unit <sup>*</sup>
                </p>
                <div
                  data-tooltip-id={"modal-info-unit"}
                  data-tooltip-html={`<span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Completion year for the simulation<br/></span><span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word"><br/></span><span style="color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">End year may or may not be the same as any of your target years.</span>`}
                >
                  <InfoOutlinedIcon />
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Unit"
                aria-label="Unit"
                name="unit"
                value={actionPostData.unit}
                onChange={handleActionInputChange}
              />
            </div>
            <div className="flex-fill">
              <div className="d-flex gap-2">
                <p className="mb-2 fs-12p">
                  Current Value <sup>*</sup>
                </p>
                <div
                  data-tooltip-id={"modal-info-current"}
                  data-tooltip-html={`<span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Completion year for the simulation<br/></span><span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word"><br/></span><span style="color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">End year may or may not be the same as any of your target years.</span>`}
                >
                  <InfoOutlinedIcon />
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Current"
                aria-label="Current"
                name="currentValue"
                value={actionPostData.currentValue}
                onChange={handleActionInputChange}
              />
            </div>
          </div>
          <div className="divider-container">
            <h6 className="line-divider ">
              <span className="span-line-divider mt-2">Budget</span>
            </h6>
          </div>
          <div className="d-flex gap-2 mt-3">
            <div className="w-50">
              <p className="mb-2 fs-12p">
                Budget <sup>*</sup>
              </p>
              <input
                type="text"
                className="form-control"
                placeholder="Budget"
                aria-label="Budget"
                name="budget"
                value={actionPostData.budget}
                onChange={handleActionInputChange}
              />
            </div>
            <div className="w-50">
              <p className="mb-2 fs-12p">Currency</p>
              <Select
                value={actionPostData.currency}
                placeholder="Currency"
                optionFilterProp="currency"
                size="large"
                onChange={(val) => handleModalSelectFilterChange("currency", val)}
                className="w-100"
              >
                {currData.data?.data.map((item, key) => (
                  <Select.Option key={key} value={item._id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="button-container">
            <button type="button" className="btn btn-outline-success" onClick={() => setActionModal(false)}>
              Cancel
            </button>
            <button type="button" className="btn btn-success" onClick={addToAction}>
              Add to action plan
            </button>
          </div>
        </div>
      </CustomModal>
      <CustomModal modalOpen={actionModalEdit} setModalOpen={setActionModalEdit} outSideClose={false}>
        <div className="main-modal-container modal-container-wide">
          <div className="d-flex gap-2 align-items-center">
            <p style={{ fontSize: "24px", fontWeight: "bold" }}>Update action</p>
            <div
              data-tooltip-id={"modal-info-action"}
              data-tooltip-html={`<span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Completion year for the simulation<br/></span><span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word"><br/></span><span style="color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">End year may or may not be the same as any of your target years.</span>`}
            >
              <InfoOutlinedIcon />
            </div>
          </div>
          <div className="divider-container">
            <h6 className="line-divider ">
              <span className="span-line-divider mt-2">Select Action Plan</span>
            </h6>
          </div>
          <div className="w-50">
            <p className="mb-2 fs-12p">
              Facility <sup>*</sup>
            </p>
            <Select
              value={actionPostData.facility}
              placeholder="Facility"
              optionFilterProp="facility"
              size="large"
              onChange={(val) => handleModalSelectFilterChange("facility", val)}
              className="w-100"
            >
              <Select.Option value={"all"}>All Facilties</Select.Option>
              {user?.facilities?.map((item, key) => (
                <Select.Option key={key} value={item._id} label={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="d-flex gap-2 mt-3">
            <div className="w-50">
              <p className="mb-2 fs-12p">Action plan</p>
              <Select
                value={actionPostData.actionPlan}
                placeholder="Action Plan"
                optionFilterProp="actionPlan"
                size="large"
                onChange={(val) => handleModalSelectFilterChange("actionPlan", val)}
                className="w-100"
              >
                <Select.Option value={"all"}>All Actions</Select.Option>
                {resultActionPlan.data?.data?.actionPlanList?.map((item, key) => (
                  <Select.Option key={key} value={item._id} label={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="w-50">
              <p className="mb-2 fs-12p">Reduction Measure</p>
              <Select
                value={actionPostData.actionMeasure}
                placeholder="Status"
                optionFilterProp="facility"
                size="large"
                onChange={(val) => handleModalSelectFilterChange("actionMeasure", val)}
                className="w-100"
              >
                <Select.Option value={"all"}>All Measures</Select.Option>
                {resultActionMeasure.data?.data?.actionMeasureList?.map((item, key) => (
                  <Select.Option key={key} value={item._id} label={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>

          <div className="divider-container">
            <h6 className="line-divider ">
              <span className="span-line-divider mt-2">Period</span>
            </h6>
          </div>
          <div className="d-flex gap-2 mt-3">
            <div className="w-50">
              <p className="mb-2 fs-12p">Start Date</p>
              <DatePicker
                value={actionPostData.startDate && dayjs(actionPostData.startDate, "DD-MM-YYYY")}
                format={"DD-MM-YYYY"}
                onChange={(val) => handleYearChange("startDate", val)}
                size="large"
                placeholder="dd-mm-yyyy"
                className="w-100"
              />
            </div>
            <div className="w-50">
              <p className="mb-2 fs-12p">End Date</p>
              <DatePicker
                value={actionPostData.endDate && dayjs(actionPostData.endDate, "DD-MM-YYYY")}
                format={"DD-MM-YYYY"}
                onChange={(val) => handleYearChange("endDate", val)}
                size="large"
                placeholder="dd-mm-yyyy"
                className="w-100"
              />
            </div>
          </div>
          <div className="divider-container">
            <h6 className="line-divider ">
              <span className="span-line-divider mt-2 fs-12p">Target</span>
            </h6>
          </div>
          <div className="d-flex gap-2">
            <div className="flex-fill">
              <p className="mb-2 fs-12p">
                Targeted Value <sup>*</sup>
              </p>
              <input
                type="text"
                className="form-control"
                placeholder="Target"
                aria-label="Target"
                name="targetedValue"
                value={actionPostData.targetedValue}
                onChange={handleActionInputChange}
              />
            </div>
            <div className="flex-fill">
              <div className="d-flex gap-2">
                <p className="mb-2 fs-12p">
                  Unit <sup>*</sup>
                </p>
                <div
                  data-tooltip-id={"modal-info-unit"}
                  data-tooltip-html={`<span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Completion year for the simulation<br/></span><span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word"><br/></span><span style="color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">End year may or may not be the same as any of your target years.</span>`}
                >
                  <InfoOutlinedIcon />
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Unit"
                aria-label="Unit"
                name="unit"
                value={actionPostData.unit}
                onChange={handleActionInputChange}
              />
            </div>
            <div className="flex-fill">
              <div className="d-flex gap-2">
                <p className="mb-2 fs-12p">
                  Current Value <sup>*</sup>
                </p>
                <div
                  data-tooltip-id={"modal-info-current"}
                  data-tooltip-html={`<span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Completion year for the simulation<br/></span><span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word"><br/></span><span style="color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">End year may or may not be the same as any of your target years.</span>`}
                >
                  <InfoOutlinedIcon />
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Current"
                aria-label="Current"
                name="currentValue"
                value={actionPostData.currentValue}
                onChange={handleActionInputChange}
              />
            </div>
          </div>
          <div className="divider-container">
            <h6 className="line-divider ">
              <span className="span-line-divider mt-2">Budget</span>
            </h6>
          </div>
          <div className="d-flex gap-2 mt-3">
            <div className="w-50">
              <p className="mb-2 fs-12p">
                Budget <sup>*</sup>
              </p>
              <input
                type="text"
                className="form-control"
                placeholder="Budget"
                aria-label="Budget"
                name="budget"
                value={actionPostData.budget}
                onChange={handleActionInputChange}
              />
            </div>
            <div className="w-50">
              <p className="mb-2 fs-12p">Currency</p>
              <Select
                value={actionPostData.currency}
                placeholder="Currency"
                optionFilterProp="currency"
                size="large"
                onChange={(val) => handleModalSelectFilterChange("currency", val)}
                className="w-100"
              >
                {currData.data?.data.map((item, key) => (
                  <Select.Option key={key} value={item._id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="button-container">
            <button type="button" className="btn btn-outline-success" onClick={() => setActionModalEdit(false)}>
              Cancel
            </button>
            <button type="button" className="btn btn-success" onClick={updateAction}>
              Update Action
            </button>
          </div>
        </div>
      </CustomModal>
      <CustomModal modalOpen={modalOpen} setModalOpen={setModalOpen} outSideClose={false}>
        <div className="main-modal-container">
          <div className="modal-container-wide">
            <p style={{ fontSize: "24px", fontWeight: "bold" }}>Create new action</p>
          </div>

          <div className="main-image-container mt-4">
            <div>
              <div className="image-container-div-fac">
                <div className="upload-image-div">
                  <img
                    src={logoReader ? logoReader : "/images/add-org-logo.png"}
                    alt="org-logo"
                    width="100%"
                    height="100%"
                    objectfit="cover"
                  />
                </div>
                {logoReader ? (
                  <div className="add-image-icon" onClick={handleButtonClickOrg}>
                    +
                  </div>
                ) : (
                  <div className="add-image-icon" onClick={handleButtonClickOrg}>
                    +
                  </div>
                )}
              </div>
              <input
                type="file"
                accept="image/*"
                ref={fileInputRefOrg}
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </div>
            <div className="flex-fill fs-12p">
              <div>
                <p className="labelTextModal mb-2">Action Name</p>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Action Name"
                    aria-label="Action Name"
                    name="name"
                    value={bodyValues.name}
                    onChange={handleModalFieldChange}
                  />
                </div>
              </div>
              <div>
                <p className="labelTextModal my-2">Description</p>
                <div className="custom-textfield">
                  <textarea
                    id="foreword"
                    rows="3"
                    placeholder="Description"
                    name="description"
                    value={bodyValues.description}
                    onChange={handleModalFieldChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="divider-container">
            <h6 className="line-divider ">
              <span className="span-line-divider mt-2">Category</span>
            </h6>
          </div>
          <div className="d-flex flex-wrap gap-2">
            {catArr.map((item) => (
              <div
                className={`category-tags cursor-pointer ${bodyValues.category.indexOf(item) !== -1 ? "bg-sb" : ""}`}
                onClick={() => addRemoveCategory(item)}
              >
                <p>{item}</p>
              </div>
            ))}
          </div>
          <div className="d-flex mt-4 gap-5">
            <div className="border-impact">
              <div className="mb-3">
                <div className="d-flex gap-2 align-items-center">
                  <p className="fw-bold">Reduction Potential</p>
                  <div
                    data-tooltip-id={"modal-info-reductionPotential"}
                    data-tooltip-html={`<span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Completion year for the simulation<br/></span><span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word"><br/></span><span style="color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">End year may or may not be the same as any of your target years.</span>`}
                  >
                    <InfoOutlinedIcon />
                  </div>
                </div>
                <div className="selection-tabs-modal mt-2">
                  <div
                    onClick={() => handleButtonClick("Low", "reductionPotential")}
                    className={`tab-select ${tabSelected.reductionPotential.Low ? "active-tab-red" : ""}`}
                  >
                    <p>Low</p>
                  </div>
                  <div
                    onClick={() => handleButtonClick("Medium", "reductionPotential")}
                    className={`tab-select ${tabSelected.reductionPotential.Medium ? "active-tab-yellow" : ""}`}
                  >
                    <p>Medium</p>
                  </div>
                  <div
                    onClick={() => handleButtonClick("High", "reductionPotential")}
                    className={`tab-select ${tabSelected.reductionPotential.High ? "active-tab-green" : ""}`}
                  >
                    <p>High</p>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="d-flex gap-2 align-items-center">
                  <p className="fw-bold">Budget</p>
                  <div
                    data-tooltip-id={"modal-info-budget"}
                    data-tooltip-html={`<span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Completion year for the simulation<br/></span><span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word"><br/></span><span style="color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">End year may or may not be the same as any of your target years.</span>`}
                  >
                    <InfoOutlinedIcon />
                  </div>
                </div>
                <div className="selection-tabs-modal mt-2">
                  <div
                    onClick={() => handleButtonClick("Low", "budget")}
                    className={`tab-select ${tabSelected.budget.Low ? "active-tab-red" : ""}`}
                  >
                    <p>Low</p>
                  </div>
                  <div
                    onClick={() => handleButtonClick("Medium", "budget")}
                    className={`tab-select ${tabSelected.budget.Medium ? "active-tab-yellow" : ""}`}
                  >
                    <p>Medium</p>
                  </div>
                  <div
                    onClick={() => handleButtonClick("High", "budget")}
                    className={`tab-select ${tabSelected.budget.High ? "active-tab-green" : ""}`}
                  >
                    <p>High</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex gap-2 align-items-center">
                  <p className="fw-bold">Complexity</p>
                  <div
                    data-tooltip-id={"modal-info-complexity"}
                    data-tooltip-html={`<span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Completion year for the simulation<br/></span><span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word"><br/></span><span style="color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">End year may or may not be the same as any of your target years.</span>`}
                  >
                    <InfoOutlinedIcon />
                  </div>
                </div>
                <div className="selection-tabs-modal mt-2">
                  <div
                    onClick={() => handleButtonClick("Low", "complexity")}
                    className={`tab-select ${tabSelected.complexity.Low ? "active-tab-red" : ""}`}
                  >
                    <p>Low</p>
                  </div>
                  <div
                    onClick={() => handleButtonClick("Medium", "complexity")}
                    className={`tab-select ${tabSelected.complexity.Medium ? "active-tab-yellow" : ""}`}
                  >
                    <p>Medium</p>
                  </div>
                  <div
                    onClick={() => handleButtonClick("High", "complexity")}
                    className={`tab-select ${tabSelected.complexity.High ? "active-tab-green" : ""}`}
                  >
                    <p>High</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className="d-flex gap-2 align-items-center">
                  <p className="fw-bold">Impact Level</p>
                  <div
                    data-tooltip-id={"modal-info-complexity"}
                    data-tooltip-html={`<span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Completion year for the simulation<br/></span><span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word"><br/></span><span style="color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">End year may or may not be the same as any of your target years.</span>`}
                  >
                    <InfoOutlinedIcon />
                  </div>
                </div>
                <div className="d-flex flex-column mt-2">
                  <Checkbox name="Employee" onChange={onCheckChange}>
                    Employee
                  </Checkbox>
                  <Checkbox name="Company" onChange={onCheckChange}>
                    Company
                  </Checkbox>
                </div>
              </div>
              <div className="mt-3">
                <div className="d-flex gap-2 align-items-center">
                  <p className="fw-bold">Action Visibility</p>
                  <div
                    data-tooltip-id={"modal-info-complexity"}
                    data-tooltip-html={`<span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Completion year for the simulation<br/></span><span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word"><br/></span><span style="color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">End year may or may not be the same as any of your target years.</span>`}
                  >
                    <InfoOutlinedIcon />
                  </div>
                </div>
                <div className="mt-2">
                  <Radio.Group onChange={onRadioChange} value={bodyValues.actionVisibility}>
                    <Radio value={"Public"}>Public</Radio>
                    <Radio value={"Private"}>Private</Radio>
                  </Radio.Group>
                </div>
              </div>
            </div>
          </div>

          <div className="button-container">
            <button type="button" className="btn btn-success" onClick={createActions}>
              Create Action
            </button>
            <button type="button" className="btn btn-outline-success" onClick={() => setModalOpen(false)}>
              Cancel
            </button>
          </div>
        </div>
      </CustomModal>
      <CustomModal modalOpen={confirmModal} setModalOpen={setConfirmModal} outSideClose={false}>
        <p>Are you sure you want to delete this action plan?</p>
        <div className="d-flex gap-3 mt-4">
          <button type="button" className="btn btn-outline-danger" onClick={() => setConfirmModal(false)}>
            Cancel
          </button>
          <button type="button" className="btn btn-danger" onClick={() => deleteActionAction()}>
            Delete
          </button>
        </div>
      </CustomModal>
      {
        <>
          <div className="d-flex justify-content-between align-items-center my-4">
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
            <div className="d-flex gap-4">
              <p className="fs-12p">
                Completed Actions:{" "}
                <span className=" fw-semibold">
                  {actionList?.actionActionList?.filter((item) => item.progress === 100)?.length}/
                  {actionList?.totalCount}
                </span>
              </p>
              <p className="fs-12p">
                Completion Rate: <span className="fw-semibold">{resultActionMeasureDetail.data?.data?.progress} %</span>
              </p>
            </div>
          </div>
          <div className="data-container-box">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <p className="box-title-name">Reduction Action</p>
              <div className="two-button-container">
                <button
                  className="btn btn-success create-new-button-widest"
                  onClick={() => navigate("/action/newactionplan/newactionmeasure/actioncatalogue")}
                >
                  + Add from actions catalogue
                </button>
                <button onClick={() => openModal()} className="btn btn-outline-success create-new-button-wide">
                  + Create New Action
                </button>
              </div>
            </div>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {tableMeasureReductionHeaders?.map((key, index) => (
                    <TableCell className="fw-bold text-start">{key}</TableCell>
                  ))}
                  <TableCell className="fw-bold text-start">ACTIONS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {actionList?.actionActionList?.length > 0 ? (
                  actionList?.actionActionList?.map((item, index) => (
                    <TableRow>
                      <TableCell className="text-start">
                        <p
                          className="clickable-text"
                          onClick={() =>
                            navigate("/action/cataloguedetail", { state: { templateId: item.actionTemplate._id } })
                          }
                        >
                          {item.actionTemplate.name}
                        </p>
                      </TableCell>
                      <TableCell className="text-start">{item.budget}</TableCell>
                      <TableCell className="text-start">{dayjs(item.startDate).format("DD-MM-YYYY")}</TableCell>
                      <TableCell className="text-start">{dayjs(item.endDate).format("DD-MM-YYYY")}</TableCell>
                      <TableCell className="text-start">
                        <div className="d-flex gap-2 align-items-center justify-content-start">
                          <div className="progress-container-completion">
                            <div className="progress-bar" style={{ width: `${item.progress}%` }}></div>
                          </div>
                          <p className="completion-text">{item.progress} %</p>
                        </div>
                      </TableCell>
                      <TableCell className="text-start">{item.assignee?.name}</TableCell>
                      <TableCell>
                        <div className="d-flex gap-2">
                          <div className="cursor-pointer">
                            <img src={"/images/icons/edit.svg"} alt="edit" onClick={() => openEditModal(item)} />
                          </div>
                          <div className="cursor-pointer">
                            <img
                              src={"/images/icons/delete-icon.svg"}
                              alt="delete"
                              onClick={() => deleteAction(item._id)}
                            />
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <div className="d-flex justify-content-center align-items-center h-300p">No measure added</div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <div className="mt-auto">
              <TablePagination
                rowsPerPageOptions={false}
                component="div"
                count={resultActionAction.data?.data?.totalCount}
                rowsPerPage={postPerPage}
                page={pg}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      }
    </>
  );
};

export default NewActionMeasure;
