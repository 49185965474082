export const kleanEndpoints = (build) => ({
  getKleanSolutions: build.query({
    query: (arg) => ({
      url: `klean/list`,
      params: { ...arg }
    }),
    providesTags: ["Kleansolutions"]
  }),

  getKleanInterests: build.query({
    query: (arg) => ({
      url: `klean/interests`,
      params: { ...arg }
    }),
    providesTags: ["Kleaninterests"]
  }),

  getKleanFilters: build.query({
    query: () => ({
      url: `klean/filter`
    }),
    providesTags: ["KleanFilters"]
  }),
  postKleanInterest: build.mutation({
    query: (postBody) => ({
      url: `klean`,
      method: "POST",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: ["Kleaninterests"]
  })
});
