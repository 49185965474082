import { createSlice } from "@reduxjs/toolkit";

const initialEsgTabState = {
  tabWiseValueGlobal: {},
  tabs: []
};

export const esgTabSlice = createSlice({
  name: "esgTabs",
  initialState: initialEsgTabState,
  reducers: {
    setTabWiseValueGlobal: (state, action) => {
      return {
        ...state,
        tabWiseValueGlobal: action.payload
      };
    },
    setTabs: (state, action) => {
      return {
        ...state,
        tabs: action.payload
      };
    }
  }
});

export const { setTabWiseValueGlobal, setTabs } = esgTabSlice.actions;

export default esgTabSlice.reducer;
