export const reportEndpoints = (build) => ({
  getDownloadReport: build.query({
    query: (arg) => ({
      url: `/report/generate/ghg`,
      params: { ...arg }
    }),
    providesTags: ["Downloadreport"],
    invalidatesTags: ["ReportsFilters"]
  }),

  getReportType: build.query({
    query: () => ({
      url: `/report/type`
    }),
    providesTags: ["ReportCards"]
  }),

  getReportFilter: build.query({
    query: (arg) => ({
      url: `/report/filter`,
      params: { ...arg }
    }),
    providesTags: ["ReportsFilters"]
  }),

  getReportDetail: build.query({
    query: (arg) => ({
      url: `/report/detail`,
      params: { ...arg }
    }),
    providesTags: ["DetailReport"]
  }),
  postUpdateAndSaveReport: build.mutation({
    query: (postBody) => ({
      url: `report/update`,
      method: "POST",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: ["DetailReport", "ReportsFilters"]
  }),

  postFilteredReport: build.mutation({
    query: (postBody) => ({
      url: `report`,
      method: "POST",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: []
  }),
});
