import { Button, Divider, Progress, Select, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import "./EsgDataQuality.scss";
import CustomDrawer from "../../../../components/CustomeDrawer/CustomDrawer";
import {
  useLazyGetUserDetailByIdQuery,
  useLazyGetDataQualityQuery,
  useLazyGetEsgFilterDataQuery
} from "../../../../state/api";
import { useSelector } from "react-redux";
import { Pagination, TextField, InputAdornment } from "@mui/material";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";
import WidgetLoader from "../../../../components/Loaders/WidgetLoader";
import { CloseOutlined } from "@ant-design/icons";
import SearchIcon from "@mui/icons-material/Search";

const labelStyle = {
  top: "-6"
};

export const EsgDataQuality = ({
  drawerOpen,
  toggleDrawer,
  tabValue,
  onSearchValue,
  triggerSearch,
  onSearchCompleted
}) => {
  const navigate = useNavigate();

  const userId = useSelector((state) => state.global.user);
  const [user, setUser] = useState(null);
  const [getUser, userResult] = useLazyGetUserDetailByIdQuery();

  const postPerPage = 10;
  const [currentPage, SetCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [getDataQuality, resultDataQuality] = useLazyGetDataQualityQuery();

  const [getEsgFilterData, resultEsgFilterData] = useLazyGetEsgFilterDataQuery();

  const [kpiFilterData, setkpiFilterData] = useState();

  const [activeFilters, setActiveFilters] = useState({ KPI: "", facility: "" });
  const [isLoading, setIsLoading] = useState(false);

  const [filtersApplied, setFiltersApplied] = useState(false);

  const [pageNo, setpageNo] = useState(1);

  useEffect(() => {
    getUser({ id: userId._id }, true);
  }, []);

  useEffect(() => {
    if (userResult.data?.data) {
      setUser(userResult.data?.data);
    }
  }, [userResult.data?.data]);

  useEffect(() => {
    if (resultDataQuality?.data?.data) {
      setTotalCount(resultDataQuality?.data?.data?.totalData);
    }
  }, [currentPage, resultDataQuality?.data?.data]);

  useEffect(() => {
    if (triggerSearch) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const body = {
            esg: tabValue === "2" ? "Social" : "Governance",
            from: `${parseInt(1) - 1 < 0 ? 0 : parseInt(1) - 1}`,
            size: postPerPage,
            ...(onSearchValue && { search: onSearchValue })
          };
          await getDataQuality(body, true);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
        onSearchCompleted();
      };

      fetchData();
    }
  }, [onSearchValue, triggerSearch, onSearchCompleted]);

  useEffect(() => {
    setIsLoading(true);
    SetCurrentPage(0);
    setpageNo(1);
    try {
      getDataQuality(
        {
          esg: `${tabValue === "2" ? "Social" : "Governance"}`,
          from: `${parseInt(1) - 1 < 0 ? 0 : parseInt(1) - 1}`,
          size: postPerPage
        },
       
      );
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [tabValue]);

  useEffect(() => {
    getEsgFilterData({ esg: `${tabValue === "2" ? "social" : "govt"}` }, true);
  }, [tabValue]);

  useEffect(() => {
    if (resultEsgFilterData?.data?.data) {
      setkpiFilterData(resultEsgFilterData?.data?.data);
    }
  }, [resultEsgFilterData?.data?.data]);

  const getPayLoad = (pageNo) => {
    let body = {
      esg: `${tabValue === "2" ? "Social" : "Governance"}`,
      from: `${parseInt(pageNo) - 1 < 0 ? 0 : parseInt(pageNo) - 1}`,
      size: postPerPage
    };

    return body;
  };

  const handlePageChange = async (e, value) => {
    if (value - 1 < 0) {
      SetCurrentPage(0);
    } else {
      SetCurrentPage((value - 1) * postPerPage);
    }
    setpageNo(value);
    try {
      if (value - 1 < 0) {
        await getDataQuality(getPayLoad(0), true);
      } else {
        await getDataQuality(getPayLoad((value - 1) * postPerPage), true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBack = () => {
    if (tabValue === "2") {
      navigate("/social");
    } else {
      navigate("/governance");
    }
  };

  const gotoBreakDown = (item, index) => {
    // console.log("data when view details", item, index);
    navigate("breakdown", { state: { data: item, tab: tabValue } });
  };

  const handleChange = (e, newValue) => {
    const { name, value } = e.target;
    let updatedFilters = { ...activeFilters };

    switch (e.target.name) {
      case "facility":
        updatedFilters[name] = value;
        break;

      case "KPI":
        updatedFilters[name] = value;
        break;

      case "Uploadedby":
        updatedFilters[name] = value;
        break;

      default:
        break;
    }
    setActiveFilters(updatedFilters);
  };

  const handleReset = async () => {
    toggleDrawer();
    setIsLoading(true);

    try {
      await getDataQuality(
        {
          esg: `${tabValue === "2" ? "Social" : "Governance"}`,
          from: `${parseInt(1) - 1 < 0 ? 0 : parseInt(1) - 1}`,
          size: postPerPage
        },
        true
      );
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }

    setActiveFilters({
      KPI: "",
      facility: ""
    });
  };

  const handleApplyFilter = async () => {
    toggleDrawer();
    setIsLoading(true);

    let body = {
      esg: `${tabValue === "2" ? "Social" : "Governance"}`,
      facility: activeFilters.facility,
      kpi: activeFilters.KPI,
      from: `${parseInt(pageNo) - 1 < 0 ? 0 : parseInt(pageNo) - 1}`,
      size: postPerPage
    };

    setFiltersApplied(true);

    try {
      await getDataQuality(body);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
    // console.log(" apply filter", activeFilters);
  };

  const removeFilter = async (filterName) => {
    const defaultFilters = {
      KPI: "",
      facility: ""
    };
    const updatedFilters = { ...activeFilters };
    if (defaultFilters.hasOwnProperty(filterName)) {
      updatedFilters[filterName] = defaultFilters[filterName];
    } else {
      delete updatedFilters[filterName];
    }
    setActiveFilters(updatedFilters);

    let body = {
      esg: `${tabValue === "2" ? "Social" : "Governance"}`,
      facility: updatedFilters.facility,
      kpi: updatedFilters.KPI,
      from: `${parseInt(pageNo) - 1 < 0 ? 0 : parseInt(pageNo) - 1}`,
      size: postPerPage
    };

    const allFiltersEmpty = Object.values(updatedFilters).every((value) => value === "" || value === undefined);

    if (allFiltersEmpty) {
      getDataQuality(
        {
          esg: `${tabValue === "2" ? "Social" : "Governance"}`,
          from: `${parseInt(1) - 1 < 0 ? 0 : parseInt(1) - 1}`,
          size: postPerPage
        },
        true
      );
      setFiltersApplied(false);
    } else if (filtersApplied) {
      setIsLoading(true);
      try {
        await getDataQuality(body);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  };

  const getFacilityName = (id) => {
    const facility = user?.facilities?.find((f) => f._id === id);
    return facility ? facility.name : id;
  };

  const getKpiName = (id) => {
    const kpiName = kpiFilterData?.find((f) => f._id === id);
    return kpiName ? kpiName.heading : id;
  };

  return (
    <>
      <div style={{ position: "relative" }} className="esg-filter-wrapper">
        <CustomDrawer open={drawerOpen} onClose={toggleDrawer}>
          <p className="filter-text">Filters</p>
          <div className="filter-container">
            <div className="allfilter-container">
              <p className="dropdown-lebel">Facility</p>
              <Select
                showSearch
                value={activeFilters["facility"]}
                placeholder="Select a facility"
                name="facility"
                optionFilterProp="facility"
                size="large"
                onChange={(value) => handleChange({ target: { value, name: "facility" } })}
                style={{ width: "340px" }}
              >
                <Select.Option value="">All facility</Select.Option>
                {user?.facilities?.map((item, ind) => (
                  <Select.Option key={ind} value={item._id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
              <Divider />
              <div>
                <p className="dropdown-lebel">KPI</p>
                <Select
                  showSearch
                  value={activeFilters["KPI"]}
                  placeholder="Select a KPI"
                  name="KPI"
                  optionFilterProp="KPI"
                  size="large"
                  onChange={(value) => handleChange({ target: { value, name: "KPI" } })}
                  style={{ width: "340px" }}
                >
                  <Select.Option value="">All KPI</Select.Option>
                  {kpiFilterData?.map((item, ind) => (
                    <Select.Option key={ind} value={item._id}>
                      {item.heading}
                    </Select.Option>
                  ))}
                </Select>
              </div>

              {/* <Divider />
              <div>
                <p className="dropdown-lebel">Uploaded by</p>
                <Select
                  showSearch
                  value={activeFilters["Uploadedby"]}
                  placeholder="Select Uploaded by"
                  name="Uploadedby"
                  optionFilterProp="Uploaded by"
                  size="large"
                  onChange={(value) => handleChange({ target: { value, name: "Uploadedby" } })}
                  style={{ width: "340px" }}
                >
                  <Select.Option value="">All Uploaded by</Select.Option>
                  {user?.facilities?.map((item, ind) => (
                    <Select.Option key={ind} value={item._id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </div> */}
            </div>
            <div className="button-container">
              <Button type="primary" ghost className="text-black" onClick={handleReset}>
                Reset
              </Button>

              <Button type="primary" size="default" style={{ width: "120px" }} onClick={handleApplyFilter}>
                Apply
              </Button>
            </div>
          </div>
        </CustomDrawer>
      </div>

      <div className="selected-filter">
        {Object.keys(activeFilters).map((filterName) => {
          const filterValue = activeFilters[filterName];
          let displayValue;

          switch (filterName) {
            case "facility":
              displayValue = getFacilityName(filterValue);
              break;
            case "KPI":
              displayValue = getKpiName(filterValue);
              break;

            default:
              displayValue = filterValue;
              break;
          }

          return (
            <>
              {displayValue ? (
                <div key={filterName} className="filter-chip">
                  <span style={{ fontSize: "12px", fontWeight: "normal" }}>{displayValue}</span>

                  <CloseOutlined onClick={() => removeFilter(filterName)} />
                </div>
              ) : (
                ""
              )}
            </>
          );
        })}
      </div>
      {resultDataQuality.isLoading || isLoading ? (
        <div className="empty-wrapper">
          <WidgetLoader />
        </div>
      ) : resultDataQuality?.data?.data?.data.length === 0 ? (
        <div className="empty-wrapper">
          <div className="empty-container ">
            <img src="/empty-box.svg" alt="EmptyImage" style={{ width: "236px" }} />
            <p>No existing data entries to show. Please add data.</p>
            <button type="button" style={{ width: "176px" }} onClick={handleBack} className="btn btn-success">
              + Add new data
            </button>
          </div>
        </div>
      ) : (
        resultDataQuality?.data?.data?.data &&
        resultDataQuality?.data?.data?.data.length > 0 && (
          <div className="mainEnv-wrapper">
            <div className="esg-table">
              <table>
                <thead>
                  <tr>
                    <th>Facility</th>
                    <th>KPI</th>
                    {Object.keys(resultDataQuality?.data?.data?.data[0]?.years).map((year) => (
                      <th key={year}>{year}</th>
                    ))}
                    {/* <th>View Details</th> */}
                  </tr>
                </thead>
                <tbody>
                  {resultDataQuality?.data?.data?.data.map((item, index) => (
                    <tr key={item + "quality"}>
                      <td>{item?.facility?.name}</td>
                      <td>{item.kpi}</td>
                      {Object.entries(item?.years).map(([key, value]) => (
                        <td key={key} className="year-tabledata">
                          {value === true ? (
                            <img src={"/images/icons/green-tick-circle.svg"} alt="icon" />
                          ) : (
                            <img src={"/images/icons/red-cross-box.svg"} alt="icon" />
                          )}
                        </td>
                      ))}

                      {/* <td style={{textAlign:"center"}}>
                        <img
                          src={"/images/icons/right-arrow.svg"}
                          className="cursor-pointer"
                          alt="icon"
                          onClick={() => gotoBreakDown(item, index)}
                        />
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )
      )}
      <div className="mt-auto py-4">
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(totalCount / postPerPage)}
            shape="rounded"
            onChange={handlePageChange}
            page={pageNo}
          />
        </Stack>
      </div>
    </>
  );
};
