import React, { useState, useEffect } from "react";
import "./DataQuality.scss";
import { Tab, Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { EsgDataQuality } from "./EsgDataQuality/EsgDataQuality";
import { EnvDataQuality } from "./EnvironmentDataQuality/EnvDataQuality";
import { Button, Input } from "antd";
import { useLazyGetUserDetailByIdQuery } from "../../../state/api";
import { useSelector } from "react-redux";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
const useStyles = makeStyles({
  tab: {
    marginRight: 40
  }
});
export const DataQuality = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [tabValue, setTabValue] = useState("1");

  const userId = useSelector((state) => state.global.user);
  const [user, setUser] = useState(null);
  const [getUser, userResult] = useLazyGetUserDetailByIdQuery();

  const [onSearchValue, setonSearchValue] = useState();
  const [triggerSearch, setTriggerSearch] = useState(false);

  useEffect(() => {
    getUser({ id: userId._id }, true);
  }, []);

  useEffect(() => {
    if (userResult.data?.data) {
      setUser(userResult.data?.data);
    }
  }, [userResult.data?.data]);

  const handleTabsChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleChange = (e) => {
    setonSearchValue(e.target.value);
    if (e.target.value === "") {
      setTriggerSearch(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setTriggerSearch(true);
    }
  };

  const handleClear = (e) => {
    // console.log("clear function" , onSearchValue)
    setonSearchValue("") 
    setTriggerSearch(true); 
  };

  return (
    <>
      
      <div className="screen-heading">
        <p>Data Quality</p>
      </div>
      <div>
        <Tabs
          value={tabValue}
          onChange={handleTabsChange}
          aria-label="wrapped label tabs example"
          indicatorColor="#64B5F6"
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab value="1" label="Environment" />
          <Tab value="2" label="Social" />
          <Tab value="3" label="Governance" />
        </Tabs>
        <div className="searchFilter">
        <Input
          size="large"
          value={onSearchValue}
          placeholder={`Search`}
          prefix={<img src="/images/icons/search-icon.svg" alt="icon" />}
          suffix={
            onSearchValue && (
              <CloseCircleOutlined
                onClick={handleClear}
                style={{ cursor: "pointer", color: "#1890ff" }}
              />
            )
          }
          style={{ width: "254px", height: "34px" }}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />
        <Button type="primary" size="default" style={{ width: "120px" }} onClick={toggleDrawer}>
          Apply Filters
        </Button>
      </div>
      </div>

      <div className="dataquality-wrapper">
        <div className="bodycontianerStyel">
          <div className="tabwrapper">
            {tabValue === "1" ? (
              <EnvDataQuality
                drawerOpen={drawerOpen}
                toggleDrawer={toggleDrawer}
                onSearchValue={onSearchValue}
                triggerSearch={triggerSearch}
                tabValue={tabValue} 
                onSearchCompleted={() => setTriggerSearch(false)}
              />
            ) : (
              <EsgDataQuality drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} tabValue={tabValue} 
              onSearchValue={onSearchValue}
              triggerSearch={triggerSearch}
              onSearchCompleted={() => setTriggerSearch(false)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
