import React from 'react'
import './CustomTabs.scss'

const CustomTabs = ({ title, image, value, closeTab, activeTab, handleTabChange }) => {

	return (
		<div key={value} name={value} className={`tab-container${activeTab === value ? "" : " inactive"}`}>
			<div onClick={() => handleTabChange(value)} className='tab-item-container'>
				<img src={image} alt="img" className='rounded-circle' />
				<p className='titlePara'>{title}</p>
			</div>
			{title !== "Air Emission" &&
				<div onClick={(e) => closeTab(value)}>
					<img src={"/images/icons/close-tab.svg"} alt="close" />
				</div>
				}
		</div>
	)
}

export default CustomTabs