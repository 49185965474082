import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "../../../../../components/Accordion/Accordion";
import "../../../../Reports/BRSR/Brsr.scss";
import "../../../Social/Social.scss";
import { setFormWiseData } from "../../../../../state/govtSlice";
import "../../Governance.scss";
import { numberRegex } from "../../../../../helper/Rejex";

export const StructureComposition = ({ formEditable, editMode }) => {
  const dispatch = useDispatch();
  const { formPageWiseData } = useSelector((state) => state.governance);

  let govtAllData = JSON.parse(JSON.stringify(formPageWiseData));

  const handleGovernanceStructureDetailsChange = (e, index) => {
    govtAllData.governance.governanceStructureComposition.governanceStructureDetails[index][e.target.name] = e.target.value;
    dispatch(setFormWiseData(govtAllData));
  };

  const handleCompositionTableDetailChange = (e, index) => {
    const { name, value } = e.target;
    if (name === "age") {
      if (numberRegex.test(value) || value === "") {
        govtAllData.governance.compositionDetails.compositionTableDetail[index][e.target.name] = e.target.value;
      }
    } else {
      govtAllData.governance.compositionDetails.compositionTableDetail[index][e.target.name] = e.target.value;
    }
    dispatch(setFormWiseData(govtAllData));
  };

  return (
    <div className="esg-table-content">
      <div className="form-bullet-points-div ">
        <Accordion title={"Details about the Governance structure and its Composition"} index={0}>
          {govtAllData?.governance?.governanceStructureComposition?.governanceStructureDetails?.map((item, index) => (
            <div className="foreword-container mb-4 mt-1" key={item + index}>
              <div className="form-textfield-container px-4 pb-4">
                <p className="form-bullet-points">{item.heading}</p>
                <div className="custom-textfield ">
                  <textarea
                    rows="1"
                    placeholder="Write your foreword here."
                    value={item.forewordValue}
                    name="forewordValue"
                    onChange={(e) => handleGovernanceStructureDetailsChange(e, index)}
                    className="no-style-input w-100"
                    disabled={editMode && !formEditable}
                  />
                </div>
              </div>
            </div>
          ))}
        </Accordion>
      </div>

      <div className="form-bullet-points-div ">
        <Accordion title={"Details of the Composition of the highest governance body"} index={1}>
          <div className="table-scrollable mt-1 govt-struct-table">
            <table className="form-table mb-4 w-auto">
              <thead>
                <tr>
                  <th scope="col">Committee Member</th>
                  <th scope="col">Executive/non-Executive</th>
                  <th scope="col">Gender</th>
                  <th scope="col">Age</th>
                  <th scope="col">Conflict of Interest</th>
                  <th scope="col">Tenure of Member</th>
                  <th scope="col">Number of significant positions held</th>
                  <th scope="col">Nature of commitment</th>
                  <th scope="col">Competencies relevant to the impacts of the organization</th>
                  <th scope="col">Stakeholder representation</th>
                  <th scope="col">Any other indicator</th>
                </tr>
              </thead>
              <tbody>
                {govtAllData?.governance?.compositionDetails?.compositionTableDetail?.map((item, index) => (
                  <tr key={index + item}>
                    <td>
                      <input
                        type="text"
                        name="committeeMember"
                        value={item.committeeMember}
                        onChange={(e) => handleCompositionTableDetailChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1 w-unset" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="executiveNonExecutive"
                        value={item.executiveNonExecutive}
                        onChange={(e) => handleCompositionTableDetailChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="gender"
                        value={item.gender}
                        onChange={(e) => handleCompositionTableDetailChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="age"
                        value={item.age}
                        onChange={(e) => handleCompositionTableDetailChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="conflictOfInteres"
                        value={item.conflictOfInteres}
                        onChange={(e) => handleCompositionTableDetailChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="tenureOfMember"
                        value={item.tenureOfMember}
                        onChange={(e) => handleCompositionTableDetailChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="numberOfSignificantPositions"
                        value={item.numberOfSignificantPositions}
                        onChange={(e) => handleCompositionTableDetailChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="natureOfCommitment"
                        value={item.natureOfCommitment}
                        onChange={(e) => handleCompositionTableDetailChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="competenciesRelevant"
                        value={item.competenciesRelevant}
                        onChange={(e) => handleCompositionTableDetailChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="stakeholderRepresentation"
                        value={item.stakeholderRepresentation}
                        onChange={(e) => handleCompositionTableDetailChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="anyOtherIndicator"
                        value={item.anyOtherIndicator}
                        onChange={(e) => handleCompositionTableDetailChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Accordion>
      </div>
    </div>
  );
};
