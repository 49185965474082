import React from "react";
import Accordion from "../../../../../components/Accordion/Accordion";
import { useDispatch, useSelector } from "react-redux";
import "../../Social.scss";
import { setPageWiseData } from "../../../../../state/SocialSlice";
import { numberRegex } from "../../../../../helper/Rejex";

const HumanRightsAssessment = ({ formEditable, editMode }) => {
  const { pageWiseData } = useSelector((state) => state.social);

  const dispatch = useDispatch();

  let socialAllData = JSON.parse(JSON.stringify(pageWiseData));

  const handleImpactAssessmentChange = (e, index) => {
    const { name, value } = e.target;
    if (numberRegex.test(value) || value === "") {
      if (name === "percentage") {
        if (value <= 100) {
          socialAllData.humanRightsAssessment.operations.impactAssessment[index][name] = value;
        }
      } else {
        socialAllData.humanRightsAssessment.operations.impactAssessment[index][name] = value;
      }
    }

    dispatch(setPageWiseData(socialAllData));
  };

  const handleEmployeeTrainingChange = (e, index) => {
    const { name, value } = e.target;
    if (name === "totalNumberOfHours") {
      if (numberRegex.test(value) || value === "") {
        socialAllData.humanRightsAssessment.procedure.employeeTraining[index][name] = value;
      }
    } else {
      socialAllData.humanRightsAssessment.procedure.employeeTraining[index][name] = value;
    }
    dispatch(setPageWiseData(socialAllData));
  };

  const addRowEmployeeTraining = () => {
    let productRowObj = {
      humanRightsAspects: "",
      totalNumberOfHours: ""
    };
    socialAllData.humanRightsAssessment.procedure.employeeTraining.push(productRowObj);
    dispatch(setPageWiseData(socialAllData));
  };

  const handleEmployeestrainedHumanRightsChange = (e, index) => {
    const { name, value } = e.target;
    if (numberRegex.test(value) || value === "") {
      if (name === "percentage") {
        if (value <= 100) {
          socialAllData.humanRightsAssessment.procedure.employeestrainedHumanRights[index][name] = value;
        }
      } else {
        socialAllData.humanRightsAssessment.procedure.employeestrainedHumanRights[index][name] = value;
      }
    }

    dispatch(setPageWiseData(socialAllData));
  };

  const handleSignificantInvestmentChange = (e, index) => {
    const { name, value } = e.target;
    if(name === "agreementsAndContracts"){
      socialAllData.humanRightsAssessment.rightsScreening.significantInvestment[index][name] = value;

    }else{
      if (numberRegex.test(value) || value === "") {
        if (name === "percentage") {
          if (value <= 100) {
            socialAllData.humanRightsAssessment.rightsScreening.significantInvestment[index][name] = value;
          }
        } else {
          socialAllData.humanRightsAssessment.rightsScreening.significantInvestment[index][name] = value;
        }
      }
    }
   
    dispatch(setPageWiseData(socialAllData));
  };

  const addRowSignificantInvestment = () => {
    let productRowObj = {
      agreementsAndContracts: "",
      totalNumberOfAgreements: "",
      percentage: ""
    };
    socialAllData.humanRightsAssessment.rightsScreening.significantInvestment.push(productRowObj);
    dispatch(setPageWiseData(socialAllData));
  };

  const handleSpecifyTheDefinitionChange = (e) => {
    socialAllData.humanRightsAssessment.rightsScreening.specifyTheDefinition = e.target.value;
    dispatch(setPageWiseData(socialAllData));
  };

  // const deleteRow = (rowIndex, name) => {
  //   if (rowIndex > 0) {
  //     let tempArray = [...socialAllData.humanRightsAssessment[name]];
  //     tempArray.splice(rowIndex, 1);
  //     socialAllData.humanRightsAssessment[name] = tempArray;
  //     dispatch(setPageWiseData(socialAllData));
  //   }
  // };

  const deleteRow = (parentKey, arrayKey, rowIndex) => {
    const parentObject = socialAllData.humanRightsAssessment?.[parentKey];
    const dataArray = parentObject?.[arrayKey];
    if (Array.isArray(dataArray)) {
      if (rowIndex >= 0 && rowIndex < dataArray.length) {
        const updatedArray = dataArray.filter((_, index) => index !== rowIndex);

        const updatedParentObject = {
          ...parentObject,
          [arrayKey]: updatedArray
        };

        const updatedData = {
          ...socialAllData,
          humanRightsAssessment: {
            ...socialAllData.humanRightsAssessment,
            [parentKey]: updatedParentObject
          }
        };

        dispatch(setPageWiseData(updatedData));
      }
    }
  };

  return (
    <div className="esg-table-content">
      <div className="form-bullet-points-div ">
        <Accordion title={"Operations that have been subject to human rights reviews or impact assessment"} index={0}>
          <div>
            <table className="form-table mb-4 mt-1">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Number </th>
                  <th scope="col">Percentage</th>
                </tr>
              </thead>
              <tbody>
                {socialAllData?.humanRightsAssessment?.operations?.impactAssessment?.map((item, index) => (
                  <tr key={item + index}>
                    <td>{item.title}</td>
                    <td>
                      <input
                        type="text"
                        name="number"
                        value={item.number}
                        onChange={(e) => handleImpactAssessmentChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="percentage"
                        value={item.percentage}
                        onChange={(e) => handleImpactAssessmentChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Accordion>
      </div>

      <div className="form-bullet-points-div ">
        <Accordion title={"Employee Training on human rights policies or procedure"} index={1}>
          <div>
            <table className="form-table mt-1">
              <thead>
                <tr>
                  <th scope="col">Human rights aspects</th>
                  <th scope="col">Total number of hours</th>
                  <th className="col-md-1" scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {socialAllData?.humanRightsAssessment?.procedure?.employeeTraining?.map((item, index) => (
                  <tr key={item + index}>
                    <td>
                      <input
                        type="text"
                        name="humanRightsAspects"
                        value={item.humanRightsAspects}
                        onChange={(e) => handleEmployeeTrainingChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="totalNumberOfHours"
                        value={item.totalNumberOfHours}
                        onChange={(e) => handleEmployeeTrainingChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td className="small-width-columns">
                      {index !== 0 && (
                        <img
                          src="/images/icons/delete-icon.svg"
                          alt=""
                          onClick={() => deleteRow("procedure", "employeeTraining", index)}
                          className="cursor-pointer"
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button className="btn btn-outline-success add-new-row mb-4" onClick={() => addRowEmployeeTraining()}>
            <p>+ Add variable</p>
          </button>

          <div>
            <table className="form-table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Number</th>
                  <th scope="col">Percentage</th>
                </tr>
              </thead>
              <tbody>
                {socialAllData?.humanRightsAssessment?.procedure?.employeestrainedHumanRights?.map((item, index) => (
                  <tr key={item + index}>
                    <td>{item.title}</td>
                    <td>
                      <input
                        type="text"
                        name="number"
                        value={item.number}
                        onChange={(e) => handleEmployeestrainedHumanRightsChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="percentage"
                        value={item.percentage}
                        onChange={(e) => handleEmployeestrainedHumanRightsChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Accordion>
      </div>

      <div className="form-bullet-points-div mt-4">
        <Accordion
          title={
            "Significant investment agreements and contracts that include human rights clauses or that underwent human rights screening"
          }
          index={2}
        >
          <div>
            <table className="form-table mt-1">
              <thead>
                <tr>
                  <th scope="col">Significant investment agreements and contracts</th>
                  <th scope="col">Total number of agreements</th>
                  <th scope="col">Percentage</th>
                  <th className="col-md-1">Action</th>
                </tr>
              </thead>
              <tbody>
                {socialAllData?.humanRightsAssessment?.rightsScreening?.significantInvestment?.map((item, index) => (
                  <tr key={item + index}>
                    <td>
                      <input
                        type="text"
                        name="agreementsAndContracts"
                        value={item.agreementsAndContracts}
                        onChange={(e) => handleSignificantInvestmentChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="totalNumberOfAgreements"
                        value={item.totalNumberOfAgreements}
                        onChange={(e) => handleSignificantInvestmentChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="percentage"
                        value={item.percentage}
                        onChange={(e) => handleSignificantInvestmentChange(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td className="small-width-columns">
                      {index !== 0 && (
                        <img
                          src="/images/icons/delete-icon.svg"
                          alt=""
                          onClick={() => deleteRow("rightsScreening", "significantInvestment",index)}
                          className="cursor-pointer"
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button className="btn btn-outline-success add-new-row mb-4" onClick={() => addRowSignificantInvestment()}>
            <p>+ Add variable</p>
          </button>

          <div className="foreword-container">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">Specify, the definition used for 'significant investment agreements'</p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={socialAllData?.humanRightsAssessment?.rightsScreening?.specifyTheDefinition}
                  name="specifyTheDefinition"
                  onChange={(e) => handleSpecifyTheDefinitionChange(e)}
                  className="no-style-input w-100"
                  disabled={editMode && !formEditable}
                />
              </div>
            </div>
          </div>
        </Accordion>
      </div>
    </div>
  );
};

export default HumanRightsAssessment;
