export const handleFetchErrors = async (response) => {
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Something went wrong");
  }
  return response;
};

export const fetchData = async (url, options) => {
  try {
    const response = await fetch(url, options);
    const data = await handleFetchErrors(response);
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
