export const emptyPageWiseData = {
  Employment: {
    newEmployeeTurnover: {
      accordionTitle: "New Employee and Employee Turnover",
      newHireEmployee: [
        {
          title: "New employee hires",
          newHireEmployeCount: "",
          newHireEmployeRatio: ""
        }
      ],
      employeesTurnover: [
        {
          title: "Employees Turnover",
          newHireEmployeTurnOver: "",
          newHireEmployeTurnOverRatio: ""
        }
      ],
      voluntaryTurnover: [
        {
          title: "Voluntary Turnover",
          newHireEmployeTurnOver: "",
          newHireEmployeTurnOverRatio: ""
        }
      ],

      noteNewEmployeeGenderAgeRegion: "",
      employeeGenderWise: [
        {
          title: "Male",
          newHireEmployeCount: "",
          newHireEmployeePercentage: "",
          newHireEmployeRatio: ""
        },
        {
          title: "Female",
          newHireEmployeCount: "",
          newHireEmployeePercentage: "",
          newHireEmployeRatio: ""
        }
      ],
      employeeAgeWise: [
        {
          title: "Under 30 years old",
          newHireEmployeCount: "",
          newHireEmployeePercentage: "",
          newHireEmployeRatio: ""
        },
        {
          title: "30-50 years old",
          newHireEmployeCount: "",
          newHireEmployeePercentage: "",
          newHireEmployeRatio: ""
        },
        {
          title: "Over 50 years old",
          newHireEmployeCount: "",
          newHireEmployeePercentage: "",
          newHireEmployeRatio: ""
        }
      ],
      employeeRegionWise: [
        {
          title: "",
          newHireEmployeCount: "",
          newHireEmployeePercentage: "",
          newHireEmployeRatio: ""
        }
      ],

      noteEmployeeTornoverByGenderAgeRegion: "",

      employeeTurnoverGenderWise: [
        {
          title: "Male",
          newHireEmployeCount: "",
          newHireEmployeePercentage: "",
          newHireEmployeRatio: ""
        },
        {
          title: "Female",
          newHireEmployeCount: "",
          newHireEmployeePercentage: "",
          newHireEmployeRatio: ""
        }
      ],
      employeeTurnoverAgeWise: [
        {
          title: "Under 30 years old",
          newHireEmployeCount: "",
          newHireEmployeePercentage: "",
          newHireEmployeRatio: ""
        },
        {
          title: "30-50 years old",
          newHireEmployeCount: "",
          newHireEmployeePercentage: "",
          newHireEmployeRatio: ""
        },
        {
          title: "Over 50 years old",
          newHireEmployeCount: "",
          newHireEmployeePercentage: "",
          newHireEmployeRatio: ""
        }
      ],
      employeeTurnOverRegionWise: [
        {
          title: "Region",
          newHireEmployeCount: "",
          newHireEmployeePercentage: "",
          newHireEmployeRatio: ""
        }
      ]
    },

    benefitsProvided: {
      accordionTitle:
        "Benefits provided to full-time employees that are not provide to temporary or part-time employees",

      employeeByLocationOperation: [
        {
          location: "Location",
          numberOfFTEWithLifeInsurance: "",
          insuPercentage: "",
          numberOfFTEWithHealthInsurance: "",
          disabilityCoverage: "",
          coveragePercent: "",
          retirementProvision: "",
          provisionPercent: "",
          stockOwnership: "",
          ownerShipPercent: "",
          otherBenefits: ""
        }
      ],

      employeeBenefitsKind: [
        {
          location: "Location",
          sportsFacilities: "",
          careFacilities: "",
          workTtime: "",
          other: ""
        }
      ]
    },

    parentalLeave: {
      accordionTitle: "Parental Leave",

      employeeParentalLeave: [
        {
          title: "Male",
          totalNumberOfEmployeesEntitledPL: "",
          totalEmployeewhoTookPL: "",
          totalEmployeereturnToWorkAfterPL: "",
          workRatio: "",
          retentionRatio: ""
        },
        {
          title: "Female",
          totalNumberOfEmployeesEntitledPL: "",
          totalEmployeewhoTookPL: "",
          totalEmployeereturnToWorkAfterPL: ""
        },
        {
          title: "Total",
          totalNumberOfEmployeesEntitledPL: "",
          totalEmployeewhoTookPL: "",
          totalEmployeereturnToWorkAfterPL: ""
        }
      ]
    }
  },

  LaborManagementRelations: {
    noticePeriod: {
      accordionTitle: "Details about notice period",
      aboutNoticePeriod: [
        {
          title:
            "Minimum number of weeks' notice typically provided to employees and their representatives prior to the implementation of significant operational changes that could substantially affect them",
          yesNo: "",
          NumberOfWeeks: ""
        }
      ],
      collectiveAgreements: ""
    }
  },

  occupationalHealthSafety: {
    occupationalHealthSafetyMgmt: {
      accordionTitle: "Occupational Health and Safety Management",
      firstForeword: "",
      secondForeword: "",
      thirdForeword: ""
    },
    hazardIdentification: {
      accordionTitle: "Hazard Identification, risk assessment and incident investigation",
      firstForeword: "",
      secondForeword: "",
      thirdForeword: "",
      fourthForeword: ""
    },
    occupationalHealthService: {
      accordionTitle: "Occupational Health Service",
      firstForeword: ""
      // secondForeword: "",
      // thirdForeword: ""
    },
    workerParticipation: {
      accordionTitle: "Worker participation, Consultation, and Communication on occupational health and safety",
      firstForeword: "",
      secondForeword: ""
    },

    workerTraining: {
      accordionTitle: "Worker Training on Occupational Health and Safety",
      workerTrainingForeword: ""
    },

    promotionWorkerHealth: {
      accordionTitle: "Promotion of Worker Health",
      firstForeword: "",
      secondForeword: ""
    },

    preventionAndMitigation: {
      accordionTitle:
        "Prevention and mitigation of occupational health and safety impacts directly linked by business relationships Promotion of Worker Health",
      preventionAndMitigationForeword: ""
    },

    workersCovered: {
      accordionTitle: "Workers covered by an occupational health and safety management system",
      firstTable: [
        {
          title: "Number",
          number: ""
        },
        {
          title: "Percentage",
          percentage: ""
        }
      ],

      secondTable: [
        {
          title: "Number",
          numberPercentage: ""
        },
        {
          title: "Percentage",
          numberPercentage: ""
        }
      ],

      thirdTable: [
        {
          title: "Number",
          numberPercentage: ""
        },
        {
          title: "Percentage",
          numberPercentage: ""
        }
      ],

      secondForeword: "",
      thirdForeword: ""
    },

    workRelatedInjury: {
      accordionTitle: "Work related injury",
      forAllEmployeesSection: {
        forAllEmployees: [
          {
            parameters: "Assembly",
            workrelatedinjury: "1.Recordable work-related injuries",
            number: "",
            rate: ""
          },
          {
            parameters: "Assembly",
            workrelatedinjury: "2.Recordable work-related injuries",
            number: "",
            rate: ""
          },
          {
            parameters: "Assembly",
            workrelatedinjury: "3.Recordable work-related injuries",
            number: "",
            rate: ""
          },
          {
            parameters: "Total",
            workrelatedinjury: "1.Fatalities as a result of work-related injury",
            number: "",
            rate: ""
          },
          {
            parameters: "Total",
            workrelatedinjury: "2.High consequence work-related injury (excluding fatalities)",
            number: "",
            rate: ""
          },
          {
            parameters: "Total",
            workrelatedinjury: "3.Recordable work-related injuries",
            number: "",
            rate: ""
          }
        ],
        bodyPart: [
          {
            parameter: "Back",
            assembly: "",
            total: ""
          },
          {
            parameter: "Eyes",
            assembly: "",
            total: ""
          },
          {
            parameter: "Feet",
            assembly: "",
            total: ""
          },
          {
            parameter: "Hand",
            assembly: "",
            total: ""
          },
          {
            parameter: "Legs",
            assembly: "",
            total: ""
          },
          {
            parameter: "Multiple parts or Whole body",
            assembly: "",
            total: ""
          },
          {
            parameter: "Neck",
            assembly: "",
            total: ""
          },
          {
            parameter: "Thorax",
            assembly: "",
            total: ""
          },
          {
            parameter: "Total number of lost time injuries",
            assembly: "",
            total: ""
          }
        ],
        injuriesByType: [
          {
            parameter: "Abrasion/cut/scratch",
            assembly: "",
            total: ""
          },
          {
            parameter: "Bone fracture",
            assembly: "",
            total: ""
          },
          {
            parameter: "Burn injury",
            assembly: "",
            total: ""
          },
          {
            parameter: "Chemical exposure",
            assembly: "",
            total: ""
          },
          {
            parameter: "Contusion/bruise",
            assembly: "",
            total: ""
          },
          {
            parameter: "Electric shock",
            assembly: "",
            total: ""
          },
          {
            parameter: "Eye injury",
            assembly: "",
            total: ""
          },
          {
            parameter: "Hearing loss",
            assembly: "",
            total: ""
          },
          {
            parameter: "Heatstroke/hypothermia",
            assembly: "",
            total: ""
          },
          {
            parameter: "Loss of consciousness",
            assembly: "",
            total: ""
          },
          {
            parameter: "Loss of limb",
            assembly: "",
            total: ""
          },
          {
            parameter: "Poisoning",
            assembly: "",
            total: ""
          },
          {
            parameter: "Sprain/strain/dislocation",
            assembly: "",
            total: ""
          },
          {
            parameter: "Whiplash injury",
            assembly: "",
            total: ""
          },
          {
            parameter: "Other",
            assembly: "",
            total: ""
          },
          {
            parameter: "Total number of lost time injuries",
            assembly: "",
            total: ""
          }
        ],
        hoursWorked: [
          {
            parameter: "Total",
            numberOfHoursWorked: ""
          },
          {
            parameter: "Assembly",
            numberOfHoursWorked: ""
          }
        ]
      },
      forAllWorkersSection: {
        forAllEmployees: [
          {
            parameters: "Total",
            workrelatedinjury: "1.Fatalities as a result of work-related injury",
            number: "",
            rate: ""
          },
          {
            parameters: "Total",
            workrelatedinjury: "2.High consequence work-related injury (excluding fatalities)",
            number: "",
            rate: ""
          },
          {
            parameters: "Total",
            workrelatedinjury: "3.Recordable work-related injuries",
            number: "",
            rate: ""
          },
          {
            parameters: "Assembly",
            workrelatedinjury: "1.Recordable work-related injuries",
            number: "",
            rate: ""
          },
          {
            parameters: "Assembly",
            workrelatedinjury: "2.Recordable work-related injuries",
            number: "",
            rate: ""
          },
          {
            parameters: "Assembly",
            workrelatedinjury: "3.Recordable work-related injuries",
            number: "",
            rate: ""
          }
        ],

        bodyPart: [
          {
            parameter: "Back",
            assembly: "",
            total: ""
          },
          {
            parameter: "Eyes",
            assembly: "",
            total: ""
          },
          {
            parameter: "Feet",
            assembly: "",
            total: ""
          },
          {
            parameter: "Hand",
            assembly: "",
            total: ""
          },
          {
            parameter: "Legs",
            assembly: "",
            total: ""
          },
          {
            parameter: "Multiple parts or Whole body",
            assembly: "",
            total: ""
          },
          {
            parameter: "Neck",
            assembly: "",
            total: ""
          },
          {
            parameter: "Thorax",
            assembly: "",
            total: ""
          },
          {
            parameter: "Total number of lost time injuries",
            assembly: "",
            total: ""
          }
        ],

        injuriesByType: [
          {
            parameter: "Abrasion/cut/scratch",
            assembly: "",
            total: ""
          },
          {
            parameter: "Bone fracture",
            assembly: "",
            total: ""
          },
          {
            parameter: "Burn injury",
            assembly: "",
            total: ""
          },
          {
            parameter: "Chemical exposure",
            assembly: "",
            total: ""
          },
          {
            parameter: "Contusion/bruise",
            assembly: "",
            total: ""
          },
          {
            parameter: "Electric shock",
            assembly: "",
            total: ""
          },
          {
            parameter: "Eye injury",
            assembly: "",
            total: ""
          },
          {
            parameter: "Hearing loss",
            assembly: "",
            total: ""
          },
          {
            parameter: "Heatstroke/hypothermia",
            assembly: "",
            total: ""
          },
          {
            parameter: "Loss of consciousness",
            assembly: "",
            total: ""
          },
          {
            parameter: "Loss of limb",
            assembly: "",
            total: ""
          },
          {
            parameter: "Poisoning",
            assembly: "",
            total: ""
          },
          {
            parameter: "Sprain/strain/dislocation",
            assembly: "",
            total: ""
          },
          {
            parameter: "Whiplash injury",
            assembly: "",
            total: ""
          },
          {
            parameter: "Other",
            assembly: "",
            total: ""
          },
          {
            parameter: "Total number of lost time injuries",
            assembly: "",
            total: ""
          }
        ],

        hoursWorked: [
          {
            parameter: "Total",
            numberOfHoursWorked: ""
          },
          {
            parameter: "Assembly",
            numberOfHoursWorked: ""
          }
        ]
      }
    },

    detailsOfWork: {
      accordionTitle: " Details of work related hazards",
      detailsOfWworkRelatedHazards: [
        {
          heading: "Incidents of work related hazards",
          foreword: ""
        },
        {
          heading: "How these hazards were determined ?",
          foreword: ""
        },
        {
          heading: "Which of these hazards have caused or contributed to high-consequence injuries?",
          foreword: ""
        },
        {
          heading:
            "Actions taken or underway to eliminate other work related hazards and minimize risks using hierarchy of controls",
          foreword: ""
        },
        {
          heading:
            "Any actions taken or underway to eliminate other work-related hazards and minimize risks using hierarchy of controls",
          foreword: ""
        },
        {
          heading: "Whether the rates have been calculated based on 200,000 or 1,000,000 hours worked",
          foreword: ""
        },
        {
          heading:
            "Whether and, if so, why any workers have been excluded from this disclosure, including the types of worker excluded",
          foreword: ""
        },
        {
          heading:
            "Any contextual information necessary to understand how the data have been compiled, such as any standards, methodologies and assumptions used",
          foreword: ""
        }
      ]
    }
  },

  trainingAndEducation: {
    reportingPeriod: {
      accordionTitle:
        "Average hours of training that the organization’s employees have undertaken during the reporting period",
      byGender: [
        {
          title: "Male",
          averageTrainingHours: ""
        },
        {
          title: "Female",
          averageTrainingHours: ""
        },
        {
          title: "Total Employee",
          averageTrainingHours: ""
        }
      ],

      byEmployeeCategory: [
        {
          category: "Permanent Employee",
          averageTraininghours: ""
        },
        {
          category: "Male",
          averageTraininghours: ""
        },
        {
          category: "Female",
          averageTraininghours: ""
        },
        {
          category: "Temporary Employee",
          averageTraininghours: ""
        },
        {
          category: "Male",
          averageTraininghours: ""
        },
        {
          category: "Female",
          averageTraininghours: ""
        },
        {
          category: "Non-guaranteed hours employee",
          averageTraininghours: ""
        },
        {
          category: "Male",
          averageTraininghours: ""
        },
        {
          category: "Female",
          averageTraininghours: ""
        },
        {
          category: "Full time employee",
          averageTraininghours: ""
        },
        {
          category: "Male",
          averageTraininghours: ""
        },
        {
          category: "Female",
          averageTraininghours: ""
        },
        {
          category: "Part time employee",
          averageTraininghours: ""
        },
        {
          category: "Male",
          averageTraininghours: ""
        },
        {
          category: "Female",
          averageTraininghours: ""
        }
      ]
    },

    assistanceProgram: {
      accordionTitle: "Programs for upgrading employee skills and transition assistance program",
      upgradingEmployeeskill: {
        foreword: ""
      },
      careerEndingsResulting: {
        foreword: ""
      }
    },

    employeePercentageOfCareerDevelopment: {
      byGender: [
        {
          title: "Male",
          number: "",
          percentage: ""
        },
        {
          title: "Female",
          number: "",
          percentage: ""
        }
      ],

      byEmployeeCategory: [
        {
          category: "Permanent Employee",
          number: "",
          percentage: ""
        },
        {
          category: "Male",
          number: "",
          percentage: ""
        },
        {
          category: "Female",
          number: "",
          percentage: ""
        },
        {
          category: "Temporary Employee",
          number: "",
          percentage: ""
        },
        {
          category: "Male",
          number: "",
          percentage: ""
        },
        {
          category: "Female",
          number: "",
          percentage: ""
        },
        {
          category: "Non-guaranteed hours employee",
          number: "",
          percentage: ""
        },
        {
          category: "Male",
          number: "",
          percentage: ""
        },
        {
          category: "Female",
          number: "",
          percentage: ""
        },
        {
          category: "Full time employee",
          number: "",
          percentage: ""
        },
        {
          category: "Male",
          number: "",
          percentage: ""
        },
        {
          category: "Female",
          number: "",
          percentage: ""
        },
        {
          category: "Part time employee",
          number: "",
          percentage: ""
        },
        {
          category: "Male",
          number: "",
          percentage: ""
        },
        {
          category: "Female",
          number: "",
          percentage: ""
        }
      ]
    }
  },

  diversityEqualOpportunity: {
    governanceBody: {
      accordionTitle: "Percentage of individuals within the organization's governance body",
      percentageOfIndividuals: [
        {
          title: "Male",
          number: "",
          percentage: ""
        },
        {
          title: "Female",
          number: "",
          percentage: ""
        },
        {
          title: "Total employee",
          number: "",
          percentage: ""
        },
        {
          title: "By age group",
          number: "",
          percentage: ""
        },
        {
          title: "Under 30 years old",
          number: "",
          percentage: ""
        },
        {
          title: "30-50 years old",
          number: "",
          percentage: ""
        },
        {
          title: "Over 50 years old",
          number: "",
          percentage: ""
        },
        {
          title: "Other indicators of diversity",
          number: "",
          percentage: ""
        },
        {
          title: "",
          number: "",
          percentage: ""
        }
      ]
    },

    percentageOfEmployees: {
      accordionTitle: "Percentage of employees per employee category in each of the following diversity categories",
      percentageOfEmployeesPerEmployee: [
        {
          category: "Permanent Employee",
          number: "",
          percentage: ""
        },
        {
          category: "Male",
          number: "",
          percentage: ""
        },
        {
          category: "Female",
          number: "",
          percentage: ""
        },
        {
          category: "Temporary Employee",
          number: "",
          percentage: ""
        },
        {
          category: "Male",
          number: "",
          percentage: ""
        },
        {
          category: "Female",
          number: "",
          percentage: ""
        },
        {
          category: "Non-guaranteed hours employee",
          number: "",
          percentage: ""
        },
        {
          category: "Male",
          number: "",
          percentage: ""
        },
        {
          category: "Female",
          number: "",
          percentage: ""
        },
        {
          category: "Full time employee",
          number: "",
          percentage: ""
        },
        {
          category: "Male",
          number: "",
          percentage: ""
        },
        {
          category: "Female",
          number: "",
          percentage: ""
        },
        {
          category: "Part time employee",
          number: "",
          percentage: ""
        },
        {
          category: "Male",
          number: "",
          percentage: ""
        },
        {
          category: "Female",
          number: "",
          percentage: ""
        },

        {
          category: "By age group",
          number: "",
          percentage: ""
        },
        {
          category: "Under 30 years old",
          number: "",
          percentage: ""
        },
        {
          category: "30-50 years old",
          number: "",
          percentage: ""
        },
        {
          category: "Over 50 years old",
          number: "",
          percentage: ""
        },
        {
          category: "Other indicators of diversity",
          number: "",
          percentage: ""
        },
        {
          category: "",
          number: "",
          percentage: ""
        }
      ]
    },

    ratioOfTheBasicSalary: {
      accordionTitle: "Ratio of the basic salary and remuneration of women to men",

      ratioOfTheBasicSalary: [
        {
          region: "",
          employeeCategory: "",
          basicSalaryFemale: "",
          basicSalaryMale: "",
          basicSalaryRatio: ""
        }
      ]
    }
  },

  discrimination:{
    nonDiscrimination: {
      accordionTitle: "Incidents of discrimination and corrective actions taken",
      totalNumberOfIncidents: "",
      statusOfTheIncidents: [
        {
          sNo: "",
          discriminatoryIncident: "",
          remediationPlans: "",
          managementProcesses: "",
          incidentsNoLonger: ""
        }
      ]
    },
  },

  freedom :{
    freedomOfAssociation: {
      accordionTitle: "Freedom of Association and Collective Bargaining",
      operationsAndSuppliers: [
        { collectiveBargaining: "", typeOfOperation: "", typeofSupplier: "", country: "", region: "" }
      ],
      measuresTakenByTheOrganization: ""
    },
  },

  humanRightsAssessment: {
    operations: {
      accordionTitle: "Operations that have been subject to human rights reviews or impact assessment",
      impactAssessment: [
        {
          title: "Operations subject to human rights policies or human right impact assessment",
          number: "",
          percentage: ""
        }
      ]
    },

    procedure: {
      accordionTitle: "Employee Training on human rights policies or procedure",
      employeeTraining: [
        {
          humanRightsAspects: "",
          totalNumberOfHours: ""
        }
      ],

      employeestrainedHumanRights: [
        {
          title: "Employees trained on human rights",
          number: "",
          percentage: ""
        }
      ]
    },

    rightsScreening: {
      accordionTitle:
        "Significant investment agreements and contracts that include human rights clauses or that underwent human rights screening",
      significantInvestment: [
        {
          agreementsAndContracts: "",
          totalNumberOfAgreements: "",
          percentage: ""
        }
      ],
      specifyTheDefinition: ""
    }
  }
};

export const emptyFormPageWiseData = {

  governance: {
    governanceStructureComposition: {
      accordionTitle: "Details about the Governance structure and its Composition",
      governanceStructureDetails: [
        {
          heading: "Describe its governance structure, including committees of the highest governance body",
          forewordValue: ""
        },
        {
          heading:
            "List the committees of the highest governance body that are responsible for decisionmaking on and overseeing the management of the organization’s impacts on the economy, environment and people",
          forewordValue: ""
        }
      ]
    },

    compositionDetails: {
      accordionTitle: "Details of the Composition of the highest governance body",
      compositionTableDetail: [
        {
          committeeMember: "",
          executiveNonExecutive: "",
          gender: "",
          age: "",
          conflictOfInteres: "",
          tenureOfMember: "",
          numberOfSignificantPositions: "",
          natureOfCommitment: "",
          competenciesRelevant: "",
          stakeholderRepresentation: "",
          anyOtherIndicator: ""
        }
      ]
    }
  },

  structureComposition: {
      structureGovernancBbody: {
        accordionTitle: "Governance Structure and Composition",
        governanceBodyDetails: [
          {
            heading:
              "Describe the nomination and selection processes for the highest governance body and its committiees",
            forewordValue: ""
          },
          {
            heading:
              "Describe the criteria used for nominating and selecting highest governance body members, including whether and how the following are taken into consideration: i. views of stakeholders (including shareholders);ii. diversity; iii. independence; and iv. competencies relevant to the impacts of the organization.",
            forewordValue: ""
          },
          {
            heading:
              "Report whether the chair of the highest governance body is also a senior executive in the organization",
            forewordValue: ""
          },
          {
            heading:
              "If the chair is also a senior executive, explain their function within the organization’s management, the reasons for this arrangement, and how conflicts of interest are prevented and mitigated",
            forewordValue: ""
          }
        ]
      }
    },

    highestGovernance:{
      roleOfHighestGovernanceBody: {
        accordionTitle: "Role of the highest governance body in overseeing the management of impacts",
  
        roleOfGovernanceBody: [
          {
            heading:
              "Describe the role of the highest governance body and of senior executives in developing, approving, and updating the organization’s purpose, value or mission statements, strategies, policies, and goals related to sustainable development.",
            firstSubHeading: "",
            secondSubHeading: "",
            forewordValue: ""
          },
          {
            heading:
              "Describe the role of the highest governance body in overseeing the organization’s due diligence and other processes to identify and manage the organization’s impacts on the economy, environment, and people, including:",
            firstSubHeading:
              "whether and how the highest governance body engages with stakeholders to support these processes",
            secondSubHeading: "how the highest governance body considers the outcomes of these processes",
            forewordValue: ""
          },
          {
            heading:
              "Describe the role of the highest governance body in reviewing the effectiveness of the organization’s processes as described in 2-12-b, and report the frequency of this review.",
            firstSubHeading: "",
            secondSubHeading: "",
            forewordValue: ""
          }
        ]
      },
    },

    managingImpacts:{
      delegationOfResponsibility: {
        accordionTitle: "Details about responsibility delegations for managing impacts",
        detailsAboutResponsibility: [
          {
            heading:
              "Describe how the highest governance body delegates responsibility for managing the organization’s impacts on the economy, environment, and people, including:",
            firstSubHeading:
              "whether it has appointed any senior executives with responsibility for the management of impacts",
            secondSubHeading: "whether it has delegated responsibility for the management of impacts to other employees",
            forewordValue: ""
          },
          {
            heading:
              "Describe the process and frequency for senior executives or other employees to report back to the highest governance body on the management of the organization’s impacts on the economy, environment, and people",
            firstSubHeading: "",
            secondSubHeading: "",
            forewordValue: ""
          }
        ]
      },
    },



    sustainability: {
      roleOfGovernanceBodyInSustainability: {
        accordionTitle: "Details about responsibility delegations for managing impacts",
        detailsAboutInSustainability: [
          {
            heading:
              "Describe the role of the highest governance body and of senior executives in developing, approving, and updating the organization’s purpose, value or mission statements, strategies, policies, and goals related to sustainable development.",
            firstSubHeading: "",
            secondSubHeading: "",
            forewordValue: ""
          },
          {
            heading:
              "Describe the role of the highest governance body in overseeing the organization’s due diligence and other processes to identify and manage the organization’s impacts on the economy, environment, and people, including:",
            firstSubHeading:
              "whether and how the highest governance body engages with stakeholders to support these processes",
            secondSubHeading: "How the highest governance body considers the outcomes of these processes",
            forewordValue: ""
          },
          {
            heading:
              "Describe the role of the highest governance body in reviewing the effectiveness of the organization’s processes as described in 2-12-b, and report the frequency of this review.",
            firstSubHeading: "",
            secondSubHeading: "",
            forewordValue: ""
          }
        ]
      }
    },


    conflictInterest:{
      conflictOfInterest: {
        accordionTitle: "Details about conflict of interest",
        detailsAboutConflict: [
          {
            heading:
              "Describe the processes for the highest governance body to ensure that conflicts of interest are prevented and mitigated",
            firstSubHeading: "",
            secondSubHeading: "",
            thirdSubHeading: "",
            fourthSubHeading: "",
            forewordValue: ""
          },
          {
            heading:
              "Report whether conflicts of interest are disclosed to stakeholders, including, at a minimum, conflicts of interest relating to",
            firstSubHeading: "cross border membership",
            secondSubHeading: "cross-shareholding with suppliers and other stakeholders",
            thirdSubHeading: "existence of controlling shareholders",
            fourthSubHeading: "related parties, their relationships, transactions, and outstanding balances",
            forewordValue: ""
          }
        ]
      },
    },

    criticalConcerns:{
      communicationOfCriticalConcerns: {
        accordionTitle: "Details about communication of critical concerns",
        detialsAboutCommunication: [
          {
            heading: "Describe whether and how critical concerns are communicated to the highest governance body",
            forewordValue: ""
          },
          {
            heading:
              "Report the total number and the nature of critical concerns that were communicated to the highest governance body during the reporting period",
            forewordValue: ""
          }
        ]
      },
    },

  knowledgeAndPerformance: {
    collectiveKnowledge: {
      accordionTitle: "Collective knowledge of the highest governance body",
      collectiveKnowledge: [
        {
          heading:
            "Report measures taken to advance the collective knowledge, skills, and experience of the highest governance body on sustainable development.",
          forewordValue: ""
        }
      ]
    },

    performanceEvaluation: {
      accordionTitle: "Evaluation of the performance of the highest governance body the organization",
      evaluationOfThePerformance: [
        {
          heading:
            "Describe the processes for evaluating the performance of the highest governance body in overseeing the management of the organization’s impacts on the economy, environment, and people.",
          forewordValue: ""
        },
        {
          heading: "Report whether the evaluations are independent or not, and the frequency of the evaluations.",
          forewordValue: ""
        },
        {
          heading:
            "Describe actions taken in response to the evaluations, including changes to the composition of the highest governance body and organizational practices.",
          forewordValue: ""
        }
      ]
    }
  },
 
  remunerationPolicies: {
    remunerationPolicy: {
      accordionTitle: "Details about the remuneration policy of the Organisation",

      detailsAboutRemuneration: [
        {
          fixedVariablePay: "",
          incentivePayments: "",
          terminationPayments: "",
          clawbacks: "",
          retirementBenefits: ""
        },
        {
          fixedVariablePay: "",
          incentivePayments: "",
          terminationPayments: "",
          clawbacks: "",
          retirementBenefits: ""
        }
      ],

      discriptionOfRemuneration: ""
    },
    // detailsAboutRemuneration: [
    //   {
    //     fixedVariablePay: "",
    //     incentivePayments: "",
    //     terminationPayments: "",
    //     clawbacks: "",
    //     retirementBenefits: ""
    //   },
    //   {
    //     fixedVariablePay: "",
    //     incentivePayments: "",
    //     terminationPayments: "",
    //     clawbacks: "",
    //     retirementBenefits: ""
    //   }
    // ],
    // discriptionOfRemuneration: [
    //   {
    //     heading:
    //       "Describe how the remuneration policies for members of the highest governance body and senior executives relate to their objectives and performance in relation to the management of the organization’s impacts on the economy, environment, and people.",
    //     forewordValue: ""
    //   }
    // ],

    determinerRemuneration: {
      accordionTitle: "Annual total compensation ratio",
      determineRemunerationProcess: [
        {
          heading:
            "Describe the process for designing its remuneration policies and for determining remuneration, including",
          firstSubHeading:
            "whether independent highest governance body members or an independent remuneration committee oversees the process for determining remuneration",
          secondSubHeading:
            "how the views of stakeholders (including shareholders) regarding remuneration are sought and taken into consideration",
          thirdSubHeading: "whether remuneration consultants are involved in determining remuneration",
          fourthSubHeading:
            "if yes, whether they are independent of the organization, its highest governance body and senior executives",
          forewordValue: ""
        },
        {
          heading:
            "Report the results of votes of stakeholders (including shareholders) on remuneration policies and proposals, if applicable",
          forewordValue: ""
        }
      ]
    },

    compensationRatio: {
      accordionTitle: "Annual total compensation ratio",
      annualCompensationRatio: [
        {
          heading:
            "Report the ratio of the annual total compensation for the organization’s highest-paid individual to the median annual total compensation for all employees(excluding the highest-paid individual)",
          forewordValue: ""
        },
        {
          heading:
            "Report the ratio of the percentage increase in annual total compensation for the organization’s highest-paid individual to the median percentage increase in annual total compensation for all employees (excluding the highest-paid individual)",
          forewordValue: ""
        },
        {
          heading: "Report contextual information necessary to understand the data and how the data has been compiled",
          forewordValue: ""
        }
      ]
    }
  },

  organisation:{

    antiCompetitive: {
      accordionTitle: "Details about the Anti-Competitive cases in the Organisation",
      deatilsAboutAntiCompetitive: [
        {
          heading: "Anti-competitive behaviour",
          numberOfCases: "",
          pendingCompleted: "",
          outcome: ""
        },
        {
          heading: "Anti-trust and monopoly behaviour",
          numberOfCases: "",
          pendingCompleted: "",
          outcome: ""
        }
      ]
    },
  },

  // we have Anti- Corruption empty slice in seprate file -

  tax: {
    approach: {
      accordionTitle: "Approach to tax",
      approachToTaxDescription: ""
    },

    taxGovernance: {
      accordionTitle: "Tax governance, control, and risk management",
      taxGovernanceControlAndRiskManagement: [
        {
          heading: "A description of the tax governance and control framework, including: ",
          firstSubHeading:
            "the governance body or executive-level position within the organization accountable for compliance with the tax strategy",
          secondSubHeading:
            "how the approach to tax is embedded within the organization;the approach to tax risks, including how risks are identified, managed, and monitored",
          thirdSubHeading: "the approach to tax risks, including how risks are identified, managed, and monitored",
          fourthSubHeading: "how compliance with the tax governance and control framework is evaluated",
          forewordValue: ""
        },
        {
          heading:
            "A description of the mechanisms to raise concerns about the organization’s business conduct and the organization’s integrity in relation to tax",
          // firstSubHeading: "",
          // secondSubHeading: "",
          // thirdSubHeading: "",
          // fourthSubHeading: "",
          forewordValue: ""
        },
        {
          heading:
            "A description of the assurance process for disclosures on tax including, if applicable, a link or reference to the external assurance report(s) or assurance statement(s).",
          // firstSubHeading: "",
          // secondSubHeading: "",
          // thirdSubHeading: "",
          // fourthSubHeading: "",
          forewordValue: ""
        }
      ]
    },

    stakeholder: {
      accordionTitle: "Stakeholder engagement and management of concerns related to tax",
      stakeholderEngagementDiscription: ""
    },

    reporting: {
      accordionTitle: "Country by country reporting",
      countryByCountryReporting: [
        {
          SINo: "",
          residentEntitiesName: "",
          primaryActivities: "",
          numberOfEmployees: "",
          basisCalculation: "",
          revenuesFromThirdParty: "",
          revenuesFromIntraGroup: "",
          profitoLssbeforeTax: "",
          tangibleAssets: "",
          corporateIncomeTaxPaidCashBasic: "",
          corporateIncomeTaxAccruedOnProfitLoss: "",
          diffrence: ""
        }
      ]
    }
  }
};

export const emptyCorruptionPageData = {
  antiCorruption: {
    operations: {
      accordionTitle: "Operations assessed for risks related to corruption",
      operationsAssessed: [
        {
          heading: "Operation assessed for the risk of corruption",
          totalNumber: "",
          percentage: ""
        }
      ],

      significantRiskForeword: ""
    },

    communicationAndTraining: {
      accordionTitle: "Communication and training about anti-corruption policies and procedures",
      governanceBodyMembers: [
        {
          region: "",
          totalNumber: "",
          percentage: "",
          some: ""
        }
      ],

      employeeCategoryAndRegion: [
        {
          region: "",
          employeeCategory: "",
          gender: "",
          totalNumber: "",
          percentage: ""
        }
      ],

      businessPartnerCategory: [
        {
          region: "",
          businessPartnerCategory: "",
          totalNumber: "",
          percentage: ""
        }
      ],

      totalRecievedTraningBodyMembers: [
        {
          region: "",
          totalNumber: "",
          percentage: ""
        }
      ],

      totalRecievedTraningEmployees: [
        {
          region: "",
          employeeCategory: "",
          gender: "",
          totalNumber: "",
          percentage: ""
        }
      ]
    },

    actionsTaken: {
      accordionTitle: "Confirmed incidents of corruption and actions taken",
      confirmedIncidents: [
        {
          heading: "Confirmed incidents of corruption (CIC)",
          totalnumberOfCIC: "",
          natureOfCIC: "",
          totalNumberEmployeesDismissed: "",
          totalNumberEmployeesDisciplined: "",
          totalNumberPartnersTerminated: ""
        }
      ],

      publiclegal: ""
    }
  }
};
