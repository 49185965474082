import React from "react";
import Accordion from "../../../../../components/Accordion/Accordion";
import { useDispatch, useSelector } from "react-redux";
import "../../Social.scss";
import { setPageWiseData } from "../../../../../state/SocialSlice";
import { numberRegex } from "../../../../../helper/Rejex";

const Occupational = ({formEditable, editMode}) => {
  const { pageWiseData } = useSelector((state) => state.social);

  const dispatch = useDispatch();

  let socialAllData = JSON.parse(JSON.stringify(pageWiseData));

  const handleOccupationalHealthChange = (e) => {
    socialAllData.occupationalHealthSafety.occupationalHealthSafetyMgmt[e.target.name] = e.target.value;
    dispatch(setPageWiseData(socialAllData));
  };

  const handlehazardIdentificationChange = (e) => {
    socialAllData.occupationalHealthSafety.hazardIdentification[e.target.name] = e.target.value;
    dispatch(setPageWiseData(socialAllData));
  };

  const handleOccupationalHealthServiceChange = (e) => {
    socialAllData.occupationalHealthSafety.occupationalHealthService.firstForeword = e.target.value;
    dispatch(setPageWiseData(socialAllData));
  };

  const hanldeWorkerParticipationChange = (e) => {
    socialAllData.occupationalHealthSafety.workerParticipation[e.target.name] = e.target.value;
    dispatch(setPageWiseData(socialAllData));
  };

  const handleWorkerTrainingForewordChange = (e) => {
    socialAllData.occupationalHealthSafety.workerTraining.workerTrainingForeword = e.target.value;
    dispatch(setPageWiseData(socialAllData));
  };

  const handlePromotionWorkerHealthChange = (e) => {
    socialAllData.occupationalHealthSafety.promotionWorkerHealth[e.target.name] = e.target.value;
    dispatch(setPageWiseData(socialAllData));
  };

  const handlePreventionAndMitigation = (e) => {
    socialAllData.occupationalHealthSafety.preventionAndMitigation.preventionAndMitigationForeword = e.target.value;
    dispatch(setPageWiseData(socialAllData));
  };

  const handleWorkersCoveredChange = (e) => {
    socialAllData.occupationalHealthSafety.workersCovered[e.target.name] = e.target.value;
    dispatch(setPageWiseData(socialAllData));
  };

  const handleDetailsOfWworkRelatedHazardsChange = (e, index) => {
    socialAllData.occupationalHealthSafety.detailsOfWork.detailsOfWworkRelatedHazards[index][e.target.name] = e.target.value;
    dispatch(setPageWiseData(socialAllData));
  };

 
  const handleWorkersCoveredFirstTableChange = (e, index) => {
    if(numberRegex.test(e.target.value)  || e.target.value ===""){
    socialAllData.occupationalHealthSafety.workersCovered.firstTable[index][e.target.name] = e.target.value;
  }
    dispatch(setPageWiseData(socialAllData));
  };
  const handleWorkersCoveredSecondChange = (e, index) => {
    socialAllData.occupationalHealthSafety.workersCovered.secondTable[index][e.target.name] = e.target.value;
    dispatch(setPageWiseData(socialAllData));
  };
  const handleWorkersCoveredThirdChange = (e, index) => {
    socialAllData.occupationalHealthSafety.workersCovered.thirdTable[index][e.target.name] = e.target.value;
    dispatch(setPageWiseData(socialAllData));
  };

  const preprocessData = (data) => {
    const rowSpans = {};
    data.forEach((item) => {
      const { parameters } = item;
      rowSpans[parameters] = (rowSpans[parameters] || 0) + 1;
    });
    return rowSpans;
  };
  const rowSpans = preprocessData(socialAllData.occupationalHealthSafety.workRelatedInjury.forAllEmployeesSection.forAllEmployees);

  const rowSpansAllworkers = preprocessData(socialAllData.occupationalHealthSafety.workRelatedInjury.forAllWorkersSection.forAllEmployees);

  const handleForAllEmployeesChange = (e, index) => {
    if(numberRegex.test(e.target.value) || e.target.value===""){
    socialAllData.occupationalHealthSafety.workRelatedInjury.forAllEmployeesSection.forAllEmployees[index][e.target.name] = e.target.value;
    }
    dispatch(setPageWiseData(socialAllData));
  };

  const handleForAllEmployeesBodyPartChange = (e, index) => {
    const {name  , value} = e.target
    if(name === "total"){
      if(numberRegex.test(value) || value ===""){
        socialAllData.occupationalHealthSafety.workRelatedInjury.forAllEmployeesSection.bodyPart[index][name] = value;
      }
    }
    else{
    socialAllData.occupationalHealthSafety.workRelatedInjury.forAllEmployeesSection.bodyPart[index][name] = value;

    }
    dispatch(setPageWiseData(socialAllData));
  };

  const handleForAllEmployeesInjuriesByTypeChange = (e, index) => {
    const {name  , value} = e.target
    if(name === "total"){
      if(numberRegex.test(value) || value ===""){
        socialAllData.occupationalHealthSafety.workRelatedInjury.forAllEmployeesSection.injuriesByType[index][name] =value;
      }
    }
    else{
      socialAllData.occupationalHealthSafety.workRelatedInjury.forAllEmployeesSection.injuriesByType[index][name] =value;

    }
    dispatch(setPageWiseData(socialAllData));
  };
 const handleForAllEmployeesHoursWorkedChange =(e, index)=>{
  const {name  , value} = e.target
  if(numberRegex.test(value) || value ===""){
  socialAllData.occupationalHealthSafety.workRelatedInjury.forAllEmployeesSection.hoursWorked[index][name] = value;
  }
  dispatch(setPageWiseData(socialAllData));
 }

const handleForAllWorkerChange =(e, index)=>{
  if(numberRegex.test(e.target.value) || e.target.value===""){
  socialAllData.occupationalHealthSafety.workRelatedInjury.forAllWorkersSection.forAllEmployees[index][e.target.name] = e.target.value;
  }
  dispatch(setPageWiseData(socialAllData));
}

const handleForAllWorkerBodyPartChange =(e, index)=>{

  const {name  , value} = e.target
  if(name === "total"){
    if(numberRegex.test(value) || value ===""){
      socialAllData.occupationalHealthSafety.workRelatedInjury.forAllWorkersSection.bodyPart[index][name] = value;
    }
  }
  else{
    socialAllData.occupationalHealthSafety.workRelatedInjury.forAllWorkersSection.bodyPart[index][name] = value;

  }

  dispatch(setPageWiseData(socialAllData));
}

const handleForAllWorkerInjuriesByTypeChange =(e, index)=>{
  const {name  , value} = e.target
  if(name === "total"){
    if(numberRegex.test(value) || value ===""){
      socialAllData.occupationalHealthSafety.workRelatedInjury.forAllWorkersSection.injuriesByType[index][name] = value;
    }
  }
  else{
    socialAllData.occupationalHealthSafety.workRelatedInjury.forAllWorkersSection.injuriesByType[index][name] = value;

  }
  dispatch(setPageWiseData(socialAllData));
}
const handleForAllWorkerHoursWorkedChange =(e, index)=>{
  if(numberRegex.test(e.target.value) ||e.target.value ===""){
  socialAllData.occupationalHealthSafety.workRelatedInjury.forAllWorkersSection.hoursWorked[index][e.target.name] = e.target.value;
  }
  dispatch(setPageWiseData(socialAllData));
}

  return (
    <div className="esg-table-content">
      <div className="form-bullet-points-div ">
        <Accordion title={"Occupational health safety and management system"} index={0}>
          <div className="foreword-container mt-1">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                A statement of whether an occupational health and safety management system has been implemented,
                including whether
              </p>

              <ol className="d-flex flex-column gap-3">
                <li>
                  The system has been implemented because of legal requirements and, if so, a list of the requirements
                </li>
                <div className="custom-textfield ">
                  <textarea
                    rows="1"
                    placeholder="Write your foreword here."
                    value={socialAllData.occupationalHealthSafety.occupationalHealthSafetyMgmt.firstForeword}
                    name="firstForeword"
                    onChange={(e) => handleOccupationalHealthChange(e)}
                    className="no-style-input w-100"
                      disabled ={editMode && !formEditable}
                  />
                </div>
                <li>
                  The system has been implemented based on recognized risk management and/or management system
                  standards/guidelines and, if so, a list of the standards/guidelines
                </li>
                <div className="custom-textfield ">
                  <textarea
                    rows="1"
                    placeholder="Write your foreword here."
                    value={socialAllData.occupationalHealthSafety.occupationalHealthSafetyMgmt.secondForeword}
                    name="secondForeword"
                    onChange={(e) => handleOccupationalHealthChange(e)}
                    className="no-style-input w-100"
                      disabled ={editMode && !formEditable}
                  />
                </div>
              </ol>
            </div>
          </div>

          <div className="foreword-container mt-3">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                A description of the scope of workers, activities and workplaces covered by the occupational health and
                safety management system, and an explanation of whether and, if so, why any workers, activities, or
                workplaces are not covered
              </p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={socialAllData.occupationalHealthSafety.occupationalHealthSafetyMgmt.thirdForeword}
                  name="thirdForeword"
                  onChange={(e) => handleOccupationalHealthChange(e)}
                  className="no-style-input w-100"
                    disabled ={editMode && !formEditable}
                />
              </div>
            </div>
          </div>
        </Accordion>
      </div>

      <div className="form-bullet-points-div mt-4 ">
        <Accordion title={"Hazard Identification, risk assessment and incident investigation"} index={1}>
          <div className="foreword-container mt-1">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                A description of the processes used to identify work-related hazards and assess risks on a routine and
                non-routine basis, and to apply the hierarchy of controls in order to eliminate hazards and minimize
                risks, including:
              </p>

              <ol className="d-flex flex-column gap-3">
                <li>
                  How the organization ensures the quality of these processes, including the competency of persons who
                  carry them out
                </li>
                <li>
                  How the results of these processes are used to evaluate and continually improve the occupational
                  health and safety management system
                </li>
              </ol>

              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={socialAllData.occupationalHealthSafety.hazardIdentification.firstForeword}
                  name="firstForeword"
                  onChange={(e) => handlehazardIdentificationChange(e)}
                  className="no-style-input w-100"
                    disabled ={editMode && !formEditable}
                />
              </div>
            </div>
          </div>

          <div className="foreword-container mt-3">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                A description of the processes for workers to report work-related hazards and hazardous situations, and
                an explanation of how workers are protected against reprisals.
              </p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={socialAllData.occupationalHealthSafety.hazardIdentification.secondForeword}
                  name="secondForeword"
                  onChange={(e) => handlehazardIdentificationChange(e)}
                  className="no-style-input w-100"
                    disabled ={editMode && !formEditable}
                />
              </div>
            </div>
          </div>

          <div className="foreword-container mt-3">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                A description of the policies and processes for workers to remove themselves from work situations that
                they believe could cause injury or ill health, and an explanation of how workers are protected against
                reprisals."
              </p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={socialAllData.occupationalHealthSafety.hazardIdentification.thirdForeword}
                  name="thirdForeword"
                  onChange={(e) => handlehazardIdentificationChange(e)}
                  className="no-style-input w-100"
                    disabled ={editMode && !formEditable}
                />
              </div>
            </div>
          </div>

          <div className="foreword-container mt-3">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                A description of the processes used to investigate work-related incidents, including the processes to
                identify hazards and assess risks relating to the incidents, to determine corrective actions using the
                hierarchy of controls, and to determine improvements needed in the occupational health and safety
                management system.
              </p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={socialAllData.occupationalHealthSafety.hazardIdentification.fourthForeword}
                  name="fourthForeword"
                  onChange={(e) => handlehazardIdentificationChange(e)}
                  className="no-style-input w-100"
                    disabled ={editMode && !formEditable}
                />
              </div>
            </div>
          </div>
        </Accordion>
      </div>

      <div className="form-bullet-points-div mt-4 ">
        <Accordion title={"Occupational Health Service"} index={2}>
          <div className="foreword-container mt-1">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                A description of the occupational health services' functions that contribute to the identification and
                elimination of hazards and minimization of risks, and an explanation of how the organization ensures the
                quality of these services and facilitates workers' access to them.
              </p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={socialAllData.occupationalHealthSafety.occupationalHealthService.firstForeword}
                  name="firstForeword"
                  onChange={(e) => handleOccupationalHealthServiceChange(e)}
                  className="no-style-input w-100"
                    disabled ={editMode && !formEditable}
                />
              </div>
            </div>
          </div>
        </Accordion>
      </div>

      <div className="form-bullet-points-div mt-4 ">
        <Accordion
          title={"Worker participation, Consultation, and Communication on occupational health and safety"}
          index={3}
        >
          <div className="foreword-container mt-1">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                A description of the processes for worker participation and consultation in the development,
                implementation, and evaluation of the occupational health and safety management system, and for
                providing access to and communicating relevant information on occupational health and safety to workers
              </p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={socialAllData.occupationalHealthSafety.workerParticipation.firstForeword}
                  name="firstForeword"
                  onChange={(e) => hanldeWorkerParticipationChange(e)}
                  className="no-style-input w-100"
                    disabled ={editMode && !formEditable}
                />
              </div>
            </div>
          </div>

          <div className="foreword-container mt-3">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                Where formal joint management-worker health and safety committees exist, a description of their
                responsibilities, meeting frequency, decision-making authority, and whether and, if so, why any workers
                are not represented by these committees
              </p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={socialAllData.occupationalHealthSafety.workerParticipation.secondForeword}
                  name="secondForeword"
                  onChange={(e) => hanldeWorkerParticipationChange(e)}
                  className="no-style-input w-100"
                    disabled ={editMode && !formEditable}
                />
              </div>
            </div>
          </div>
        </Accordion>
      </div>

      <div className="form-bullet-points-div mt-4 ">
        <Accordion title={"Worker Training on Occupational Health and Safety"} index={4}>
          <div className="foreword-container mt-1">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                A description of any occupational health and safety training provided to workers, including generic
                training as well as training on specific work-related hazards, hazardous activities, or hazardous
                situations
              </p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={socialAllData?.occupationalHealthSafety?.workerTraining?.workerTrainingForeword}
                  name="workerTrainingForeword"
                  onChange={(e) => handleWorkerTrainingForewordChange(e)}
                  className="no-style-input w-100"
                    disabled ={editMode && !formEditable}
                />
              </div>
            </div>
          </div>
        </Accordion>
      </div>

      <div className="form-bullet-points-div mt-4 ">
        <Accordion title={"Promotion of Worker Health"} index={5}>
          <div className="foreword-container mt-1">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                An explanation of how the organization facilitates workers' access to non-occupational medical and
                healthcare services, and the scope of access provided
              </p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={socialAllData.occupationalHealthSafety.promotionWorkerHealth.firstForeword}
                  name="firstForeword"
                  onChange={(e) => handlePromotionWorkerHealthChange(e)}
                  className="no-style-input w-100"
                    disabled ={editMode && !formEditable}
                />
              </div>
            </div>
          </div>

          <div className="foreword-container mt-3">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                A description of any voluntary health promotion services and programs offered to workers to address
                major non-work-related health risks, including the specific health risks addressed, and how the
                organization facilitates workers' access to these services and programs
              </p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={socialAllData.occupationalHealthSafety.promotionWorkerHealth.secondForeword}
                  name="secondForeword"
                  onChange={(e) => handlePromotionWorkerHealthChange(e)}
                  className="no-style-input w-100"
                    disabled ={editMode && !formEditable}
                />
              </div>
            </div>
          </div>
        </Accordion>
      </div>

      <div className="form-bullet-points-div mt-4 ">
        <Accordion
          title={
            "Prevention and mitigation of occupational health and safety impacts directly linked by business relationships Promotion of Worker Health"
          }
          index={6}
        >
          <div className="foreword-container mt-1">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                A description of the organization's approach to preventing or mitigating significant negative
                occupational health and safety impacts that are directly linked to its operations, products or services
                by its business relationships, and the related hazards and risks
              </p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={socialAllData?.occupationalHealthSafety?.preventionAndMitigation?.preventionAndMitigationForeword}
                  name="preventionAndMitigationForeword"
                  onChange={(e) => handlePreventionAndMitigation(e)}
                  className="no-style-input w-100"
                    disabled ={editMode && !formEditable}
                />
              </div>
            </div>
          </div>
        </Accordion>
      </div>

      <div className="form-bullet-points-div mt-4 ">
        <Accordion title={"Workers covered by an occupational health and safety management system"} index={7}>
          <div className="foreword-container mt-1">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                If the organization has implemented an occupational health and safety management system based on legal
                requirements and/or recognized standards/guidelines
              </p>

              <ol className="d-flex flex-column gap-3">
                <li>
                  The number and percentage of all employees and workers who are not employees but whose work and/or
                  workplace is controlled by the organization, who are covered by such a system
                </li>
                <table className="form-table mb-4 without-head">
                  <tbody>
                    {socialAllData?.occupationalHealthSafety?.workersCovered?.firstTable.map((item, index) => (
                      <tr key={item + index}>
                        <td>{item.title}</td>
                        <td>
                          <input
                            type="text"
                            name="numberPercentage"
                            value={item.numberPercentage}
                            onChange={(e) => handleWorkersCoveredFirstTableChange(e, index)}
                            className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}   
                            readOnly={editMode && !formEditable}
                          ></input>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <li>
                  The number and percentage of all employees and workers who are not employees but whose work and/or
                  workplace is controlled by the organization, who are covered by such a system that has been internally
                  audited
                </li>
                <table className="form-table mb-4 without-head">
                  <tbody>
                    {socialAllData.occupationalHealthSafety.workersCovered.secondTable.map((item, index) => (
                      <tr key={item + index}>
                        <td>{item.title}</td>
                        <td>
                          <input
                            type="number"
                            name="numberPercentage"
                            value={item.numberPercentage}
                            onChange={(e) => handleWorkersCoveredSecondChange(e, index)}
                            className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                            readOnly={editMode && !formEditable}

                          ></input>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <li>
                  The number and percentage of all employees and workers who are not employees but whose work and/or
                  workplace is controlled by the organization, who are covered by such a system that has been audited or
                  certified by an external party
                </li>

                <table className="form-table mb-4 without-head">
                  <tbody>
                    {socialAllData.occupationalHealthSafety.workersCovered.thirdTable.map((item, index) => (
                      <tr key={item + index}>
                        <td>{item.title}</td>
                        <td>
                          <input
                            type="number"
                            name="numberPercentage"
                            value={item.numberPercentage}
                            onChange={(e) => handleWorkersCoveredThirdChange(e, index)}
                            className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                            readOnly={editMode && !formEditable}
                            
                   
                          ></input>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </ol>
            </div>
          </div>

          <div className="foreword-container mt-3">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                Whether and, if so, why any workers have been excluded from this disclosure, including the types of
                worker excluded
              </p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={socialAllData.occupationalHealthSafety.workersCovered.secondForeword}
                  name="secondForeword"
                  onChange={(e) => handleWorkersCoveredChange(e)}
                  className="no-style-input w-100"
                    disabled ={editMode && !formEditable}
                />
              </div>
            </div>
          </div>

          <div className="foreword-container mt-3">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
                Any contextual information necessary to understand how the data have been compiled, such as any
                standards, methodologies, and assumptions used
              </p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={socialAllData.occupationalHealthSafety.workersCovered.thirdForeword}
                  name="thirdForeword"
                  onChange={(e) => handleWorkersCoveredChange(e)}
                  className="no-style-input w-100"
                    disabled ={editMode && !formEditable}
                />
              </div>
            </div>
          </div>
        </Accordion>
      </div>

      <div className="form-bullet-points-div mt-4 ">
        <Accordion title={"Work related injury"} index={8}>
          <div className="table-with-bullet-point">
            <p className="form-bullet-points">For Employees</p>
            <div>
              <table className="form-table">
                <thead>
                  <tr>
                    <th className="col-sm-2" scope="col">
                      Type
                    </th>
                    <th scope="col">Work Related Injury</th>
                    <th scope="col">Number</th>
                    <th scope="col">Rate</th>
                  </tr>
                </thead>
                <tbody>
                  {socialAllData.occupationalHealthSafety.workRelatedInjury.forAllEmployeesSection.forAllEmployees.map((item, index) => (
                    <tr key={index + item}>
                      {(index === 0 || index === 3) && <td rowSpan={rowSpans[item.parameters]}>{item.parameters}</td>}
                      <td className="border-left">{item.workrelatedinjury}</td>
                      <td>
                        <input
                          type="text"
                          name="number"
                          value={item.number}
                          onChange={(e) => handleForAllEmployeesChange(e, index)}
                          className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                          readOnly={editMode && !formEditable}
                          
                          
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="rate"
                          value={item.rate}
                          onChange={(e) => handleForAllEmployeesChange(e, index)}
                          className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                          readOnly={editMode && !formEditable}
                          
                        ></input>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div>
              <p className="form-bullet-points">Number of injuries by body part</p>
              <table className="form-table">
                <thead>
                  <tr>
                    <th scope="col">Body part</th>
                    <th scope="col">Assembly</th>
                    <th scope="col">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {socialAllData.occupationalHealthSafety.workRelatedInjury.forAllEmployeesSection.bodyPart.map((item, index) => (
                    <tr key={index + item}>
                      <td>{item.parameter}</td>
                      <td>
                        <input
                          type="text"
                          name="assembly"
                          value={item.assembly}
                          onChange={(e) => handleForAllEmployeesBodyPartChange(e, index)}
                          className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                          readOnly={editMode && !formEditable}
                          
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="total"
                          value={item.total}
                          onChange={(e) => handleForAllEmployeesBodyPartChange(e, index)}
                          className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                          readOnly={editMode && !formEditable}
                          
                        ></input>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="mt-4">
              <table className="form-table">
                <thead>
                  <tr>
                    <th scope="col">Number of injuries by type</th>
                    <th scope="col">Assembly</th>
                    <th scope="col">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {socialAllData.occupationalHealthSafety.workRelatedInjury.forAllEmployeesSection.injuriesByType.map((item, index) => (
                    <tr key={index + item}>
                      <td>{item.parameter}</td>
                      <td>
                        <input
                          type="text"
                          name="assembly"
                          value={item.assembly}
                          onChange={(e) => handleForAllEmployeesInjuriesByTypeChange(e, index)}
                          className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                          readOnly={editMode && !formEditable}
                          
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="total"
                          value={item.total}
                          onChange={(e) => handleForAllEmployeesInjuriesByTypeChange(e, index)}
                          className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                          readOnly={editMode && !formEditable}
                          
                        ></input>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="mt-4">
              <table className="form-table">
                <thead>
                  <tr>
                    <th scope="col">Parameter</th>
                    <th scope="col">Number of hours worked</th>
                  </tr>
                </thead>
                <tbody>
                  {socialAllData.occupationalHealthSafety.workRelatedInjury.forAllEmployeesSection.hoursWorked.map((item, index) => (
                    <tr key={index + item}>
                      <td>{item.parameter}</td>
                      <td>
                        <input
                          type="text"
                          name="numberOfHoursWorked"
                          value={item.numberOfHoursWorked}
                          onChange={(e) => handleForAllEmployeesHoursWorkedChange(e, index)}
                          className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                          readOnly={editMode && !formEditable}
                          
                        ></input>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>


          <div className="table-with-bullet-point mt-4">
            <p className="form-bullet-points">For All Workers</p>
            <div>
              <table className="form-table">
                <thead>
                  <tr>
                    <th className="col-sm-2" scope="col">
                      Type
                    </th>
                    <th scope="col">Work Related Injury</th>
                    <th scope="col">Number</th>
                    <th scope="col">Rate</th>
                  </tr>
                </thead>
                <tbody>
                  {socialAllData.occupationalHealthSafety.workRelatedInjury.forAllWorkersSection.forAllEmployees.map((item, index) => (
                    <tr key={index + item}>
                      {(index === 0 || index === 3) && <td rowSpan={rowSpansAllworkers[item.parameters]}>{item.parameters}</td>}
                      <td className="border-left">{item.workrelatedinjury}</td>
                      <td>
                        <input
                          type="text"
                          name="number"
                          value={item.number}
                          onChange={(e) => handleForAllWorkerChange(e, index)}
                          className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                          readOnly={editMode && !formEditable}
                          
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="rate"
                          value={item.rate}
                          onChange={(e) => handleForAllWorkerChange(e, index)}
                          className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                          readOnly={editMode && !formEditable}
                          
                        ></input>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div>
              <p className="form-bullet-points">Number of injuries by body part</p>
              <table className="form-table">
                <thead>
                  <tr>
                    <th scope="col">Body part</th>
                    <th scope="col">Assembly</th>
                    <th scope="col">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {socialAllData.occupationalHealthSafety.workRelatedInjury.forAllWorkersSection.bodyPart.map((item, index) => (
                    <tr key={index + item}>
                      <td>{item.parameter}</td>
                      <td>
                        <input
                          type="text"
                          name="assembly"
                          value={item.assembly}
                          onChange={(e) => handleForAllWorkerBodyPartChange(e, index)}
                          className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                          readOnly={editMode && !formEditable}
                          
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="total"
                          value={item.total}
                          onChange={(e) => handleForAllWorkerBodyPartChange(e, index)}
                          className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                          readOnly={editMode && !formEditable}
                          
                        ></input>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="mt-4">
              <table className="form-table">
                <thead>
                  <tr>
                    <th scope="col">Number of injuries by type</th>
                    <th scope="col">Assembly</th>
                    <th scope="col">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {socialAllData.occupationalHealthSafety.workRelatedInjury.forAllWorkersSection.injuriesByType.map((item, index) => (
                    <tr key={index + item}>
                      <td>{item.parameter}</td>
                      <td>
                        <input
                          type="text"
                          name="assembly"
                          value={item.assembly}
                          onChange={(e) => handleForAllWorkerInjuriesByTypeChange(e, index)}
                          className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                          readOnly={editMode && !formEditable}
                          
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="total"
                          value={item.total}
                          onChange={(e) => handleForAllWorkerInjuriesByTypeChange(e, index)}
                          className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                          readOnly={editMode && !formEditable}
                          
                        ></input>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="mt-4">
              <table className="form-table">
                <thead>
                  <tr>
                    <th scope="col">Parameter</th>
                    <th scope="col">Number of hours worked</th>
                  </tr>
                </thead>
                <tbody>
                  {socialAllData.occupationalHealthSafety.workRelatedInjury.forAllWorkersSection.hoursWorked.map((item, index) => (
                    <tr key={index + item}>
                      <td>{item.parameter}</td>
                      <td>
                        <input
                          type="text"
                          name="numberOfHoursWorked"
                          value={item.numberOfHoursWorked}
                          onChange={(e) => handleForAllWorkerHoursWorkedChange(e, index)}
                          className={`no-style-input ${formEditable? "border border-2 rounded-1 p-1":""} `}
                          readOnly={editMode && !formEditable}
                          
                        ></input>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

        </Accordion>
      </div>

      <div className="form-bullet-points-div mt-3 ">
        <Accordion title={"Details of work related hazards"} index={9}>
          {socialAllData?.occupationalHealthSafety?.detailsOfWork?.detailsOfWworkRelatedHazards.map((item, index) => (
            <div className="foreword-container mt-1 mb-4" key={item + index}>
              <div className="form-textfield-container px-4 pb-4">
                <p className="form-bullet-points">{item.heading}</p>
                <div className="custom-textfield ">
                  <textarea
                    rows="1"
                    placeholder="Write your foreword here."
                    value={item.foreword}
                    name={"foreword"}
                    onChange={(e) => handleDetailsOfWworkRelatedHazardsChange(e, index)}
                    className="no-style-input w-100"
                      disabled ={editMode && !formEditable}
                  />
                </div>
              </div>
            </div>
          ))}

          {/* <div className="foreword-container mt-3">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
              Specify, the definition used for 'significant locations of operations’
              </p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={socialAllData.occupationalHealthSafety.workersCovered.secondForeword}
                  name="secondForeword"
                  onChange={""}
                  className="no-style-input w-100"
                    disabled ={editMode && !formEditable}
                />
              </div>
            </div>
          </div>


          <div className="foreword-container mt-3">
            <div className="form-textfield-container px-4 pb-4">
              <p className="form-bullet-points">
              Specify, the definition used for 'significant locations of operations’
              </p>
              <div className="custom-textfield ">
                <textarea
                  rows="1"
                  placeholder="Write your foreword here."
                  value={socialAllData.occupationalHealthSafety.workersCovered.secondForeword}
                  name="secondForeword"
                  onChange={""}
                  className="no-style-input w-100"
                    disabled ={editMode && !formEditable}
                />
              </div>
            </div>
          </div> */}
        </Accordion>
      </div>
    </div>
  );
};

export default Occupational;
